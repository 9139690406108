<script lang="ts">
  import Config from "../../config.json";
  const DEV = Config.dev;

  import { Debugger, Sheet } from "@okcontract/cells";
  import { isEqual } from "@okcontract/lambdascript";
  import { Core } from "@okcontract/sdk";
  import { ENDPOINT, WIDGET_APP } from "@scv/app";

  import Widget from "./widget.svelte";

  // URL parameters
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const wid = params.get("id");
  const th = params.get("th");
  const xp = params.get("xp");
  const chain = params.get("ch") || undefined;

  const core = Core(WIDGET_APP, {
    chain,
    sheet: new Sheet(isEqual),
    endpoint: ENDPOINT
  });

  if (DEV) {
    const debug = new Debugger(core.Sheet);
    window["debug"] = debug;
    window["p"] = (cell: number) => debug.p(cell);
    setInterval(() => {
      console.log(debug.sheet.stats);
    }, 5000);
    // debug.w(513);
  }
</script>

<Widget {wid} {th} {xp} {core} />
