<script lang="ts">
  import { isAddress } from "viem";

  import {
    isContract,
    isToken,
    is_contract_addr,
    is_met,
    is_org,
    is_person,
    is_widget,
    type_of_cache_query,
    type CacheQuery
  } from "@okcontract/coredata";
  import { Address, type ChainType } from "@okcontract/multichain";
  import { isKnownAddress } from "@okcontract/sdk";
  import { WalletAddress, newInstance } from "@okcontract/svelte-sdk";
  import { Loading, ThemeText, getTheme } from "@okcontract/uic";
  import {
    MethodSnippet,
    OrgSnippet,
    PersonSnippet,
    SmartContractSnippet,
    TokenSnippet,
    WidgetSnippet
  } from "@scv/dcuic";

  export let instance = newInstance("DatacacheSnippet");
  export let dq: CacheQuery | `0x${string}`;
  export let displayType: boolean = true;
  export let displayUpvote: boolean = true;
  export let small: boolean = true;
  export let chain: ChainType = undefined;

  const core = instance._core;
  const theme = getTheme();

  const compiledTheme = theme?.compiled;
  const dark_tag = theme.dark(
    $compiledTheme,
    "badge badge-outline",
    "badge badge-outline",
    "badge badge-secondary"
  );

  let vdq: string = dq;
  // @todo outside of this component?
  $: isKnownAddress(core, dq, chain).then((v) => {
    if (v) vdq = v;
  });

  let tq: any = dq;
  //@ts-expect-error vdq should be CacheQuery
  $: ty = type_of_cache_query(vdq);
</script>

<div class="flex gap-1 items-center">
  {#if !dq}
    <Loading />
  {:else if small}
    {#if is_org(vdq)}
      <OrgSnippet {instance} q={vdq} small={true} />
    {:else if isToken(vdq)}
      <TokenSnippet {instance} q={vdq} small={true} />
    {:else if isContract(vdq)}
      <SmartContractSnippet {instance} q={vdq} small={true} />
    {:else if is_met(vdq)}
      <MethodSnippet {instance} q={vdq} small={true} />
    {:else if is_person(vdq)}
      <PersonSnippet {instance} q={vdq} small={true} />
    {:else if is_widget(vdq)}
      <WidgetSnippet {instance} q={vdq} inline={false} />
    {:else if isAddress(vdq) && chain}
      {@const ty = is_contract_addr(vdq, chain) ? "c" : "w"}
      <WalletAddress
        {instance}
        address={{ addr: vdq, ty, chain }}
        link={true}
      />
    {:else}
      <div
        class={theme.dark(
          $compiledTheme,
          "badge badge-outline text-white opacity-70",
          "badge badge-outline text-black opacity-70",
          "badge badge-primary"
        )}
        style={theme.apply($compiledTheme, [ThemeText])}
      >
        {typeof vdq === "string" && vdq?.indexOf(":")
          ? vdq.substring(vdq.indexOf(":") + 1)
          : vdq}
      </div>
    {/if}
    {#if displayType && ty}
      <span
        class="{dark_tag} flex-none"
        style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
      >
    {/if}
  {:else if vdq.startsWith("org:")}
    <OrgSnippet {instance} q={tq} small={false} upvote={displayUpvote}>
      <span slot="tag">
        {#if displayType}
          <span
            class="{dark_tag} flex-none"
            style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
          >
        {/if}
      </span></OrgSnippet
    >
  {:else if isToken(vdq)}
    <TokenSnippet {instance} q={vdq} small={false} upvote={displayUpvote}>
      <span slot="tag">
        {#if displayType}
          <span
            class="{dark_tag} flex-none"
            style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
          >
        {/if}
      </span></TokenSnippet
    >
  {:else if isContract(vdq)}
    <SmartContractSnippet
      {instance}
      q={vdq}
      small={false}
      upvote={displayUpvote}
    >
      <span slot="tag">
        {#if displayType}
          <span
            class="{dark_tag} flex-none"
            style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
          >
        {/if}
      </span></SmartContractSnippet
    >
  {:else if vdq.startsWith("met:")}
    <MethodSnippet {instance} q={tq} small={false}>
      <span slot="tag">
        {#if displayType}
          <span
            class="hidden {dark_tag} flex-none"
            style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
          >
        {/if}
      </span></MethodSnippet
    >
  {:else if is_person(tq)}
    <PersonSnippet {instance} q={tq} small={false} upvote={displayUpvote}>
      <span slot="tag">
        {#if displayType}
          <span
            class="hidden {dark_tag} flex-none"
            style={theme.apply($compiledTheme, [ThemeText])}>{ty}</span
          >
        {/if}
      </span></PersonSnippet
    >
  {:else if is_widget(vdq)}
    <WidgetSnippet {instance} q={vdq} inline={false} />
  {:else if isAddress(vdq) && chain}
    {@const ty = is_contract_addr(vdq, chain) ? "c" : "w"}
    <WalletAddress
      {instance}
      address={{ addr: new Address(vdq), ty, chain }}
      link={true}
    />
  {:else}
    <div class="flex">
      <div
        class={theme.dark(
          $compiledTheme,
          "badge badge-outline text-white opacity-70",
          "badge badge-outline text-black opacity-70",
          "badge badge-primary"
        )}
        style={theme.apply($compiledTheme, [ThemeText])}
      >
        {typeof vdq === "string" && vdq?.indexOf(":")
          ? vdq.substring(vdq.indexOf(":") + 1)
          : vdq}
      </div>
    </div>
  {/if}
</div>
