import type { AnyCell } from "@okcontract/cells";
import type { CacheQuery } from "@okcontract/coredata";
import type { LocalSubscriber } from "@scv/cache";

import { parseHeaders } from "./parse_headers";
import { ProxyQuery, VirtualProxyQuery } from "./types";

export const proxy_fetch = (
  local: LocalSubscriber<CacheQuery>,
  uri: AnyCell<string>,
  proxy?: string // @todo cell
): AnyCell<Response> =>
  uri.map((uri) => {
    try {
      return fetch(proxy ? proxy + uri : uri);
    } catch (error) {
      const px = local.unwrappedCell(local._proxy.new(ProxyQuery(uri)));
      // @todo remove .get()
      return px.map((v) => (v && JSON.parse(v)) || null).get();
    }
  });

export const proxy_head = (
  local: LocalSubscriber<CacheQuery>,
  uri: AnyCell<string>
): AnyCell<Response> =>
  uri.map(async (_uri) => {
    try {
      return fetch(_uri, { method: "HEAD" });
    } catch (error) {
      const vp = local.unwrappedCell(
        uri.map((_uri) => VirtualProxyQuery(_uri))
      );
      // @todo remove .get()
      const res = await vp.map((_vp) => parseHeaders(_vp)).get();
      if (res instanceof Error) throw res;
      return res;
    }
  });
