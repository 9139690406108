import type { ForeignSource } from "@okcontract/coredata";

export const foreignOrgId = {
  Coingecko: "coingecko",
  CoinMarketCap: "cmc",
  Defillama: "llama",
  Opensea: "opensea",
  Coinlist: "coinlist"
};

export const foreignIdOfSlug = Object.fromEntries(
  Object.entries(foreignOrgId).map(([k, v]) => [v, k])
);

/**
 * getForeign retrieves the first matching entity in the list.
 * @param l
 * @param entity
 */
export const getForeign = (l: string[], entity: ForeignSource): string => {
  for (const k of l || [])
    if (k.startsWith(`${entity}:`)) return k.replace(`${entity}:`, "");
};
