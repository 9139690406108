export async function filterAsync<T>(
  arr: T[],
  predicate: (item: T) => Promise<boolean>
): Promise<T[]> {
  const result: T[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (await predicate(arr[i])) {
      result.push(arr[i]);
    }
  }
  return result;
}
