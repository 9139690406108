import { getContext } from "svelte";

import type { ValueCell } from "@okcontract/cells";

import type { RouterContext } from "./types";

export const LOCATION = Symbol();
export const ROUTER = Symbol();
export const HISTORY = Symbol();

export const useLocation = (): ValueCell<Location> => getContext(LOCATION);
export const useRouter = (): RouterContext => getContext(ROUTER);
export const useHistory = (): Window => getContext(HISTORY);
