<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { type ValueCell } from "@okcontract/cells";
  import { Instance } from "@okcontract/sdk";
  import {
    Button,
    Icon,
    ThemeText,
    getTheme,
    inputSizes,
    inputStyles,
    type InputSize,
    type InputStyle
  } from "@okcontract/uic";

  export let instance: Instance;
  const proxy = instance._proxy;

  /** the search input */
  export let input: ValueCell<string> = proxy.new("");
  export let placeholder: string;
  export let style: InputStyle = "default";
  export let size: InputSize = "md";
  export let type: string = "text";
  export let searching: ValueCell<boolean> | undefined = proxy.new(false);
  export let disabled = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
  let searchInputRef: HTMLInputElement;

  const onEnter = (event: KeyboardEvent) => {
    if (event.code === "Enter") {
      if (searchInputRef.value.trim() !== "") {
        event.preventDefault();
        dispatch("submit");
      }
      searchInputRef.blur();
    }
  };
  const focus = (node: HTMLInputElement) => {
    if (!$input) return;
    node.focus();
  };
</script>

<label
  class="{disabled
    ? 'input-disabled'
    : theme.dark(
        $compiledTheme,
        `${inputStyles[`${style}-ghost`]}`,
        `${inputStyles[`${style}-ghost`]}`,
        `${inputStyles[style]}`
      )} input flex items-center"
  style={theme.apply($compiledTheme, [ThemeText])}
>
  <Icon name="search" size="xs" opacity="70" />
  <input
    class="grow {inputSizes[size]} flex gap-2"
    {type}
    {placeholder}
    style={theme.apply($compiledTheme, [ThemeText])}
    value={$input}
    {disabled}
    bind:this={searchInputRef}
    use:focus
    on:click={() => dispatch("click")}
    on:input={() => {
      const newInput = searchInputRef.value.trim();
      input.set(newInput);
      dispatch("input", newInput);
    }}
    on:keyup={onEnter}
    on:focus={() => dispatch("focus")}
    on:keydown={(ev) => dispatch("keydown", ev)}
    on:mousedown={(ev) => ev.stopPropagation}
    on:focus
  />
  <!-- FIXME: stopPropagation not ok -->
  {#if $searching}
    <span class="loading loading-spinner loading-xs"></span>
  {/if}
  {#if searchInputRef?.value?.length}
    <Button
      size="sm"
      style="ghost"
      circle={true}
      icon="close-o"
      iconAppend={true}
      action={(ev) => {
        ev.stopPropagation();
        searchInputRef.value = "";
        dispatch("delete");
      }}
    />
  {/if}
</label>
