<script lang="ts">
  import { Tooltip } from "@okcontract/uic";

  import LogoMap from "./LogoMap.svelte";

  export let logo: string;
  export let tooltip: boolean = false;
  export let size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" = "sm";
  export let remote = false;

  let sizer =
    size === "xs"
      ? 3
      : size === "sm"
        ? 4
        : size === "md"
          ? 6
          : size === "lg"
            ? 8
            : size === "xl"
              ? 10
              : 20;
</script>

{#if tooltip}
  <Tooltip label={logo}>
    <div class="h-{sizer} w-{sizer}" aria-haspopup="true">
      {#if typeof logo === "string" && (logo.startsWith("https://") || logo.startsWith("data:image/"))}
        <img
          src={logo}
          alt={logo}
          class="rounded-full"
          on:error={() => (logo = undefined)}
        />
      {:else}
        <LogoMap name={logo} {size} {remote} />
      {/if}
    </div>
  </Tooltip>
{:else}
  <div class="h-{sizer} w-{sizer}" aria-haspopup="true">
    {#if typeof logo === "string" && (logo.startsWith("https://") || logo.startsWith("data:image/"))}
      <img
        src={logo}
        alt={logo}
        class="rounded-full"
        on:error={() => (logo = undefined)}
      />
    {:else}
      <LogoMap name={logo} {size} {remote} />
    {/if}
  </div>
{/if}
