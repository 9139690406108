<script lang="ts">
  import { hashStringToListedColor } from "@scv/utils";
  import { bgStyles } from "@scv/dcuic";
  import { Icon, Tooltip } from "@okcontract/uic";

  export let username: string;

  export let size: keyof typeof sizes = "sm";
  const sizes = {
    xs: "h-5 w-5 text-xs leading-5",
    sm: "h-6 w-6 text-xs leading-5",
    md: "h-8 w-8 text-sm leading-6",
    lg: "h-12 w-12 text-xl leading-6"
  };

  const initial = username && username.substring(0, 1).toLowerCase();
</script>

{#await username}
  <Tooltip label={username} pos="bottom">
    <div
      class="{sizes[
        size
      ]} relative items-center justify-center rounded-full font-medium text-white text-center
       uppercase truncate ring-2 ring-white bg-purple-800 focus:opacity-90 focus:outline-none"
    >
      <div class="absolute top-0 left-O text-white opacity-50">
        <Icon name="user-c-o" {size} />
      </div>
    </div>
  </Tooltip>
{:then res}
  <!-- FIXME: display image if uploaded or gravatar -->
  <Tooltip label={username}>
    <div
      class="{sizes[
        size
      ]} flex items-center justify-center rounded-full font-medium text-white
        uppercase truncate ring-2 ring-white {res
        ? hashStringToListedColor(bgStyles, username)
        : 'bg-purple-800'} focus:opacity-90 focus:outline-none
           tooltip"
    >
      {initial}
    </div>
  </Tooltip>
{/await}
