import { isAddress } from "viem";

import type { AnyCell, MapCell } from "@okcontract/cells";
import {
  AllAnonContractsQuery,
  AnonContractQuery,
  HashAnonContractQuery,
  SearchQuery,
  coredata_search_query,
  strip_datacache_prefix,
  type EVMToken,
  type SearchType,
  type SmartContract
} from "@okcontract/coredata";
import type { ChainType, EVMAddress } from "@okcontract/multichain";

import type { AnyCore, CoreExecution } from "./coreExecution.types";

/**
 * search for a given input its contract query
 * - search for a known contract
 * - search for an anonymous contract
 * @param core
 * @param input
 * @returns
 */
export const search = async (core: AnyCore, input: string) => {
  const list = await core.CacheOnce(SearchQuery(input));
  if (!list?.res?.length && input) return searchAnon(core, input);
  return list?.res;
};

/**
 *
 * @param input
 * @returns
 * @todo should be a mapping function for cells?
 */
export const searchAnon = async (core: AnyCore, input: string) => {
  const chain = await core.CurrentChain.get();
  if (chain instanceof Error) return;
  if (input.length === 66 && input.startsWith("0x")) {
    const cd = await core.CacheOnce(HashAnonContractQuery(input, chain));
    if (!cd) return;
    return [AnonContractQuery((cd as SmartContract).addr.addr, chain)];
  }
  if (!isAddress(input)) return;

  // search for all contract (on all chains) matching the input addr
  const allAnonContractQuery = AllAnonContractsQuery(input);
  const res = await core.CacheOnce(allAnonContractQuery);
  const queries = res?.l?.map((cd) => cd?.q) || [];
  // check if we find the current chain addr
  // and move it to the first position
  const found = queries.find((q) => q.includes(chain));
  if (found) return [found].concat(queries.filter((item) => item !== found));
  return queries;
};

/**
 * returns either the datacache token or contract for a given address
 * @param addr
 * @returns
 */
export const searchAddress = async (
  core: AnyCore,
  addr: EVMAddress
): Promise<SmartContract | EVMToken> => {
  if (!addr?.addr || !addr?.chain) return null;
  const query = AnonContractQuery(addr.addr, addr.chain);
  return core.CacheOnce(query);
};

/**
 * coredataSearch search in datacache a coredata type.
 * @param ty
 * @param input
 * @returns
 * @todo error management...
 */
export const coredataSearch =
  (
    core: AnyCore,
    ty: SearchType,
    chain: AnyCell<ChainType> = core.CurrentChain
  ) =>
  async (input: string) => {
    const ch = await chain.get();
    if (!input || ch instanceof Error) return;

    const query = coredata_search_query(ty, input, ch);
    const data = await core.CacheOnce(query);
    if (!data) return;
    return data.res?.map((v) => strip_datacache_prefix(v));
  };
