import type { WalletClient } from "@wagmi/core";

import type { AnyCell } from "@okcontract/cells";
import { Address } from "@okcontract/multichain";
import type { Role } from "@scv/auth";

import { DISCORD_APP, type AppIDs } from "./constants";
import { COINBASE, INJECTED, METAMASK } from "./coreExecution";
import { balance_of, formatBig } from "./erc20";

/**
 * value_of formats values, using decimals for totalSupply.
 * @todo other use of decimals?
 * @todo "MAX" if more than 10^40?
 * @todo 2 digits precision
 * @todo https://docs.ethers.io/v5/api/utils/display-logic/
 * @todo use formatBig
 */
export const value_of = (
  name: string,
  v: string | number | bigint,
  decimals = 18
) =>
  v &&
  (name === ".dec" || name === "totalSupply" || name === balance_of) &&
  decimals > 3
    ? formatBig(v, decimals)
    : v;

export const max_of = (v: number) => (v > 10e40 ? "MAX" : v);

export const isRealWalletID = (
  walletID: Address | "loading"
): walletID is Address => walletID instanceof Address;

/**
 * walletID is the current wallet.
 * @todo should not init empty
 */
export const NO_WALLET = undefined;
export const LOADING_WALLET = "loading" as const;
// @todo use everywhere
export type WalletIDType = typeof LOADING_WALLET | Address;

// @todo remove
export const ALL_CHAINS = "ALL" as const;

export const sign_message = async (
  walletClient: AnyCell<WalletClient>,
  msg: string
) => {
  const wClient = await walletClient.get();
  if (wClient instanceof Error) return;
  if (!wClient?.account) throw "Missing client wallet";
  const sig = await wClient.signMessage({
    account: wClient.account,
    message: msg
  });
  return sig;
};

export const detectWallet = (appID?: AppIDs) => {
  if (appID === DISCORD_APP) return null;
  if (typeof window.ethereum !== "undefined") {
    if (window.ethereum?.isBraveWallet || window.ethereum?.isRabby)
      return INJECTED;
    if (window.ethereum?.isMetaMask) return METAMASK;
    if (window.ethereum?.isCoinbaseWallet) return COINBASE;
    return INJECTED;
  }
  return null;
};

export const CODE_REQUIRED = "code" as const;
export const AUTHENTICATING = "loading" as const;
