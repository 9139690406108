/**
 * to_base64 is a native ArrayBuffer to base64 conversion.
 * @param buf
 */
export const toDataURL = (
  buf: ArrayBuffer,
  ty?: BlobPropertyBag
): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_) => {
      // console.log(buf, reader.result);
      resolve(reader.result as string);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    const blob = new Blob([buf], ty);
    reader.readAsDataURL(blob);
  });

/**
 * hash_to_base64 hashes a string to base64, using the non secure SHA-1 function.
 * @param str
 * @returns hash as bas64 string
 */
export const hash_to_base64 = async (str: string) => {
  const buffer = new TextEncoder().encode(str);
  const hash = await crypto.subtle.digest("SHA-1", buffer);
  return btoa(String.fromCharCode(...new Uint8Array(hash)));
};
