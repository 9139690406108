import Context from "./context";

class PatchContext extends Context {
  left;
  delta;
  pipe: string;

  constructor(left, delta) {
    super();
    this.left = left;
    this.delta = delta;
    this.pipe = "patch";
  }
}

export default PatchContext;
