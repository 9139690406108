// @todo multichain
import { isAddress } from "viem";

import { Address, type ChainType, type Network } from "@okcontract/multichain";

import {
  AnonContractQuery,
  ContractQuery,
  OrgQuery,
  type AnonContractQueryType,
  type ContractQueryType
} from "./coredata";
import type { EVMToken, SmartContract } from "./types";

/**
 * @deprecated
 * @param addr
 * @param ch
 * @returns
 */
// @todo retrieve from datacache (with bloom filter)
export const is_contract_addr = async (addr: string, ch: ChainType) => {
  if (!addr || !ch) return false;
  // const provider = await get_viem_public_provider(ch);
  // const bytecode = await provider.getBytecode({ address: addr as Address });
  // return bytecode !== "0x";
  return true;
};

/**
 * is_contract checks if the input is a contract query.
 *
 * @description This function does not resolve the contract from an address.
 * @param q
 * @returns
 */
export const isContract = (q: unknown): q is ContractQueryType =>
  typeof q === "string" &&
  (q.startsWith("con:") ||
    q.startsWith("nft:") ||
    q.startsWith("swap:") ||
    q.startsWith("stake:"));

export const isAnonQuery = (q: string): q is AnonContractQueryType<ChainType> =>
  typeof q === "string" && (q.startsWith("χcd:con:") || q.startsWith("con:@"));

/**
 * is_anon_contract verifies if the contract is anonymous
 * @param con
 * @returns
 */
export const is_anon_contract = (con: SmartContract | EVMToken): boolean =>
  !con?.id;

export const contract_org_query = (q: ContractQueryType) => {
  if (!(typeof q === "string")) return;
  const org_name = q
    ?.replace("con:", "")
    .substring(0, q.replace("con:", "").indexOf("/"));
  if (org_name?.startsWith("@")) return;
  return OrgQuery(org_name);
};

export const contractQueryFromOrgID = (org: string, id: string) =>
  org.startsWith("@") && isAddress(id)
    ? AnonContractQuery(new Address(id), org.slice(1))
    : ContractQuery(`${org}/${id}`);
