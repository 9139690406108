export const parseHeaders = (
  rawHeaders: string,
  body: BodyInit = null,
  status = 200,
  statusText = "OK"
): Response => {
  // Split raw headers into lines
  const headerLines = rawHeaders.trim().split(/[\r\n]+/);

  // Create a new Headers object
  const headers = new Headers();

  // Process each line
  for (const line of headerLines) {
    const parts = line.split(": ");
    const header = parts.shift();
    const value = parts.join(": ");
    headers.append(header, value);
  }

  // Prepare the response init object
  const init: ResponseInit = {
    status: status,
    statusText: statusText,
    headers: headers
  };

  // Create and return the new Response object
  return new Response(body, init);
};
