<script lang="ts">
  import type { AnyCell } from "@okcontract/cells";
  import { AlertBanner } from "@okcontract/uic";

  export let cell: AnyCell<unknown>;

  const rewrite = (msg: unknown) =>
    msg
      .toString()
      .replaceAll("Error: ", "")
      .replace("no response", "no RPC response");
</script>

{#if $cell instanceof Error}
  <AlertBanner style="warning">
    {#if "reason" in $cell && "source" in $cell}
      <b>Cell {$cell.source}</b>: {rewrite($cell.reason)}
    {:else}
      <b>Cell {cell.id}</b>: {rewrite($cell.message)}
    {/if}
  </AlertBanner>
{/if}
