<script lang="ts">
  import { Cell, type AnyCell } from "@okcontract/cells";
  import type { Instance } from "@okcontract/sdk";

  import TitlesCell from "./TitlesCell.svelte";
  import TitlesRaw from "./TitlesRaw.svelte";

  export let instance: Instance;

  export let item: any;
  export let size: "smb" | "sm" | "md" = "sm";
  export let chain: AnyCell<string> = instance._core.CurrentChain;
</script>

{#if item instanceof Cell}
  <TitlesCell {instance} {item} {size} {chain}></TitlesCell>
{:else}
  <TitlesRaw {instance} {item} {size} {chain}></TitlesRaw>
{/if}
