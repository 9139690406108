import type { Connector } from "@wagmi/connectors";

import type { SheetProxy } from "@okcontract/cells";
import type {
  AnonContractQueryType,
  ContractQueryType,
  OKWidget,
  OKWidgetStep,
  OKWidgetStepType,
  TokenQueryType
} from "@okcontract/coredata";
import {
  emptyValueOfTypeDefinitionAux,
  type MapTypeDefinitions
} from "@okcontract/fred";
import type { ChainType } from "@okcontract/multichain";
import { WidgetTypeDefinition, type CoreExecution } from "@okcontract/sdk";

import type { OKWidgetData } from "./types";

export const newWidget = async (
  core: CoreExecution<Connector>,
  proxy: SheetProxy,
  types: MapTypeDefinitions,
  contractQuery?:
    | TokenQueryType
    | ContractQueryType
    | AnonContractQueryType<ChainType>,
  method?: string
): Promise<OKWidgetData> => {
  const data = (await emptyValueOfTypeDefinitionAux(
    proxy,
    types,
    WidgetTypeDefinition()
  )) as OKWidget;
  const { id, sig } = await core.Cache._API.NewID();
  const walletID = await core.WalletID.get();
  if (walletID instanceof Error) return;
  return {
    OKWidget: {
      ...data,
      id: id,
      st: [await new_step(proxy, types, contractQuery, method)],
      own: walletID
    },
    sig: sig
  };
};

export const newWidgetFrom = async (
  core: CoreExecution<Connector>,
  proxy: SheetProxy,
  types: MapTypeDefinitions,
  from: OKWidget
): Promise<OKWidgetData> => {
  const widget = await newWidget(core, proxy, types);
  return {
    OKWidget: {
      ...from,
      id: widget.OKWidget.id,
      own: widget.OKWidget.own,
      qri: widget.OKWidget.qri
    },
    sig: widget.sig
  };
};

/**
 * new_step ... @todo
 * @param schema
 * @param step
 * @returns
 */
export const new_step = async (
  proxy: SheetProxy,
  types: MapTypeDefinitions,
  contractQuery?:
    | ContractQueryType
    | AnonContractQueryType<ChainType>
    | TokenQueryType,
  method?: string
): Promise<OKWidgetStep<OKWidgetStepType>> => {
  const { empty_step }: { empty_step: OKWidgetStep<OKWidgetStepType> } =
    await emptyValueOfTypeDefinitionAux(proxy, types, {
      object: proxy.new({
        empty_step: () => proxy.new({ label: "step", name: "OKWidgetStep" })
      })
    });

  // @todo data is in convertedFrom state
  const new_step = {
    ...empty_step,
    q: contractQuery || empty_step?.q,
    m: method || ""
  } as OKWidgetStep<OKWidgetStepType>;
  return Object.fromEntries(
    Object.entries(new_step).filter(([k, v]) => v !== undefined)
  ) as OKWidgetStep<OKWidgetStepType>;
};

export const get_widget_method = (
  step: Partial<OKWidgetStep<OKWidgetStepType>>
) =>
  step?.m?.includes("(") ? step.m.substring(0, step.m.indexOf("(")) : step?.m;

export const get_widget_step = (w: OKWidget, index: number) => w?.st?.[index];
