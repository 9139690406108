import type { Store } from "./hstore";

/**
 * debouncer creates a debounce function that will execute a callback after a _delay_.
 *
 * Create with `const debounce = debouncer()`
 * and use as `debounce(cb, v, delay)`.
 * @param cb callback
 * @param v value passed to callback
 * @param delay optional delay in ms, default: 750
 */
export const debouncer = <T>(
  delay: number = 750,
  working: Store<boolean> = undefined
) => {
  let timer: number;
  return (cb: (v: T) => void, v: T) => {
    if (working !== undefined) working.set(true);
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      cb(v);
      if (working !== undefined) working.set(false);
    }, delay);
  };
};
