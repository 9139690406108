<!-- @todo rename to SelectChains -->
<script lang="ts">
  import { type AnyCell } from "@okcontract/cells";
  import { type ChainType } from "@okcontract/multichain";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { Dropdown, Icon, getTheme } from "@okcontract/uic";
  import { Logo } from "@scv/dcuic";

  import ChainButton from "./ChainButton.svelte";
  import ChainListItem from "./ChainListItem.svelte";

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  export let instance = newInstance("SwitchChains");
  export let chains: AnyCell<ChainType[]>;
  export let displayCurrent = false;
  // Optional label
  export let label = "Switch to";
  export let size: "xs" | "sm" | "md" = "sm";

  const core = instance._core;
  const coreChains = core.Chains;
  const current = core.Chain;
  const loading = core.IsSwitchingChain;
  const noWallet = core.IsNoWallet;

  let buttonElement: HTMLElement;
  const open = instance._proxy.new(false, "SwitchChain.dropDownOpen");
</script>

{#if $chains && !($chains instanceof Error) && !($coreChains instanceof Error) && $chains.length}
  {#if $chains.length > 1}
    <Dropdown
      style="bottom"
      size="sm"
      {open}
      {buttonElement}
      on:close={() => open.set(false)}
    >
      <div slot="action">
        <button
          bind:this={buttonElement}
          on:click={() => open.update((_open) => !_open)}
          class="flex flex-row items-center btn btn-{size} rounded-full {theme.dark(
            $compiledTheme,
            'btn-white-alpha',
            'btn-black-alpha',
            'btn-ghost'
          )}"
        >
          <!-- disabled={!hasChains} -->
          {#if $loading && $noWallet !== true}
            <span class="loading loading-spinner loading-xs"></span>
          {:else if !($current instanceof Error)}
            <Logo
              logo={$current?.id}
              size="sm"
              remote={instance._core.AppID === "widget"}
            />
          {/if}
          <span class="font-medium hidden lg:block">
            {#if displayCurrent}
              {$current?.name}
            {:else}
              {label}
            {/if}
          </span>
          <span
            class={theme.dark($compiledTheme, "text-white", "text-black", "")}
            ><Icon name="chevron-d" size="xs" opacity="80" /></span
          >
        </button>
      </div>
      <ul slot="main">
        {#each $chains as ch}
          {@const chain = $coreChains[ch]}
          <ChainListItem {instance} {chain} {open} />
        {/each}
      </ul>
    </Dropdown>
  {:else}
    <ChainButton {instance} chain={$chains[0]} />
  {/if}
{/if}
