<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import { isAddress } from "viem";

  import {
    SearchQuery,
    isContract,
    isToken,
    type CacheQuery,
    type EVMToken,
    type SmartContract
  } from "@okcontract/coredata";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { DataCacheSnippet } from "@scv/dcuic";

  import SearchSelector from "./SearchSelector.svelte";

  // optional one click address.
  export let oneClick: [string, string] | undefined = undefined;
  // optional additional search terms
  export let search_constant: string = "";
  export let placeholder: string | undefined = undefined;
  export let removable: boolean = false;
  export let disabled = false;
  export let instance = newInstance("SearchAddress");
  const core = instance._core;
  const currentChain = core.CurrentChain;

  /**
   * available_on_chain returns the CacheQuery if it is available on
   * current chain, or undefined otherwise.
   * @param q
   */
  const available_on_chain = async (q: CacheQuery) =>
    isContract(q)
      ? (((await core.CacheOnce(q)) as SmartContract)?.addr.chain ===
          $currentChain &&
          q) ||
        undefined
      : (((await core.CacheOnce(q)) as EVMToken)?.addr.find(
          (addr) => addr.chain === $currentChain
        ) !== undefined &&
          q) ||
        undefined;

  /**
   * searchFn searches for addresses.
   * @param q
   * @todo autocomplete addresses (also include users?)
   */
  const searchFn = async (q: string) =>
    isAddress(q)
      ? [q]
      : ($currentChain &&
          (
            await Promise.all(
              (
                (await core.CacheOnce(SearchQuery(q + search_constant)))?.res ||
                []
              )
                .filter((x) => isContract(x) || isToken(x))
                .map(available_on_chain)
            )
          ).filter((x) => x !== undefined)) ||
        [];

  const dispatch = createEventDispatcher();
  const onSelect = async (ev: CustomEvent) => {
    const component = ev.detail.component;
    const selected = ev.detail.selected;
    let v = await instance
      ._retrieveAddress(currentChain, component.input)
      // @todo remove .get()
      ?.get();
    if (!v) v = selected;
    component.selected.set(v);
    dispatch("update", v);
  };
</script>

<SearchSelector
  {disabled}
  {searchFn}
  {oneClick}
  {removable}
  {placeholder}
  inputStyle="bordered"
  on:select={onSelect}
  let:value
>
  <DataCacheSnippet dq={value} small={true} />
</SearchSelector>
