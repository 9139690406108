import { isObject } from "@okcontract/cells";

export const isNull = (value: unknown) => typeof value === "object" && !value;

/**
 * randomId generates a new, random string identifier.
 */
export const randomId = () => {
  return crypto?.randomUUID()?.substring(0, 8);
};

/**
 * length returns the length of a value.
 * @param value any value (object, array, single value)
 */
export const length = (value: unknown) => {
  if (!value) {
    return 0;
  }
  if (typeof value === "number") return value;
  if (Array.isArray(value)) {
    return value.length;
  }
  if (typeof value === "object") {
    return Object.keys(value).length;
  }
  return 1;
};

/**
 * plural returns a label with "s" if value length is more than 1.
 * Also, display the number (unless _count_ set to false).
 * @param label name of entity
 * @param value any value
 * @param showCount display count (true by default)
 */
export const plural = (label: string, value: unknown, showCount = true) => {
  const l = length(value);
  return `${showCount ? `${l} ` : ""}${label}${l > 1 ? "s" : ""}`;
};

/**
 * objectEquals checks if two objects are equal.
 * @param x first object
 * @param y second object
 *
 * @author https://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects/16788517#16788517
 * @todo return false for object in different order?
 */
export const objectEquals = (x: any, y: any): boolean => {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  var p = Object.keys(x);
  return (
    Object.keys(y).every((i) => p.indexOf(i) !== -1) &&
    p.every((i) => objectEquals(x[i], y[i]))
  );
};

export const djb2 = (str: string) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
};

export type Tuple<TItem, TLength extends number> = [TItem, ...TItem[]] & {
  length: TLength;
};

export const hashStringToListedColor = (
  colors: Tuple<string, 16>,
  str: string
) => {
  const hash = djb2(str);
  const x = hash & 0x00000f;
  return colors[x];
};

export const hashStringToColor = (str: string) => {
  const hash = djb2(str);
  var r = (hash & 0xff0000) >> 16;
  var g = (hash & 0x00ff00) >> 8;
  var b = hash & 0x0000ff;
  return (
    "#" +
    ("0" + r.toString(16)).substr(-2) +
    ("0" + g.toString(16)).substr(-2) +
    ("0" + b.toString(16)).substr(-2)
  );
};

// <T extends { id: string }>(arr: Array<T>)
export const buildObject = <T extends { id: string }>(arr: Array<T>) =>
  Object.fromEntries(arr.map((v) => [v.id, v]));

/**
 * array_equals checks if two arrays are shallowy equal.
 * @param a
 * @param b
 * @returns true if both arrays are equal (shallow only).
 */
export const array_shallow_equals = (a: any[], b: any[]) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((v, i) => v === b[i]);

/**
 * replaceArrays replaces element of dest with src
 * @param src
 * @param dest
 * @returns
 */
export const replaceArrays = (src: any[], dest: any[]) =>
  dest.reduce(
    (mergedArray, destItem, i) => {
      const srcItem = src[i];
      if (typeof srcItem === "object" && srcItem !== null) {
        if (Array.isArray(srcItem)) {
          mergedArray[i] = replaceArrays(srcItem, destItem);
        } else {
          mergedArray[i] = mergeObjects(destItem, srcItem);
        }
      } else {
        mergedArray[i] = srcItem !== undefined ? srcItem : destItem;
      }
      return mergedArray;
    },
    [...src]
  );

/**
 * mergeObjects merges a list of objects while preserving
 * nested object properties
 * @param src source object
 * @param list list of objects to merge
 * @returns a fresh object (shallow copy unless merged)
 */
export const mergeObjects = <T extends object>(
  src: T,
  ...list: Partial<T>[]
): T => {
  // console.log({ src, list });
  const res = { ...src };
  for (const obj of list)
    if (obj)
      for (const [key, value] of Object.entries(obj)) {
        if (value === null) continue;
        res[key] =
          isObject(value) && res[key] ? mergeObjects(res[key], value) : value;
      }

  return res;
};

/**
 * mergeObjectsBis is like mergeObject but replaces array element
 * instead of overwriting the whole array
 * @param src
 * @param list
 * @returns
 */
export const mergeObjectsBis = (src: any, ...list: any[]) => {
  const res = { ...src };
  for (const obj of list)
    if (obj)
      for (const [key, value] of Object.entries(obj)) {
        if (value && typeof value === "object" && !Array.isArray(value)) {
          res[key] = res[key] ? mergeObjects(value, res[key]) : value;
        } else if (Array.isArray(value) && Array.isArray(res?.[key])) {
          res[key] = replaceArrays(value, res[key]);
        } else {
          res[key] = value;
        }
      }
  return res;
};

/**
 * mergeObjectList merges a single list of objects.
 * @param list
 * @returns
 */
export const mergeObjectList = <T extends object>(list: T[]): T =>
  list?.length > 0 ? mergeObjects(list[0], ...list.slice(1)) : undefined;

/**
 * stripDefaultValues strips an object from empty default values
 * @param data
 * @returns the striped object
 */

export const stripDefaultValues = (
  data: object,
  opts: { exclude_bool: boolean; exclude_empty_object: boolean } = {
    exclude_bool: true,
    exclude_empty_object: true
  }
) => {
  if (typeof data !== "object") return data;

  return Object.keys(data).reduce((acc, key) => {
    const isObject = data[key] !== null && typeof data[key] === "object";
    let value = isObject ? stripDefaultValues(data[key], opts) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (
      value === undefined ||
      (isEmptyObject && opts.exclude_empty_object) ||
      (opts.exclude_bool && value === false)
    )
      return acc;
    if (Array.isArray(data[key])) value = Object.values(value);
    return Object.assign(acc, { [key]: value });
  }, {});
};

/**
 * insertValueByPath assign a value in an object for a given path
 * (creates object if path not found)
 * @param obj
 * @param path
 * @param value
 * @returns
 */
export const insertValueByPath = (
  obj: any,
  path: (string | number)[],
  value: any,
  replace = false
) => {
  if (!path?.length) return obj;
  const newObj = { ...obj };
  path.reduce((acc, key, index) => {
    if (index === path.length - 1) {
      if (typeof value === "object" && acc[key]) {
        acc[key] = replace ? value : { ...acc[key], ...value };
      } else {
        acc[key] = value;
      }
    } else {
      if (!acc.hasOwnProperty(key)) {
        acc[key] = {};
      } else {
        acc[key] = { ...acc[key] };
      }
    }
    return acc[key];
  }, newObj);
  return newObj;
};

/**
 * rewriteArrayPath rewrites an object with array element as keys
 * ex: { test: { 1:"hello", 2: "world"} } => { test: { 0:"hello", 1: "world"} }
 * @param obj
 * @param key
 * @returns
 */
export const rewriteArrayPath = (obj: Object) => {
  if (!obj) return;
  const q = Object.entries(obj).reduce((acc, [k, v], i) => {
    if (parseInt(k)) return { ...acc, [i]: v };
    return { ...acc, [k]: v };
  }, {});
  return q;
};

// @todo replaces this traverse function with the scv one once the jest test
// works with files importing /schema
// @fixme cannot use scv traverse cause of cyclic dep
const traverse = (obj: any, path: (string | number)[]) =>
  path.reduce((acc, v) => acc?.[v], obj);

/**
 * deleteValueByPath
 * @todo maybe immutable ?
 * @param obj
 * @param path
 * @param rewrite
 * @returns
 */
export const deleteValueByPath = (
  obj: any,
  path: (string | number)[],
  rewrite = true
): any => {
  if (!path?.length) return obj;
  const new_obj = { ...obj };

  let parents: any[] = [];
  let keys: (string | number)[] = [];

  const remove_empty_parents = () => {
    for (let i = parents.length - 1; i >= 0; i--) {
      if (Object.keys(parents[i][keys[i]]).length === 0) {
        delete parents[i][keys[i]];
      } else {
        break;
      }
    }
  };

  path.reduce((acc, key, index, self) => {
    if (index === self.length - 1) {
      if (acc && acc.hasOwnProperty(key)) {
        delete acc[key];
        remove_empty_parents();
      }
    } else {
      if (acc && acc.hasOwnProperty(key)) {
        parents.push(acc);
        keys.push(key);
        acc[key] = { ...acc[key] };
      } else {
        return undefined;
      }
    }
    return acc ? acc[key] : undefined;
  }, new_obj);

  if (typeof path[path.length - 1] === "number" && rewrite) {
    const parent_path = path.slice(0, path.length - 1);
    return insertValueByPath(
      new_obj,
      parent_path,
      rewriteArrayPath(traverse(new_obj, parent_path)),
      true
    );
  }
  return new_obj;
};

/**
 * getAllPaths returns all paths of an object
 * @param obj
 * @param path
 * @returns
 */
export const getAllPaths = (obj: Object, path: string[] = []): string[][] => {
  if (!obj && path.length === 0) return [];
  let paths: string[][] = [];

  if ((!obj || typeof obj !== "object") && path.length > 0) return [path];
  if (Object.keys(obj).length === 0 && path.length > 0) paths.push(path);
  for (let key in obj) {
    let newPath = path.concat(key);
    paths = paths.concat(getAllPaths(obj[key], newPath));
  }
  return paths;
};

export const mergeWithFirstPriority = <T extends Object>(objects: T[]): T => {
  const result = {} as T;
  objects.forEach((obj) => {
    Object.entries(obj).forEach(([k, v]) => {
      if (!result.hasOwnProperty(k)) result[k] = v;
    });
  });
  return result;
};
