import { Cell, type AnyCell } from "@okcontract/cells";
import type { Environment } from "@okcontract/lambdascript";

/**
 * resolve a key, potentially containing λscript expressions.
 * @param env
 * @param key
 * @returns
 * @deprecated should not be used anymore
 * @todo if the expression doesn't resolve, we **don't** want to return it (v)
 */
export const resolveExpr = async (
  env: Environment,
  v: unknown,
  def: AnyCell<unknown>
) =>
  typeof v === "string" && v && env
    ? env.evaluateString(v)
    : v instanceof Cell // for cellified values
      ? env.evaluateString(await v.get()) // @todo map and collect
      : def;
