export class MinHeap<T> {
  private heap: T[] = [];
  private comparator: (a: T, b: T) => number;
  private keyExtractor: (item: T) => unknown | undefined;

  constructor(
    comparator: (a: T, b: T) => number,
    keyExtractor: (item: T) => unknown = undefined
  ) {
    this.comparator = comparator;
    if (keyExtractor !== undefined) this.keyExtractor = keyExtractor;
  }

  private parent(index: number): number {
    return Math.floor((index - 1) / 2);
  }

  private leftChild(index: number): number {
    return 2 * index + 1;
  }

  private rightChild(index: number): number {
    return 2 * index + 2;
  }

  private swap(i: number, j: number) {
    [this.heap[i], this.heap[j]] = [this.heap[j], this.heap[i]];
  }

  enqueue(item: T) {
    this.heap.push(item);
    let index = this.heap.length - 1;

    while (
      index !== 0 &&
      this.comparator(this.heap[index], this.heap[this.parent(index)]) < 0
    ) {
      this.swap(index, this.parent(index));
      index = this.parent(index);
    }
  }

  dequeue(): T | undefined {
    if (this.heap.length === 0) return undefined;

    const root = this.heap[0];
    const last = this.heap.pop();
    if (this.heap.length !== 0) {
      this.heap[0] = last;
      this.heapify(0);
    }
    return root;
  }

  private heapify(index: number) {
    const left = this.leftChild(index);
    const right = this.rightChild(index);
    let smallest = index;

    if (
      left < this.heap.length &&
      this.comparator(this.heap[left], this.heap[smallest]) < 0
    ) {
      smallest = left;
    }

    if (
      right < this.heap.length &&
      this.comparator(this.heap[right], this.heap[smallest]) < 0
    ) {
      smallest = right;
    }

    if (smallest !== index) {
      this.swap(index, smallest);
      this.heapify(smallest);
    }
  }

  peek(): T | undefined {
    return this.heap[0];
  }

  isEmpty(): boolean {
    return this.heap.length === 0;
  }

  delete(item: T): void {
    const index = this.heap.findIndex((h) => this.comparator(h, item) === 0);
    if (index === -1) return; // Item not found

    const lastItem = this.heap.pop();
    if (index < this.heap.length) {
      this.heap[index] = lastItem;
      this.heapify(index);
    }
  }

  deleteByKey(key: unknown): void {
    if (this.keyExtractor === undefined) return;
    const index = this.heap.findIndex((h) => this.keyExtractor(h) === key);
    if (index === -1) return; // Key not found

    const lastItem = this.heap.pop();
    if (index < this.heap.length) {
      this.heap[index] = lastItem;
      this.heapify(index);
    }
  }
}
