<script lang="ts">
  import { type ValueCell } from "@okcontract/cells";
  import {
    EnvKeySlippage,
    type AnyContractQuery,
    type OKTransaction
  } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { AlertBanner } from "@okcontract/uic";
  import { DataEditorLight } from "@scv/dataeditor";

  import AbiValue from "./ABIValue.svelte";
  import SendTx from "./SendTX.svelte";
  import Titles from "./Titles.svelte";

  // Constants
  const lowSlippage = 0.1;
  const highSlippage = 5;

  export let instance = newInstance("ABIFunction");
  export let okTX: OKTransaction<AnyContractQuery>;
  export let hideLabels = true;

  $: btnName = okTX.btnLabel;
  // $: image = okTX.image;
  $: func = okTX.function;
  $: isSending = okTX.isSending;
  $: tx = okTX.tx;
  $: canSendTX = okTX.canSendTX;
  $: readOutput = okTX.readOutput;
  $: env = okTX.env;
  $: editor = okTX.editor;

  // @todo constant expression, recreates cells
  $: slippage = env?.map(
    (_env) => _env.value(EnvKeySlippage) as ValueCell<bigint>,
    "ABIFunction.slippage"
  );
</script>

{#if $editor && !($editor instanceof Error)}
  <!-- {$editor.root.id} -->
  <!-- @todo is this required? -->
  {#key editor.id}
    <!--     {env.id}
    {$editor.env.value(EnvKeyChain).id}
 -->
    <DataEditorLight {instance} editor={$editor} options={{ hideLabels }} />
  {/key}
{/if}
<div class="flex items-center gap-1 flex-wrap py-4">
  <Titles {instance} item={okTX.info} />
</div>

{#if $func instanceof Error}
  Error at $func {$func}
{:else if $func?.stateMutability === "view"}
  <AbiValue {instance} fn={$func} values={readOutput} chain={okTX.contractChain}
  ></AbiValue>
{:else if !($slippage instanceof Error)}
  {#if $slippage <= lowSlippage || $slippage >= highSlippage}
    <div class="absolute bottom-0 left-0 right-0 z-30">
      <AlertBanner style="warning" icon="warning" size="sm">
        <span>
          <b>{$slippage >= highSlippage ? "High" : "Low"} slippage!</b>The
          transaction may {$slippage >= highSlippage ? "be frontrun" : "fail"}
        </span></AlertBanner
      >
    </div>
  {/if}
  <!-- {$canSendTX} -->
  {#key okTX}
    <SendTx
      {instance}
      {okTX}
      tx_data={$tx instanceof Error ? undefined : $tx}
      label={$btnName instanceof Error ? "Send" : $btnName}
      disabled={!$canSendTX || $tx instanceof Error || $isSending}
      on:submit
      on:sent
      on:error
    />
  {/key}
{/if}
