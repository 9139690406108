<script lang="ts">
  import { getTheme } from "@okcontract/uic";
  import { logos } from "./logo";

  // @todo remove string
  export let name: keyof typeof logos | string;
  export let size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" = "sm";
  export let remote = false;

  const theme = getTheme();
  const compiled = theme.compiled;
  let sizer =
    size === "xs"
      ? 3
      : size === "sm"
        ? 4
        : size === "md"
          ? 6
          : size === "lg"
            ? 8
            : 10;

  const ok = "https://app.okcontract.com";
  $: src = logos?.[name]
    ? `${remote ? ok : ""}/assets/logos/${logos[name]}.png`
    : undefined;
</script>

{#if src}
  <img
    {src}
    alt={name}
    class="rounded-full"
    style="--tw-ring-color: {$compiled?.bg
      ? 'background-color: transparent'
      : ''}"
    on:error={() => {
      src = undefined;
    }}
  />
{:else}
  <div
    class="rounded-full bg-purple-300 bg-gradient-to-br from-pink-400 via-purple-400 to-blue-500 h-{sizer} w-{sizer}"
    style="--tw-ring-color: {$compiled?.bg
      ? 'background-color: transparent'
      : ''}"
  />
{/if}
<!--ring-1 ring-white dark:ring-gray-600 ring-opacity-80-->
