import type {
  AbiParameter,
  AbiType,
  SolidityArrayWithTuple,
  SolidityTuple
} from "abitype";

/**
 * formatViemResponse format and add to a viem contract responses (["res1", "res2"])
 * its abi function outputs params
 * ex: ["arg1", "arg2"] to {"0": "arg1", "1": "arg2", "arg1Name": "arg1", "arg2Name": "arg2"}
 * @param abiFunc
 * @param res
 * @returns
 * @todo re-implement functionally
 */
export function formatViemResponse(
  params: readonly AbiParameter[],
  input: unknown[]
): unknown {
  const result: { [key: string]: unknown } = {};
  const values = Array.isArray(input) ? input : [input];
  if (values?.length === 1) {
    const param = params?.[0];
    const value = values?.[0];
    if (param && "components" in param) {
      if (isArrayOfTuple(param.type) || isFixedArray(param.type)) {
        result[param.name] = (value as unknown[]).map(
          (val: unknown[], idx: number) =>
            "components" in param
              ? formatViemResponse(param.components, val)
              : val
        );
        return { ...[values?.[0]], ...result };
      }
      result[param?.name] = formatViemResponse(
        param.components,
        value as unknown[]
      );
      // @todo check
      return { ...values?.[0], ...result };
    }
    return values?.[0];
  }

  if (params.length !== values.length) {
    if (
      params.length === 1 &&
      (isArray(params[0].type) || isFixedArray(params[0].type))
    )
      return values;
    throw new Error("Mismatch between types and values length");
  }

  // const result: { [key: string]: any } = { $length: values.length };

  for (let i = 0; i < params.length; i++) {
    const type = params[i];
    const value = values[i];
    result[i] = value;

    // console.log({ type, value });

    // if (isArray(type.type)) {
    //   result[type.name] = value.map((val: any, idx: number) =>
    //     formatViemResponse(
    //       [{ type: type.type.substring(0, type.type.length - 2) }],
    //       val
    //     )
    //   );
    // } else
    if (type && "components" in type) {
      if (isArrayOfTuple(type.type) || isFixedArray(type.type))
        result[type.name] = (value as unknown[]).map(
          (val: unknown[], idx: number) =>
            "components" in type
              ? formatViemResponse(type.components, val)
              : val
        );
      else
        result[type.name] = formatViemResponse(
          type.components,
          value as unknown[]
        );
    } else {
      if (type?.name) result[type.name] = value;
    }
  }
  return result;
}

export const fixedArrayRegexp = /\[\d+\]$/;
export const isArray = (type: string): type is `${AbiType}[]` =>
  type.endsWith("[]");
export const isFixedArray = (type: string): type is `${AbiType}[${number}]` =>
  fixedArrayRegexp.test(type);
export const isTuple = (type: string): type is SolidityTuple =>
  type === "tuple";
export const isArrayOfTuple = (type: string): type is SolidityArrayWithTuple =>
  type.startsWith("tuple[");
