import {
  type AnyCell,
  type MapCell,
  type SheetProxy,
  collector
} from "@okcontract/cells";

// @todo introduce a type variable that is a sum type of all possible field types?
export type CellObject<T> = AnyCell<Record<string, AnyCell<T>>>;

export const findObjectValue = <T>(
  proxy: SheetProxy,
  obj: CellObject<T>,
  fn: (value: T) => boolean
) => {
  const coll = collector<MapCell<T, false>>(proxy);
  return proxy.map([obj], (cells) => {
    const entries = Object.entries(cells).map((_cell) => _cell[1]);
    return coll(
      proxy.mapNoPrevious(
        entries,
        (..._cells) => _cells.find((value) => fn(value)) || null
      )
    );
  });
};

export const filterObjectValue = <T, NF extends boolean = false>(
  proxy: SheetProxy,
  obj: CellObject<T>,
  predicate: AnyCell<(v: AnyCell<T>) => AnyCell<boolean>>,
  name = "filter",
  nf?: NF
) => {
  const coll = collector<MapCell<AnyCell<T>[], NF>>(proxy);
  return obj.map((_obj) => {
    const entries = Object.entries(_obj).map((_cell) => _cell[1]);
    let prevFn: (elt: AnyCell<T>) => AnyCell<boolean>;
    const keep = proxy.map(
      [predicate],
      (fn, prev: AnyCell<boolean>[]) => {
        const unused = new Set(
          Object.entries(prev || []).map(([k, cell]) => cell.id)
        );
        const keep = entries.map((cell) => {
          const reuse = prevFn === fn;
          if (!reuse) unused.delete(cell.id);
          return reuse ? cell : fn(cell);
        });

        proxy._sheet.collect(...[...unused]);
        prevFn = fn;
        return keep;
      },
      name,
      nf
    );
    return proxy.map(
      [keep],
      (_keep) =>
        coll(
          proxy.mapNoPrevious(
            _keep,
            (..._flat) => entries.filter((_, i) => _flat[i]),
            "filter.map"
          )
        ),
      name,
      nf
    );
  });
};
