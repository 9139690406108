import { newCachedData, type CacheQuery, type DataCacheType } from "./coredata";
import { emptyPartitionFromQuery } from "./partition";
import type { VirtualQueryType } from "./virtual";

/**
 * data_prefixes for each DataType.
 * @todo should be retrieved from server as static JSON
 */
export const data_prefixes = {
  contract: "con",
  org: "org",
  token: "tok",
  "token-chain": "tok",
  nft: "nft",
  stake: "stake",
  swap: "swap",
  lend: "lend",
  upvote: "upvote",
  person: "pp",
  widget: "wid",
  abix: "abix",
  part: "part", // @todo unused
  address: "addr",
  method: "met",
  abi: "abi",
  chain: "chain"
} as const;

// @todo comment, where is it used?
export const invert_data_prefixes = Object.fromEntries(
  Object.entries(data_prefixes).map(([k, v]) => [v, k])
) as {
  [k: string]: DataCacheType;
};

export const removeAllPrefixes = new RegExp(
  Object.keys(invert_data_prefixes).join(":|"),
  "g"
);

/**
 * client-side computed default values when server-side data is missing.
 */
export const prefixDefaultValue = {
  part: emptyPartitionFromQuery
} as const;

const prefixDefaultEntries = Object.entries(prefixDefaultValue);

const queryFromPrefix = (q: CacheQuery) => {
  for (const [k, v] of prefixDefaultEntries) {
    if (q?.startsWith(`${k}:`)) {
      return newCachedData(
        invert_data_prefixes[k],
        q,
        v(q),
        Date.now() / 1000 + 60 // @todo defined for each type
      );
    }
  }
};

export const defaultCachedData = (ql: CacheQuery[]) =>
  ql.map(queryFromPrefix).filter((v) => v !== undefined);

// We retrieve the keys (not exported).
type DataCacheKey = keyof typeof data_prefixes;
// And export the type of values for each key.
export type DataCachePrefix = (typeof data_prefixes)[DataCacheKey];

/**
 * datatypes from each prefix
 * @todo refactor?
 */
export const datatype_from_prefix = (pfx: string) =>
  pfx === "tok" ? "token" : (invert_data_prefixes[pfx] as DataCacheType);

export const prefix_from_datatype = (pfx: string) =>
  data_prefixes[pfx] as DataCachePrefix;

/**
 * strip_datacache_prefix removes any datacache prefixes
 * of a string if found
 * @param q
 * @returns
 * @todo different implementation?
 */
export const strip_datacache_prefix = (str: string) =>
  str && typeof str === "string"
    ? // @todo only one substitution?
      str.replace(removeAllPrefixes, "")
    : "";

type DataCacheKeys = keyof typeof data_prefixes;
export const IndexPrefixQuery = <T extends DataCacheKeys>(
  ty: T,
  prefix: string
): IndexPrefixQueryType<T> => `χ${ty}.prefix:${data_prefixes[ty]}:${prefix}`;

export type IndexPrefixQueryType<T extends DataCacheKeys> =
  | VirtualQueryType<`${T}.prefix`, string>
  | VirtualQueryType<`${T}.prefix`, `${string}:${string}`>;
