import Config from "../../config.json";
const DEV = Config.dev;

import type { AppDefinition } from "@scv/app";
import {
  DatacacheCode,
  FRONT_URL,
  WALLET_PUBKEY,
  WIDGET_APP,
  config_store
} from "@scv/app/config";
import wasm from "@scv/libcrypto";

import "../../src/style.css";

import WidgetWrapper from "./WidgetWrapper.svelte";

const config: AppDefinition = {
  AppID: WIDGET_APP,
  Password: false,
  Metamask: true,
  Guest: true,
  UI: {
    Modes: {}
  },
  Env: {
    [FRONT_URL]: DEV ? "http://localhost:5000" : "https://app.okcontract.com",
    [WALLET_PUBKEY]: "r5wnRH1mdmUug0bDX7A71+dfhHTVxyaOyp/00ZZpX2I=",
    // btoa(String.fromCharCode(...new Uint8Array(await crypto.subtle.digest("SHA-1", new TextEncoder().encode(".apifi")))))
    [DatacacheCode]: ".apifi"
  }
};

const init = async () => {
  await wasm(); // setup wasm
  config_store.set(config);

  const app = new WidgetWrapper({
    target: document.body
  });
};

init();
