// @todo copied from app, delete there

export const FRONT_URL = "f";
export const WALLET_PUBKEY = "p";
export const WIDGET_URL = "u";
// export const UNLOGGED = "l";
// export const LOGGED = "h";

export const WIDGET_APP = "widget";
export const OKCONTRACT_APP = "apifi"; // cspell:disable-line
export const INTERACT_APP = "interact";
export const DISCORD_APP = "discord";
export const TEST_APP = "test";

export type AppIDs =
  | typeof WIDGET_APP
  | typeof OKCONTRACT_APP
  | typeof TEST_APP
  | typeof INTERACT_APP
  | typeof DISCORD_APP;

// @todo from app/login
export const UNLOGGED = "unlogged" as const;
export const LOGGING_IN = "loading" as const;
export const VERIFYING = "verifying" as const;
export const LOGGED = "logged" as const;

export type LogState =
  | typeof UNLOGGED
  | typeof LOGGING_IN
  | typeof VERIFYING
  | typeof LOGGED;
