export const logos = {
  okcontract: "ok",
  metamask: "metamask",
  rabbywallet: "rabbywallet",
  argentwallet: "argentwallet",
  ledgerwallet: "ledgerwallet",
  trustwallet: "trustwallet",
  coinbasewallet: "coinbasewallet",
  arbitrum: "arbitrum",
  ethereum: "ethereum",
  fantom: "fantom",
  avalanche: "avalanche",
  binancesmartchain: "bsc",
  bsc: "bsc",
  optimism: "optimism",
  optimismgoerli: "optimism",
  base: "base",
  polygon: "polygon",
  mumbai: "polygon",
  matic: "matic",
  era: "zksync",
  zksync: "zksync",
  rinkeby: "rinkeby",
  goerli: "goerli",
  lineagoerli: "linea",
  linea: "linea",
  cronos: "cronos",
  okxchain: "okxchain",
  okx: "okxchain",
  wokt: "wokt",
  shardeum: "shardeum",
  shm: "shardeum",
  sepolia: "sepolia",
  "0x": "0x",
  "1inch": "1inch",
  aave: "aave",
  avax: "avalanche",
  wavax: "wavax",
  abracadabra: "abracadabra",
  spell: "spell",
  alchemix: "alchemix",
  alcx: "alcx",
  autofarm: "autofarm",
  auto: "auto",
  axieinfinity: "axieinfinity",
  axs: "axs",
  badgerdao: "badgerdao",
  badger: "badger",
  bnb: "bnb",
  wbnb: "bnb",
  busd: "busd",
  cro: "cro",
  dodo: "dodo",
  pancakeswap: "pancakeswap",
  cake: "cake",
  chainlink: "chainlink",
  link: "link",
  compound: "compound",
  comp: "comp",
  convex: "convex",
  cvx: "cvx",
  curve: "curve",
  crv: "crv",
  dopex: "dopex",
  dpx: "dpx",
  gmx: "gmx",
  decentraland: "decentraland",
  mana: "mana",
  land: "land",
  enjin: "enjin",
  enj: "enj",
  gro: "gro",
  klima: "klima",
  looksrare: "looksrare",
  looks: "looks",
  pickle: "pickle",
  rari: "rari",
  rgt: "rgt",
  quickswap: "quickswap",
  quick: "quick",
  spookyswap: "spookyswap",
  boo: "boo",
  sushi: "sushi",
  thegraph: "thegraph",
  thesandbox: "thesandbox",
  sand: "sand",
  thorswap: "thorswap",
  thor: "thor",
  tokemak: "tokemak",
  toke: "toke",
  uniswap: "uniswap",
  uni: "uni",
  usdc: "usdc",
  usdt: "usdt",
  vesta: "vesta",
  solidly: "solidly",
  solid: "solid",
  solidex: "solidex",
  sex: "sex",
  weth: "weth",
  yearn: "yearn",
  yfi: "yfi",
  makerdao: "makerdao",
  dai: "dai",
  wbtc: "wbtc",
  lido: "lido",
  instadapp: "instadapp",
  balancer: "balancer",
  frax: "frax",
  venus: "venus",
  traderjoe: "traderjoe",
  renvm: "renvm",
  synthetix: "synthetix",
  bancor: "bancor",
  beefy: "beefy",
  dydx: "dydx",
  flexa: "flexa",
  alpha: "alpha",
  alpaca: "alpaca",
  mirror: "mirror",
  keep3rnetwork: "keep3rnetwork",
  truefi: "truefi",
  maple: "maple",
  tornadocash: "tornadocash",
  armor: "armor",
  dforce: "dforce",
  biswap: "biswap",
  nexusmutual: "nexusmutual",
  notional: "notional",
  fei: "fei",
  wonderland: "wonderland",
  vvs: "vvs",
  stakedao: "stakedao",
  loopring: "loopring",
  yieldyak: "yieldyak",
  ysl: "ysl",
  origindollar: "origindollar",
  olympusdao: "olympusdao",
  olympus: "olympusdao",
  qidao: "qidao",
  defidollardao: "defidollardao",
  apeswap: "apeswap",
  thorchain: "thorchain",
  blizz: "blizz",
  belt: "belt",
  reflexer: "reflexer",
  pnetwork: "pnetwork",
  ankr: "ankr",
  stakewise: "stakewise",
  bunny: "bunny",
  injective: "injective",
  enzyme: "enzyme",
  acryptos: "acryptos",
  ramp: "ramp",
  zkswap: "zkswap",
  mantradao: "mantradao",
  o3swap: "o3swap",
  vesper: "vesper",
  kyber: "kyber",
  metronome: "metronome",
  centrifuge: "centrifuge",
  pooltogether: "pooltogether",
  inverse: "inverse",
  deversifi: "deversifi",
  "88mph": "88mph",
  dfynnetwork: "dfynnetwork",
  defiswap: "defiswap",
  bao: "bao",
  sharedstake: "sharedstake",
  ichifarm: "ichifarm",
  sifchain: "sifchain",
  insurace: "insurace",
  bakeryswap: "bakeryswap",
  boringdao: "boringdao",
  atlantisloans: "atlantisloans",
  beanstalk: "beanstalk",
  yaxis: "yaxis",
  derivadex: "derivadex",
  perpetual: "perpetual",
  tetu: "tetu",
  pendle: "pendle",
  idex: "idex",
  reserve: "reserve",
  dfx: "dfx",
  mcdex: "mcdex",
  popsicle: "popsicle",
  orion: "orion",
  snowball: "snowball",
  pacoca: "pacoca",
  gnosis: "gnosis",
  premia: "premia",
  dhedge: "dhedge",
  valuedefi: "valuedefi",
  powerpool: "powerpool",
  jetfuel: "jetfuel",
  bridgemutual: "bridgemutual",
  snowbank: "snowbank",
  nervenetwork: "nervenetwork",
  nftx: "nftx",
  mistswap: "mistswap",
  burgerswap: "burgerswap",
  ethernitychain: "ethernitychain",
  boofinance: "boofinance",
  alchemist: "alchemist",
  btcst: "btcst",
  perlin: "perlin",
  opium: "opium",
  farmersonly: "farmersonly",
  apwine: "apwine",
  cardstarter: "cardstarter",
  erasure: "erasure",
  adamant: "adamant",
  gearbox: "gearbox",
  unit: "unit",
  xtoken: "xtoken",
  wault: "wault",
  indexed: "indexed",
  eleven: "eleven",
  cap: "cap",
  cometh: "cometh",
  luaswap: "luaswap",
  warp: "warp",
  golff: "golff",
  tokenlon: "tokenlon",
  mushrooms: "mushrooms",
  marginswap: "marginswap",
  vee: "vee",
  spartan: "spartan",
  macaronswap: "macaronswap",
  cvi: "cvi",
  swamp: "swamp",
  unfederalreserve: "unfederalreserve",
  goose: "goose",
  float: "float",
  cub: "cub",
  iron: "iron",
  bearn: "bearn",
  cvault: "cvault",
  whiteheart: "whiteheart",
  polycat: "polycat",
  unilend: "unilend",
  augur: "augur",
  convergence: "convergence",
  swipe: "swipe",
  basketdao: "basketdao",
  wardenswap: "wardenswap",
  lydia: "lydia",
  avaware: "avaware",
  nft20: "nft20",
  nsure: "nsure",
  timewarp: "timewarp",
  cafeswap: "cafeswap",
  unifi: "unifi",
  rubic: "rubic",
  strudel: "strudel",
  bigdata: "bigdata",
  augury: "augury",
  ethalend: "ethalend",
  unitrade: "unitrade",
  sakeperp: "sakeperp",
  cover: "cover",
  dev: "dev",
  zero: "zero",
  oddz: "oddz",
  peakdefi: "peakdefi",
  xdefi: "xdefi",
  halodao: "halodao",
  auctus: "auctus",
  domani: "domani",
  superfarm: "superfarm",
  bagels: "bagels",
  ruler: "ruler",
  basiscash: "basiscash",
  stargate: "stargate",
  paprprintr: "paprprintr",
  valueliquid: "valueliquid",
  finnexus: "finnexus",
  miniswap: "miniswap",
  bt: "bt",
  defiyield: "defiyield",
  definer: "definer",
  mochiswap: "mochiswap",
  bepswap: "bepswap",
  synlev: "synlev",
  avalaunch: "avalaunch",
  shapeshift: "shapeshift",
  banano: "banano",
  fegex: "fegex",
  oraichain: "oraichain",
  saffron: "saffron",
  hex: "hex",
  mobox: "mobox",
  ferrum: "ferrum",
  ovr: "ovr",
  unizen: "unizen",
  api3: "api3",
  acs: "acs",
  addy: "addy",
  amp: "amp",
  apw: "apw",
  atl: "atl",
  auc: "auc",
  ave: "ave",
  bac: "bac",
  bagel: "bagel",
  bake: "bake",
  bal: "bal",
  ban: "ban",
  banana: "banana",
  bank: "bank",
  bask: "bask",
  bdp: "bdp",
  bean: "bean",
  bfi: "bfi",
  bifi: "bifi",
  blzz: "blzz",
  bmi: "bmi",
  bnt: "bnt",
  boofi: "boofi",
  boring: "boring",
  brew: "brew",
  bsw: "bsw",
  burger: "burger",
  cards: "cards",
  cfg: "cfg",
  chrono: "chrono",
  conv: "conv",
  core: "core",
  cpv: "cpv",
  ddx: "ddx",
  dextf: "dextf",
  df: "df",
  dfd: "dfd",
  dfyn: "dfyn",
  dht: "dht",
  duck: "duck",
  dvf: "dvf",
  dyp: "dyp",
  egg: "egg",
  ele: "ele",
  ern: "ern",
  erowan: "erowan",
  ersdl: "ersdl",
  etha: "etha",
  feg: "feg",
  fin: "fin",
  fish: "fish",
  flx: "flx",
  fnx: "fnx",
  fox: "fox",
  frm: "frm",
  fuel: "fuel",
  fxs: "fxs",
  gear: "gear",
  gno: "gno",
  gof: "gof",
  govi: "govi",
  ice: "ice",
  ichi: "ichi",
  inj: "inj",
  inst: "inst",
  insur: "insur",
  inv: "inv",
  joe: "joe",
  knc: "knc",
  kp3r: "kp3r",
  ldo: "ldo",
  lon: "lon",
  lrc: "lrc",
  lua: "lua",
  lyd: "lyd",
  mbox: "mbox",
  mcb: "mcb",
  mcrn: "mcrn",
  met: "met",
  mfi: "mfi",
  mini: "mini",
  mir: "mir",
  mist: "mist",
  mkr: "mkr",
  mln: "mln",
  mm: "mm",
  mochi: "mochi",
  mph: "mph",
  mpl: "mpl",
  muse: "muse",
  must: "must",
  ndx: "ndx",
  nmr: "nmr",
  note: "note",
  nvt: "nvt",
  nxm: "nxm",
  o3: "o3",
  ohm: "ohm",
  om: "om",
  omen: "omen",
  orai: "orai",
  orn: "orn",
  ousd: "ousd",
  papr: "papr",
  peak: "peak",
  perl: "perl",
  perp: "perp",
  pnt: "pnt",
  pool: "pool",
  rbc: "rbc",
  ren: "ren",
  rep: "rep",
  rnbw: "rnbw",
  rsr: "rsr",
  rune: "rune",
  sake: "sake",
  sb: "sb",
  sdt: "sdt",
  sfi: "sfi",
  sgt: "sgt",
  snob: "snob",
  snx: "snx",
  sparta: "sparta",
  super: "super",
  swise: "swise",
  sxp: "sxp",
  sysl: "sysl",
  time: "time",
  torn: "torn",
  trade: "trade",
  trdl: "trdl",
  tru: "tru",
  uft: "uft",
  unfi: "unfi",
  value: "value",
  vsp: "vsp",
  wad: "wad",
  wex: "wex",
  white: "white",
  xava: "xava",
  xdex: "xdex",
  xtk: "xtk",
  yak: "yak",
  zcx: "zcx",
  zks: "zks",
  aavegotchi: "aavegotchi",
  ghst: "ghst",
  acoconut: "acoconut",
  ac: "ac",
  aelin: "aelin",
  agarthadao: "agarthadao",
  aladdindao: "aladdindao",
  ald: "ald",
  algebradex: "algebradex",
  algb: "algb",
  alita: "alita",
  ali: "ali",
  aliumswap: "aliumswap",
  alm: "alm",
  wftm: "wftm",
  alusd: "alusd",
  "3rcv": "3crv",
  aleth: "aleth",
  seth: "seth",
  rtfkt: "rtfkt",
  doodles: "doodles"
};
