// This file was generated by lezer-generator. You probably shouldn't edit it.
export const terminator = 90,
  Identifier = 1,
  tripleStringContent = 91,
  stringContent = 92,
  commandStringContent = 93,
  immediateParen = 94,
  immediateBracket = 95,
  immediateDoubleQuote = 96,
  immediateBackquote = 97,
  immediateDot = 98,
  SourceFile = 2,
  UnaryOperatorExtra = 5,
  PowerOperator = 7,
  TimesOperator = 8,
  PlusOperator = 9,
  ComparisonOperator = 12,
  OrOperator = 13,
  AndOperator = 14,
  BooleanValue = 48,
  Character = 49,
  NumberValue = 50,
  SymbolValue = 51,
  StringValue = 52,
  TripleString = 53,
  CommandString = 54,
  PrefixedString = 55;
