<script lang="ts">
  import { ValueCell, type AnyCell } from "@okcontract/cells";
  import { type Chain } from "@okcontract/multichain";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { Logo } from "@scv/dcuic";

  export let instance = newInstance("ChainListItem");
  export let chain: AnyCell<Chain>;
  export let open: ValueCell<boolean>;

  const core = instance._core;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const switch_action = (chain: Chain) => () => {
    open.set(false);
    core.SwitchChain(chain.id);
  };
</script>

{#if $chain instanceof Error}
  Chain Error
{:else if $chain}
  <button
    on:click={switch_action($chain)}
    class="flex w-full flex-row items-center px-2 py-1 {theme.dark(
      $compiledTheme,
      'text-white hover:bg-white/10',
      'text-gray-900 hover:bg-black/5',
      'text-gray-900 hover:bg-black/5 dark:text-white dark:hover:bg-white/10'
    )}"
  >
    <Logo logo={$chain.id} size="sm" />
    <div class="ml-2 font-medium">
      {$chain.name}
    </div>
  </button>
{/if}
