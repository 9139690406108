<script lang="ts">
  import { DataEditor, type TypeScheme } from "@okcontract/fred";
  import {
    settingsSchema,
    settingsTypescheme,
    type Instance
  } from "@okcontract/sdk";
  import { Dropdown, Icon, getTheme } from "@okcontract/uic";
  import { DataEditorLight } from "@scv/dataeditor";

  export let instance: Instance;
  const proxy = instance._proxy;
  const core = instance._core;

  export let theme = getTheme();
  const compiledTheme = theme?.compiled;

  const schema = {
    types: proxy.new(settingsTypescheme(proxy)),
    values: proxy.new(settingsSchema(proxy)),
    gs: proxy.new([])
  } as TypeScheme;

  // We directly map the Environment value cells to a new proxy.
  const data = proxy.new(core.Settings._values);
  const editor = new DataEditor(proxy, data, schema);

  let buttonElement: HTMLElement;
  const open = proxy.new(false);
</script>

<Dropdown
  style="bottom-end"
  {open}
  {buttonElement}
  on:close={() => open.set(false)}
>
  <div slot="action">
    <button
      bind:this={buttonElement}
      on:click={() => open.update((_open) => !_open)}
      class="flex flex-row items-center justify-center btn btn-xs rounded-full {theme.dark(
        $compiledTheme,
        'btn-white-alpha',
        'btn-black-alpha',
        'btn-ghost'
      )}"
    >
      <Icon name={"cog-o"} size={"xs"} opacity="70" />
    </button>
  </div>

  <div slot="main" class="min-w-[200px]">
    <DataEditorLight {instance} {editor} />
  </div>
</Dropdown>
