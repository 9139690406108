import Context from "./context";

class ReverseContext extends Context {
  delta;
  pipe: string;

  constructor(delta) {
    super();
    this.delta = delta;
    this.pipe = "reverse";
  }
}

export default ReverseContext;
