import type { CacheQuery, DataCacheType } from "./coredata";

/**
 * Partition is a specialized index over datacache.
 * @todo Queries are always same type? If so, introduce type variable.
 */
export type Partition = {
  /** datacache type */
  ty: DataCacheType;
  /** key name */
  k: string;
  /** attribute value */
  v: string;
  /** list of matching queries */
  q: CacheQuery[];
};

export type PartitionQueryType =
  | `part:${string}:${string}:${string}`
  | `part:${string}`; // @todo unused

export const PartitionQuery = (
  ty: DataCacheType,
  k: string,
  v: string
): PartitionQueryType => `part:${ty}:${k}:${v}`; // @todo unused

export const emptyPartition = (
  ty: DataCacheType,
  k: string,
  v: string
): Partition => ({
  ty,
  k,
  v,
  q: []
});

export const emptyPartitionFromQuery = (q: string) => {
  const l = q.split(":");
  if (l.length < 4 || l[0] !== "part") return;
  return emptyPartition(l[1] as DataCacheType, l[2], l[3]);
};
