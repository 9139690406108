import type {
  AnyCell,
  Cellified,
  SheetProxy,
  ValueCell
} from "@okcontract/cells";
import {
  convertABI,
  coredata_search_type,
  type DataCacheType
} from "@okcontract/coredata";
import {
  EDIT,
  defaultPatterns,
  objectDefinition,
  type EditorMode,
  type LabelledTypeDefinition,
  type MapTypeDefinitions
} from "@okcontract/fred";
import {
  ABIMethodFieldsRef,
  ABIMethodRef,
  ABIValuesRef
} from "@okcontract/libwidget";
import type { EVMAddress } from "@okcontract/multichain/src/address";

import { chainSchema } from "./data-chain";
import { contractSchema } from "./data-contract";
import { orgSchema } from "./data-org";
import { tokenSchema } from "./data-token";
import { foreignIdOfSlug, foreignOrgId } from "./foreign";
import type { Instance } from "./instance";

const rev_pool_token_type = {
  token: "Basic token",
  stake: "Staked token",
  swap: "Swap pool token",
  lend: "Lending pool token",
  nft: "NFT"
};

const channel_type = {
  Website: "Web",
  Twitter: "Tw",
  Discord: "Dis",
  Other: "O",
  GitHub: "Gh",
  Telegram: "Tg",
  Reddit: "Rdt",
  Medium: "Med",
  Instagram: "Ins",
  "ENS Domain": "ENS"
} as const;

const channel_of_slug = Object.fromEntries(
  Object.entries(channel_type).map(([k, v]) => [v, k])
);

export const EVMAddressDefinition =
  (proxy: SheetProxy) => (): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      proxy,
      {
        chain: {
          label: "Chain",
          name: "ChainType"
        },
        addr: {
          label: "Address",
          base: "string",
          isAddress: true,
          disableOneClick: true,
          pl: "0x...",
          // @todo pattern should not be required for isAddress
          pattern: defaultPatterns.ethaddr
        },
        px: {
          label: "Proxy",
          base: "string",
          optional: true,
          isAddress: true,
          disableOneClick: true,
          pl: "0x...",
          pattern: defaultPatterns.ethaddr
        },
        ty: {
          label: "Type",
          enum: { c: { label: "Contract" }, w: { label: "Wallet" } },
          def: "c",
          hidden: true
        }
      },
      "Address"
    );

/**
 * coredataTypeScheme builds a static TypeScheme for coredata.
 * @param intf
 * @returns
 * @todo generalize an environment to get interactive data, rather than static parameters (toks, intf)
 */
export const coredataTypeScheme = (
  instance: Instance,
  is_admin?: boolean,
  mode?: EditorMode,
  type?: DataCacheType,
  methods?: string[]
): MapTypeDefinitions => {
  // @todo remove
  const proxy = instance._proxy;
  return {
    Org: orgSchema(instance, mode),
    Token: tokenSchema(instance, mode, is_admin),
    Contract: contractSchema(instance, mode, is_admin),
    SmartContractAction: () =>
      objectDefinition(
        instance._proxy,
        {
          ty: { label: "Type", base: "string" },
          com: { label: "Comment", base: "string" },
          reviewed: {
            label: "Has been reviewed",
            base: "boolean"
          }
        },
        "Contract Action"
      ),
    Payload: () =>
      objectDefinition(
        instance._proxy,
        {
          q: { label: "Contract", base: "string" },
          m: { label: "Method", base: "string" },
          title: {
            label: "Title",
            array: () => proxy.new({ label: "Text", base: "string" })
          },
          values: {
            label: "Values",
            dict: () =>
              objectDefinition(instance._proxy, {
                ty: { label: "Type of value", name: "Type" }
              })
          }
        },
        "Widget Customizer"
      ),
    Type: () =>
      objectDefinition(
        instance._proxy,
        {
          base: {
            label: "Base Type",
            enum: ["string", "number", "date", "boolean"]
          },
          array: () =>
            proxy.new({
              label: "Is an array?",
              base: "boolean"
            })
        },
        "Type Definition"
      ),
    ABI: () =>
      objectDefinition(
        proxy,
        {
          addr: EVMAddressDefinition(proxy),
          abi: () =>
            proxy.new({
              label: "ABI",
              base: "string",
              hint: "ABI definitions as JSON format or function signatures",
              long: true,
              lens: (v: ValueCell<string>) => {
                const abi: ValueCell<string> = proxy.new(undefined, "abi.lens");
                v.subscribe((_v) => {
                  abi.set(_v);
                });
                abi.subscribe((_abi) => {
                  v.set(convertABI(_abi));
                });
                return abi;
              }
            })
        },
        "ABI definition",
        {
          validator: (abi: {
            abi: ValueCell<string>;
            addr: Cellified<EVMAddress>;
          }) => {
            if (!abi?.abi) return;
            try {
              JSON.parse(abi.abi.value);
              // @todo check parseAbi structure
            } catch (error) {
              return "invalid abi";
            }
          }
        }
      ),
    ABIExtra: () =>
      objectDefinition(
        proxy,
        {
          id: {
            label: "Unique ABIExtra ID",
            locked: mode === EDIT,
            search: mode === EDIT ? "abix" : undefined,
            base: "string",
            gr: "id"
          },
          name: {
            label: "Name",
            base: "string",
            gr: "id",
            min: 3
          },
          desc: {
            label: "Description",
            base: "string",
            long: true,
            optional: true,
            gr: "id"
          },
          err: () =>
            proxy.new({
              label: "Error definitions",
              optional: true,
              gr: "e",
              array: () =>
                objectDefinition(
                  proxy,
                  {
                    pat: {
                      label: "Pattern to match",
                      base: "string"
                    },
                    dis: {
                      label: "Should be displayed?",
                      base: "boolean"
                    },
                    msg: {
                      label: "Human-friendly message",
                      base: "string",
                      optional: true
                    }
                  },
                  "",
                  {
                    def: ["", "", ""],
                    lens: (v: ValueCell<ValueCell<string>[]>) => {
                      const pat = proxy.new(undefined, "ABIExtra.err.pat");
                      const dis = proxy.new(undefined, "ABIExtra.err.dis");
                      const msg = proxy.new(undefined, "ABIExtra.err.msg");

                      v.subscribe((_v) => {
                        pat.set(_v[0]);
                        dis.set(_v[1]);
                        msg.set(_v[2]);
                      });

                      const arr = proxy.map(
                        [pat, dis, msg],
                        (_pat, _dis, _msg) => ({ pat, dis, msg })
                      );

                      arr.subscribe((_arr) => {
                        if (_arr instanceof Error) throw _arr;
                        v.set([_arr.pat, _arr.dis, _arr.msg]);
                      });
                      return arr;
                    }
                  }
                )
            }),
          values: {
            label: "Values",
            name: "ABIValues",
            optional: true,
            gr: "v"
          },
          methods: {
            label: "Methods",
            name: "ABIMethods",
            gr: "m",
            optional: true
          },
          pivot: {
            label: "Pivot",
            name: "Pivot",
            gr: "p",
            optional: true
          }
        },
        "ABI metadata"
      ),
    ABIMethods: () =>
      proxy.new({
        label: "ABIMethod",
        dict: () =>
          proxy.new({
            label: "ABIMethod",
            name: "ABIMethod",
            keys: methods
          })
      }),
    ABIMethod: ABIMethodRef(proxy),
    ABIMethodFields: ABIMethodFieldsRef(proxy),
    ABIValues: ABIValuesRef(proxy),
    Pivot: () =>
      objectDefinition(
        instance._proxy,
        {
          name: {
            label: "Pivot name (or entity)",
            base: "string"
          },
          val: { label: "Pivot value", base: "string" },
          range: {
            label:
              "Name of value containing the range (assuming the range is a number)",
            base: "string"
          },
          label: {
            label:
              "Name of value containing the address of label of a given pivot value",
            base: "string"
          }
        },
        "Pivot",
        { optional: true }
      ),
    TokenID: (node, env) => {
      if (node?.value === undefined)
        return instance._proxy.new({ label: "Token", base: "string" });
      const self = instance._proxy.get(node.value);
      return instance._proxy.map([self], (_self: string) => ({
        label: "Token",
        base: "string",
        // @todo maybe search should take a Cell directly
        search: coredata_search_type(_self) || "token"
      }));
    },
    ContractID: () =>
      instance._proxy.new({
        label: "Contract",
        base: "string",
        search: "contract"
        // FIXME: pattern...
      }),
    ChainType: () =>
      instance._proxy.map([instance._core.Chains], (chains) => ({
        label: "Chain",
        enum: Object.keys(chains).map((ch) => ch.substring(1))
      })),
    Channel: (node, env) =>
      proxy.new({
        label: "Link",
        lens: (v: ValueCell<string>) => {
          const platform = proxy.new(undefined);
          const value = proxy.new(undefined);
          v.subscribe((_v) => {
            platform.set(channel_of_slug[_v.substring(0, _v.indexOf(":"))]);
            value.set(_v.substring(_v.indexOf(":") + 1));
          });
          platform.subscribe((p) => {
            if (p instanceof Error) return;
            v.set(`${channel_type[platform.value] || ""}:${value.value || ""}`);
          });
          value.subscribe((p) => {
            if (p instanceof Error) return;
            v.set(`${channel_type[platform.value] || ""}:${value.value || ""}`);
          });
          return proxy.map([platform, value], (_platform, _value) => ({
            Platform: platform,
            Value: value
          }));
        },
        def: "Web:",
        object: proxy.new({
          Platform: () =>
            proxy.new({
              label: "Platform",
              enum: Object.keys(channel_type),
              optional: true,
              unique: true // applies to parent? FIXME: unique: Platform
            }),
          Value: () =>
            proxy.new({
              label: "Slug or ID",
              base: "string",
              optional: true
            })
        }),
        inline: true
      }),
    SearchAddress: () =>
      proxy.new({
        label: "Address",
        isAddress: true,
        base: "string"
      }),
    EVMAddress: EVMAddressDefinition(proxy),
    PoolTokenType: () =>
      proxy.new({
        label: "Category",
        enum: rev_pool_token_type,
        locked: type === "nft",
        def: type
      }),
    // ForeignTokId: {
    //   label: "Crypto platform identifiers",
    //   object: {
    //     Platform: {
    //       label: "Platform",
    //       enum: Object.keys(foreign_tok_id),
    //     },
    //     Value: { label: "ID", base: "string", hint: "API id" },
    //   },
    //   inline: true,
    // },
    ForeignOrgId: () =>
      objectDefinition(
        proxy,
        {
          Platform: {
            label: "Platform",
            enum: Object.keys(foreignOrgId)
          },
          Value: {
            label: "Slug or ID",
            base: "string"
          }
        },
        "Crypto platform identifiers",
        {
          lens: (v: ValueCell<string>) => {
            const platform = proxy.new(undefined, "ForeignOrgId.platform.lens");
            const value = proxy.new(undefined, "ForeignOrgId.value.lens");
            v.subscribe((_v) => {
              platform.set(foreignIdOfSlug[_v.substring(0, _v.indexOf(":"))]);
              value.set(_v.substring(_v.indexOf(":") + 1));
            });
            platform.subscribe((p) => {
              if (p instanceof Error) return;
              v.set(`${foreignOrgId[p] || ""}:${value.value || ""}`);
            });
            value.subscribe((p) => {
              if (p instanceof Error) return;
              v.set(
                `${foreignOrgId[platform.value] || ""}:${value.value || ""}`
              );
            });
            return proxy.map([platform, value], (_platform, _value) => ({
              Platform: platform,
              Value: value
            }));
          },
          inline: true,
          def: "coingecko:"
        }
      ),
    Person: (node, env) =>
      objectDefinition(
        instance._proxy,
        {
          name: {
            label: "Name",
            base: "string"
          },
          bio: {
            label: "Short bio",
            base: "string",
            long: true
          },
          pfp: {
            label: "Profile picture URL",
            base: "string",
            pattern: defaultPatterns.url
          },
          chans: {
            label: "Website, Social media",
            array: () => proxy.new({ name: "Channel" })
          },
          orgs: {
            label: "Existing organizations",
            array: () => proxy.new({ label: "Org", base: "string" }),
            locked: true
          }
        },
        "Profile"
      ),
    Chain: chainSchema(instance, mode)
  };
};
