import type { EventDispatcher } from "svelte";

import type { MapCell, ValueCell } from "@okcontract/cells";
import type { Instance } from "@okcontract/sdk";
import type { DropdownStyle, InputStyle } from "@okcontract/uic";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ENTER,
  ESCAPE
} from "@scv/utils";

type SearchSelectorOptions<T> = {
  size?: "sm" | "md" | "lg";
  placeholder?: string;
  delay?: number;
  /** whether a remove button is available to delete selected value */
  isRemovable?: boolean;
  inputStyle?: InputStyle;
  toolBarStyle?: DropdownStyle;
  toolBarExtension?: boolean;

  createLabel?: string;
  /** function controlling if a user can create a new value */
  canCreateFn?: ((input: T, list?: T[]) => Promise<boolean>) | undefined;
};

export class SearchSelector<T extends string> {
  readonly instance: Instance;

  dispatch: EventDispatcher<Record<string, unknown>>;
  /** the selected value (main value) */
  readonly selected: ValueCell<T>;
  /** input from the search input */
  readonly input: ValueCell<T>;
  /** the searched results */
  readonly results: MapCell<T[], boolean>;
  /** open or close the dropdown */
  readonly isOpen: ValueCell<boolean>;
  /** wether we can add / create a new value if not in results */
  readonly canCreate: MapCell<boolean, boolean>;
  /** the index of the hovered / active element in the toolbar */
  readonly activeIndex: ValueCell<number>;
  /** the options */
  readonly opts: SearchSelectorOptions<T>;

  constructor(
    instance: Instance,
    dispatch: EventDispatcher<Record<string, unknown>>,
    selected: ValueCell<T>,
    onSearch: (input: T) => Promise<T[]>,
    opts?: SearchSelectorOptions<T>
  ) {
    this.instance = instance;
    this.dispatch = dispatch;
    this.selected = selected;
    this.input = instance._proxy.new("" as T, "SearchSelector.input");
    this.isOpen = instance._proxy.new(false, "SearchSelector.open");
    this.activeIndex = instance._proxy.new(0, "SearchSelector.activeIndex");
    this.opts = opts || {};

    // on input changes we call the search fn
    this.results = instance._proxy.map([this.input], async (_input) => {
      const res = await onSearch(_input);
      return res?.length ? res : [];
    });

    // input should always have the selected value
    this.selected.subscribe((_selected) => this.input.set(_selected));

    // check if we can add a new value
    this.canCreate = instance._proxy.map(
      [this.results, this.input],
      (_results, _inputs) => {
        if (!opts?.canCreateFn) return false;
        return opts.canCreateFn(_inputs, _results);
      }
    );
  }

  open() {
    this.isOpen.set(true);
  }

  close() {
    this.isOpen.set(false);
  }

  create(input: T) {
    this.dispatch("create", { input, component: this });
  }

  select(selected: T, index: number) {
    this.dispatch("select", { selected, index, component: this });
  }

  clear() {
    this.close();
    this.input.set("" as T);
    this.selected.set("" as T);
  }

  async handleKeyboard(
    event: KeyboardEvent & {
      currentTarget: EventTarget & HTMLInputElement;
    }
  ) {
    const caretIndex = event.currentTarget?.selectionStart;
    switch (event.key) {
      case ESCAPE:
        this.close();
        break;
      case ENTER:
        return this.handleEnter();
      case ARROW_DOWN:
      case ARROW_UP:
        return this.handleArrowNavigation(event);
      case ARROW_LEFT:
        if (caretIndex === 0) {
          this.close();
          event.stopImmediatePropagation();
        }
        break;
      case ARROW_RIGHT:
        // @ts-expect-error value
        if (caretIndex === event.target?.value?.length) {
          this.close();
          event.stopImmediatePropagation();
        }
        break;
      default:
        this.open();
        break;
    }
  }

  private async handleEnter() {
    const canCreate = await this.canCreate.get();
    if (canCreate) return this.create((this.input.value || "") as T);

    const results = await this.results.get();
    if (results instanceof Error) throw results;

    const activeIndex = await this.activeIndex.get();
    const activeValue = results?.[activeIndex || 0];
    return this.select(activeValue, activeIndex);
  }

  private async handleArrowNavigation(event: KeyboardEvent) {
    const results = await this.results.get();
    if (results instanceof Error) throw results;

    const activeIndex = await this.activeIndex.get();
    event.preventDefault();
    const increment = event.key === ARROW_UP ? -1 : 1;
    const computedIndex = (activeIndex + increment) % results?.length;
    this.activeIndex.update((_activeIndex) =>
      _activeIndex < 0 ? results.length - 1 : computedIndex
    );

    const p = document.querySelector("ul.options > li.active");
    if (p)
      "scrollIntoViewIfNeeded" in p
        ? // @ts-expect-error Not part of any specification. This is a proprietary, WebKit-specific method.
          p.scrollIntoViewIfNeeded()
        : p.scrollIntoView(); // Firefox
  }
}
