import { DiffPatcher } from "@scv/jsondiffpatch";

import type { CacheQuery } from "@okcontract/coredata";

// FIXME: get from "con:okcontract/okdb"?

// @todo add goerli okdb_chain
export const okdb_chains = {
  // rinkeby: "0x06ba4022d3d309c2a833a483d3b502316cf217c0",
  goerli: "0x"
} as const;

export const okdb_default_chain = "goerli";

// "function grantOrg(bytes calldata _org, address _grantee)", // ADMIN
export const okdb_abi = [
  "function OrganizationID(uint256 _id) public view returns (bytes)",
  "function ownedOrgs(address _addr) external view returns (uint256[] memory)",
  "function orgEditors(bytes calldata _org) returns (uint256[] memory)",
  "function canWrite(address _addr,bytes calldata _key) public view returns (bool)",
  "function update(bytes calldata _key,bytes calldata _value,bool _patch) external",
  "function Keys(bytes calldata _key) public view returns (uint256 root)",
  "function getAll(uint256 _root) public view returns (bytes[] memory)"
];

// let okdb_contract: Contract;

/**
 * load_okdb_contract loads the okdb contract for the current chain.
 * @param core
 * @todo now that we have RPCs, just use one chain
 */
export const load_okdb_contract = async () => {
  // const chain = await coreExecution.CurrentChain.get();
  // if (chain instanceof Error) return;
  // if (okdb_chains[chain]) {
  // const ws = await core.WalletSigner();
  // okdb_contract = new Contract(okdb_chains[chain], okdb_abi);
  // }
};

/**
 * okdb_can_write calls okdb to know if given address can write the following query.
 * @param id
 * @param q
 * @returns
 * @todo load_okdb_contract must be called
 */
export const okdb_can_write = async (id: string, q: CacheQuery) => {
  // if (!okdb_contract) return false;
  // return okdb_contract.canWrite(id, toUtf8Bytes(q));
  return true;
};

// FIXME: map3
/**
 * owned_orgs
 * @param core
 * @returns
 */
export const owned_orgs = async () => {
  // if (!okdb_contract) return;
  // const chain = await coreExecution.CurrentChain.get();
  // if (chain instanceof Error) return;
  // const walletID = await coreExecution.WalletID.get();
  // if (
  //   walletID instanceof Error ||
  //   walletID === "none" ||
  //   walletID === "loading"
  // )
  //   return;
  // if (!Object.keys(okdb_chains).includes(chain)) return undefined;
  // const orgs = await okdb_contract.ownedOrgs(walletID);
  // return Promise.all(
  //   orgs.map(async (x: bigint) => {
  //     const b = await okdb_contract.OrganizationID(x); // FIXME: cache?
  //     return toUtf8String(b);
  //   })
  // ); // .map(x => OrgQuery(x))
  // });
};

export const read_data = async (q: CacheQuery) => {
  // const chain = await coreExecution.CurrentChain.get();
  // if (chain instanceof Error) return;
  // if (!Object.keys(okdb_chains).includes(chain)) return undefined;
  // const okdb = await okdb_contract.get();
  // const root = await okdb.Keys(toUtf8Bytes(q));
  // return okdb.getAll(root);
};

export const differ = new DiffPatcher({
  objectHash: function (obj, index) {
    // try to find an id property, otherwise just use the index in the array
    return obj.id || obj._id || obj.name || "$$index:" + index;
  }
});

export const apply_data = (l: Uint8Array[]) => {
  // if (l.length === 0) return "";
  // let base = toUtf8String(l[l.length - 1]);
  // for (let i = l.length - 2; i >= 0; i--) {
  //   base = differ.patch(base, [toUtf8String(l[i]), 0, 2]);
  // }
  // return base;
};
