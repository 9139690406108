import type { AbiFunction } from "abitype";
import { getFunctionSignature, type Abi } from "viem";

import {
  initialValue,
  reduce,
  type AnyCell,
  type Cellified,
  type SheetProxy
} from "@okcontract/cells";
import {
  OKWidgetCallStep,
  OKWidgetNetworkTXStep,
  OKWidgetSigStep,
  type ABIExtra,
  type OKWidgetStep,
  type OKWidgetStepType,
  type SmartContract
} from "@okcontract/coredata";
import {
  VIEW,
  objectDefinition,
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition,
  type MapTypeDefinitions,
  type TypeScheme
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";
import {
  ABIMethodFieldsRef,
  ABIMethodRef,
  ABIValuesRef
} from "@okcontract/libwidget";
import { mergeObjects } from "@scv/utils";

import { abixOrId } from "./abix";
import type { Instance } from "./instance";
import type { Step } from "./steps";
import {
  ThemeDarkPropertyDefinition,
  ThemePropertyDefinition,
  themeDefinition,
  themeDefinitions
} from "./theme";

const filterStepDefinition = (step: OKWidgetStep<OKWidgetStepType>) => {
  switch (step.sty) {
    case OKWidgetCallStep: {
      const { ntx, fch, sig, ...newStep } = step;
      return newStep;
    }
    case OKWidgetSigStep: {
      const { ntx, fch, m, q, ...newStep } = step;
      return newStep;
    }
    case OKWidgetNetworkTXStep: {
      const { m, q, sig, ...newStep } = step;
      return newStep;
    }
    default:
      return step;
  }
};

const uniqueMethods = (abi: Abi) => {
  if (!abi) return;
  // select non-constant methods
  const m = abi.filter(
    (abiFunc) =>
      abiFunc?.type === "function" &&
      (abiFunc.stateMutability === "payable" ||
        abiFunc.stateMutability === "nonpayable")
  ) as AbiFunction[];
  // count overloaded methods
  const count = m.reduce(
    (acc, mi) => {
      const name = mi.name; // short name
      return acc.set(name, (acc.get(name) || 0) + 1);
    },
    new Map() as Map<string, number>
  );

  // return short name if not ambiguous
  return m.map((abiFunc) =>
    count.get(abiFunc.name) === 1 ? abiFunc.name : getFunctionSignature(abiFunc)
  );
};

/**
 * okayedMethod checks if method m is okayed in ABIExtra.
 * @param x
 * @returns
 */
export const okayedMethod = (x: ABIExtra) => (m: string) =>
  x?.methods ? Object.keys(x.methods).includes(m) : false;

/**
 * methods_ok_for_widget computes the list of okayed methods for a widget (possibly none).
 * @param methods
 * @param x
 */
export const okayedMethods = (methods: string[], x: ABIExtra) =>
  x ? methods.filter(okayedMethod(x)) : methods;

/**
 * getMethods returns an array of allowed methods
 * @param abi
 * @param abix
 * @param m
 * @returns
 */
const getMethods = (
  abi: Abi,
  is_admin = false,
  abix?: ABIExtra,
  m?: string
) => {
  const methods = (abi && uniqueMethods(abi)) || [];
  return [
    ...((is_admin ? methods : okayedMethods(methods, abix)) || []),
    ...((m && [m]) || []) // cloned widget method if not already included
  ];
};

/**
 * getMethodDefinition returns the definition for contract call method.
 * @param core
 * @param step
 * @returns
 */
const getMethodDefinition = (instance: Instance) => (node: EditorNode) => {
  const proxy = instance._proxy;
  if (!node?.parent) return proxy.new({ base: "string", label: "Method" });
  const stepCell = proxy.get(node.parent) as Cellified<Step<OKWidgetStepType>>;
  const sty = proxy.map([stepCell], (_s) => _s?.sty || null);
  const q = proxy.map([stepCell], (_s) => _s?.q || null);

  const contract = instance._local.unwrappedCell(q) as AnyCell<SmartContract>;
  // @todo reuse prev
  const abixes = proxy.map(
    [contract],
    (_c) =>
      _c?.xr?.map((_xr) => instance._local.staticQuery(abixOrId(_xr))) || []
  );
  const merged = reduce(
    proxy,
    abixes,
    (acc, elt) => mergeObjects(acc, elt),
    {} as ABIExtra
  );
  const addr = proxy.map(
    [contract, instance.wantedChain],
    (_c, _ch) =>
      _c?.addr?.find((_addr) => _addr.chain === _ch) || _c?.addr?.[0] || null
  );
  const abi = instance._getABI(addr);
  const methods = proxy.map(
    [abi, instance._core.IsAdmin, merged],
    (_abi, _isAdmin, _abix) => getMethods(_abi.parsed, _isAdmin, _abix)
  );

  const defaultLTD = proxy.map([sty, q], (_sty, _q) => ({
    hidden: _sty !== OKWidgetCallStep || !_q,
    label: "Method",
    enum: []
  }));
  const output = proxy.map([defaultLTD, methods], (_ltd, _methods) => ({
    ..._ltd,
    enum: _methods,
    default: _methods?.[0]
  }));
  return initialValue(proxy, defaultLTD, output);
};

export const widgetGroups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "main", l: "Interaction Information" }),
  proxy.new({ id: "steps", l: "Interaction Specification" }),
  proxy.new({ id: "par", l: "Parameters" }),
  proxy.new({ id: "custom", l: "Customization" }),
  proxy.new({ id: "image", l: "Theme & Connection Screen" })
];

export const key_group_collapsed = (k) => `gr.${k}.clp`;

const OKWidgetStepDefinition =
  (instance: Instance, mode: EditorMode) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      instance._proxy,
      {
        sty: {
          label: "Step Type",
          hint: "Type of action performed by this widget step",
          enum: {
            [OKWidgetCallStep]: "Call a contract",
            [OKWidgetSigStep]: "Sign a message",
            [OKWidgetNetworkTXStep]:
              "Generate a transaction from OKcontract nodes"
          },
          recomp: true
        },
        org: {
          label: "Organization for this step",
          hint: "This will replace contract logo in the header",
          base: "string",
          search: "org",
          optional: true,
          icon: "office-o"
        },
        q: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Contract", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetCallStep,
            label: "Contract",
            base: "string",
            search: "contract",
            locked: mode === VIEW
          }));
        },
        m: getMethodDefinition(instance),
        sig: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Signature", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetSigStep,
            label: "Signature",
            hint: "Expression that must be signed by the user",
            base: "string",
            isExpr: true,
            icon: "signature"
          }));
        },
        ntx: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Signature", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Network expression",
            base: "string",
            isExpr: true
          }));
        },
        fch: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({
              label: "Available on the following chains",
              array: () =>
                instance._proxy.new({
                  base: "string",
                  label: "Chain",
                  enum: []
                }),
              unique: true
            });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Available on the following chains",
            // @todo chain_names enum
            array: () =>
              instance._proxy.new({ base: "string", label: "Chain", enum: [] }),
            // @todo unique for enum should remove existing entries from enum options
            unique: true
          }));
        },
        xm: {
          label: "",
          name: "ABIMethod"
        },
        skip: (_node: EditorNode) =>
          instance._proxy.new({
            label: "Skip the step if...",
            help: "Expression that defines whether the step should be skipped",
            base: "string",
            isExpr: true,
            optional: true,
            icon: "hand-o"
          })
      },
      "Interaction step: Transaction or Signature",
      {
        lens: (v) => {
          const conv = instance._proxy.new(undefined);
          v.subscribe((_v) => {
            conv.set(filterStepDefinition(_v));
          });
          conv.subscribe((_v) => {
            v.set(_v);
          });
          return conv;
        }
      }
    );

/**
 * TypeScheme definitions for Widget.
 * @param cons
 * @returns
 * @todo flatten without OKWidget
 */
export const widgetTypeScheme = (
  instance: Instance,
  mode: EditorMode
): MapTypeDefinitions => ({
  OKWidget: () =>
    objectDefinition(
      instance._proxy,
      {
        id: {
          label: "Unique ID",
          base: "string",
          min: 3,
          hidden: true,
          gr: "main"
        },
        ver: {
          label: "Version",
          base: "number",
          def: 1,
          hidden: true,
          gr: "main"
        },
        name: {
          label: "Interaction name",
          pl: "ex. Swap WETH to USDC on Uniswap",
          base: "string",
          min: 5,
          gr: "main"
        },
        act: {
          label: "Active",
          base: "boolean",
          locked: true,
          gr: "main",
          hidden: true
        },
        own: {
          label: "Owner",
          base: "string",
          isAddress: true,
          locked: true,
          gr: "main"
        },
        qri: {
          label: "Attestation Reference ID",
          base: "string",
          gr: "main",
          hidden: true
        },
        org: {
          // FIXME: choose verified org
          label: "Organization",
          base: "string",
          hidden: true,
          optional: true,
          gr: "main",
          icon: "office-o"
        },
        st: {
          label: "Steps", // "List of transactions"
          array: () => instance._proxy.new({ name: "OKWidgetStep" }),
          showAsTabs: (i) => `Step ${i + 1}`,
          min: 1,
          gr: "steps",
          hideLabel: true
        },
        ...themeDefinitions(instance._proxy)
      },
      "Onchain Interaction"
    ),
  OKWidgetStep: OKWidgetStepDefinition(instance, mode),
  ABIMethod: ABIMethodRef(instance._proxy),
  ABIMethodFields: ABIMethodFieldsRef(instance._proxy),
  ABIValues: ABIValuesRef(instance._proxy),
  ...themeDefinition(instance._proxy),
  // FIXME: compile to bytecode here...
  ThemeDarkProperty: ThemeDarkPropertyDefinition(instance._proxy),
  ThemeProperty: ThemePropertyDefinition(instance)
});

export const WidgetTypeDefinition = (): LabelledTypeDefinition => ({
  label: "OKWidget",
  name: "OKWidget"
});

export const widgetSchema = (
  instance: Instance,
  mode: EditorMode
): TypeScheme => ({
  types: instance._proxy.new(widgetTypeScheme(instance, mode)),
  values: instance._proxy.new(WidgetTypeDefinition()),
  gs: instance._proxy.new(widgetGroups(instance._proxy))
});
