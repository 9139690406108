import { parseAbi } from "viem";

const functionSignaturePattern =
  /^\s*function\s+\w+\s*\([^)]*\)\s*(external|public|internal|private)?\s*(view|pure|payable)?\s*(returns\s*\([^)]*\))?\s*;?\s*$/;

const isHumanABI = (input: string): boolean => {
  const lines = input.split("\n");
  for (const line of lines) {
    if (line.trim() === "") continue; // Ignore empty lines
    if (!functionSignaturePattern.test(line)) return false; // Check if the line matches the function signature pattern
  }
  return true;
};

/**
 * convertABI to any format
 * @todo add tests
 **/
export const convertABI = (s: string) => {
  if (!s) return;
  if (isHumanABI(s))
    return JSON.stringify(
      parseAbi(
        s
          .split(";")
          .map((s) => s.trim())
          .filter((s) => s !== "")
      )
    );
  return s;
};
