import type { SyntaxNodeRef } from "@lezer/common";
import { styleTags, tags } from "@lezer/highlight";

import {
  autocompletion,
  type CompletionContext
} from "@codemirror/autocomplete";
import {
  HighlightStyle,
  LRLanguage,
  LanguageSupport,
  syntaxHighlighting,
  syntaxTree
} from "@codemirror/language";
import { linter, type Diagnostic } from "@codemirror/lint";
import type { Extension } from "@codemirror/state";
import type { EditorView } from "@codemirror/view";

import {
  NameLambda,
  parser,
  prettyPrintType,
  type Environment
} from "@okcontract/lambdascript";

// import Completion from "./Completion.svelte";

/**
 * Syntax highlighting style
 */
const style = {
  Identifier: tags.variableName,
  tripleStringContent: tags.quote,
  StringValue: tags.string,
  commandStringContent: tags.content,
  immediateParen: tags.paren,
  immediateBracket: tags.bracket,
  immediateDoubleQuote: tags.quote,
  immediateBackquote: tags.quote,
  immediateDot: tags.derefOperator,
  // SourceFile: t.string, // never used ?
  UnaryOperatorExtra: tags.operator,
  "PowerOperator TimesOperator PlusOperator": tags.arithmeticOperator,
  ComparisonOperator: tags.compareOperator,
  "OrOperator AndOperator": tags.logicOperator,
  BooleanValue: tags.bool,
  Character: tags.character,
  NumberValue: tags.number,
  Symbol: tags.atom,
  TripleString: tags.docString,
  CommandString: tags.string
  // PrefixedString : t.?,
};

const parserWithMetadata = parser.configure({
  props: [styleTags(style)]
});

const renderInfo = (completion, element) => {
  const { info: Component, props } = completion;
  // Clear existing content
  element.innerHTML = "";
  if (Component) {
    new Component({
      target: element,
      props
    });
  }
};

export const info = (env: Environment, key: string) => {
  const elt = env.library(key);
  return {
    label: key,
    type: elt?.t?.type?.kind === NameLambda ? "function" : "variable",
    detail: elt?.t?.type ? prettyPrintType(elt.t.type) : undefined,
    info: elt?.doc
    // info: Completion,
    // props: { env, key }
  };
};

/**
 * complete with matching values from `env` (start with pfx).
 * @param pfx prefix to be completed
 * @returns
 */
const complete = (pfx: string, env: Environment) =>
  env
    .keys()
    .filter((key, _) => key.startsWith(pfx))
    .map((key) => info(env, key));

const completionSource = (env: Environment) => (context: CompletionContext) => {
  const word = context.matchBefore(/[$]?\w*/);
  if (!word || (word.from === word.to && !context.explicit)) return null;
  return {
    from: word.from,
    options: complete(word.text.toLowerCase(), env)
  };
};

/**
 * Code linter
 * @todo : add typechecker support
 * @param view
 * @returns
 */
const lint = (view: EditorView): readonly Diagnostic[] => {
  const tree = syntaxTree(view.state);
  const diags: Diagnostic[] = [];
  // alerting on parsing errors
  tree.cursor().iterate((node: SyntaxNodeRef) => {
    if (node.name === "⚠") {
      diags.push({
        from: node.from,
        to: node.to,
        severity: "error",
        message: "Parse error"
      });
    }
    return true;
  });
  //@todo add typing errors.
  return diags;
};

const highlightStyle = HighlightStyle.define([
  { tag: tags.variableName, color: "#7c7c7c" },
  { tag: tags.quote, color: "#d4d4d4" },
  { tag: tags.string, color: "#ce9178" },
  { tag: tags.content, color: "#d4d4d4" },
  { tag: tags.paren, color: "#9cdcfe" },
  { tag: tags.bracket, color: "#9cdcfe" },
  { tag: tags.derefOperator, color: "#d4d4d4" },
  { tag: tags.operator, color: "#d4d4d4" },
  { tag: tags.arithmeticOperator, color: "#b5cea8" },
  { tag: tags.compareOperator, color: "#d4d4d4" },
  { tag: tags.logicOperator, color: "#d4d4d4" },
  { tag: tags.bool, color: "#569cd6" },
  { tag: tags.character, color: "#d4d4d4" },
  { tag: tags.number, color: "#b5cea8" },
  { tag: tags.atom, color: "#d4d4d4" },
  { tag: tags.docString, color: "#d4d4d4" },
  { tag: tags.string, color: "#ce9178" }
]);

export const lambdascript = (
  config?: Extension,
  env?: Environment
): LanguageSupport => {
  const lang = LRLanguage.define({
    parser: parserWithMetadata
  });
  const extensions = config ? [config] : [];
  extensions.push(
    autocompletion({
      override: [completionSource(env)]
    }),
    linter(lint),
    syntaxHighlighting(highlightStyle)
  );
  return new LanguageSupport(lang, extensions);
};
