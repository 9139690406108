<script lang="ts">
  import { ValueCell, type AnyCell, type Key } from "@okcontract/cells";
  import { DataEditor, type EditorNode } from "@okcontract/fred";
  import { reservedKeywords } from "@okcontract/lambdascript";
  import { EnvKeyMissingKeys, type Instance } from "@okcontract/sdk";
  import { Button, Hoverable, MasterDetail } from "@okcontract/uic";
  import { SearchSelector, SearchSelectorClass } from "@scv/selector";

  import type { EditorOptions } from "../types";
  import EditorNodeCell from "./EditorNodeCell.svelte";

  export let instance: Instance;
  const proxy = instance._proxy;

  export let editor: DataEditor;
  export let node: EditorNode;
  const value = proxy.get(node.value) as ValueCell<
    Record<Key, AnyCell<unknown>>
  >;
  const definition = node.definition;

  export let options: EditorOptions = {};
  const env = editor.env;

  const selected = proxy.new("", "ObjectDictEdit.selectedInput");
  const searchSelected = proxy.new("", "ObjectDictEdit.searchSelected");

  const dict = "dict" in node && node.dict;
  const isEditable = !options?.view && value instanceof ValueCell;

  const dictValue = value.map(
    (_value) => Object.entries(_value).sort(),
    "ObjectDictEdit.dictValue"
  );
  dictValue.subscribe(
    (_value) => _value?.[0]?.[0] && selected.set(_value[0][0])
  );

  /**
   * searchFn computes the list of potential completions from the search query.
   * @param q
   * @todo do not return default lambda keys and operators...
   * @todo should be a Cell?
   */
  const searchFn = async (q: string): Promise<string[]> => {
    const def = "dict" in $definition ? $definition.dict() : $definition;
    if (def instanceof Error) throw def;
    const keys =
      "keys" in def && def.keys
        ? Array.from(def.keys).filter((s) =>
            s.toLowerCase().includes(q.toLowerCase())
          )
        : [];

    const _value = await value.get();
    const existing = Object.keys(_value);

    // @todo move to schema definition
    const envKeys =
      env && env._values[EnvKeyMissingKeys]
        ? await env._values[EnvKeyMissingKeys].consolidatedValue
        : [];
    // console.log({ envKeys });
    const comp =
      envKeys instanceof Error
        ? []
        : (envKeys as string[])?.filter(
            (v) =>
              // key defined (not case sensitive)
              v?.toLowerCase()?.includes(q?.trim().toLowerCase()) &&
              // not already in Dict
              !existing.includes(v)
          ) || [];

    return Array.from(new Set([...keys, ...comp]));
  };

  const onCreate = async (ev: CustomEvent) => {
    // console.log("CREATE");
    const { input: key, component } = ev.detail as {
      input: string;
      component: SearchSelectorClass;
    };
    component.close();
    component.input.set("");
    await editor.addNewProperty(node, key);
    await instance._proxy.working.wait();
    selected.set(key);
  };

  const onEmpty = (ev: CustomEvent) => {
    // console.log("EMPTY");
    const searchSelector = ev.detail as SearchSelectorClass;
    searchSelector.close();
  };

  const onSelect = (ev: CustomEvent) => {
    // console.log("SELECT");
    const key = ev.detail.selected;
    const searchSelector = ev.detail.component as SearchSelectorClass;
    searchSelector.close();
    searchSelector.input.set("");
    selected.set(key);
  };
</script>

{#if $definition instanceof Error}
  Error $definition: {$definition}
{:else if $dictValue instanceof Error}
  Error $dictValue: {$dictValue}
{:else if $dict instanceof Error}
  Error $dict: {$dict}
{:else if $dict}
  {#if isEditable}
    <div class="item-center flex space-x-3">
      <SearchSelector
        {instance}
        selected={searchSelected}
        {searchFn}
        placeholder="ex. Type $ or a letter to autocomplete"
        createLabel="Create value"
        canCreateFn={async (key) => {
          if (!key) return false;
          const dictDefinition =
            "dict" in $definition ? await $definition?.dict(key) : null;
          const keys =
            "keys" in dictDefinition && dictDefinition.keys
              ? Array.from(dictDefinition.keys)
              : [];
          return (
            !reservedKeywords.includes(key.toLowerCase()) && !keys.includes(key)
          );
        }}
        on:create={onCreate}
        on:select={onSelect}
        on:empty={onEmpty}
        let:value
      >
        {value}
      </SearchSelector>
    </div>
  {/if}

  {#if options?.view && "compact" in $definition && $definition.compact}
    {#each Object.entries($dict).sort() as [k, child]}
      <dl class="flex gap-2 py-2 px-1 border-t border-base-300">
        <dt class="flex-none w-40 break-all font-medium">{k}</dt>
        <dd>
          <EditorNodeCell
            {instance}
            {editor}
            node={child}
            options={{ ...options, hideLabels: true }}
          />
        </dd>
      </dl>
    {/each}
  {:else}
    <div class="mt-1 border border-base-300 bg-base-100 rounded-btn">
      <div class="md:grid md:grid-cols-3">
        <MasterDetail
          data={Object.entries($dict).sort()}
          title={$definition.label}
          selectedKey={selected}
          size="sm"
        >
          <span slot="master" let:key class="">
            <Hoverable let:hovering={hover}>
              <div class="flex flex-row items-center justify-between">
                <span class="py-2">{key}</span>
                {#if hover && isEditable}
                  <Button
                    style="ghost"
                    size="xs"
                    square={true}
                    iconAppend={true}
                    icon="close"
                    action={() => editor.removeProperty(node, key)}
                  />
                {/if}
              </div>
            </Hoverable>
          </span>
          <span
            slot="detail"
            let:value={child}
            class="bg-base-200 p-2 md:col-span-2"
          >
            {#key child}
              <EditorNodeCell {instance} {editor} node={child} {options} />
            {/key}
          </span>
        </MasterDetail>
      </div>
    </div>
  {/if}
{/if}
