<script lang="ts">
  import { type AnyCell } from "@okcontract/cells";
  import { isToken, is_org } from "@okcontract/coredata";
  import type { Instance } from "@okcontract/sdk";
  import { ThemeText, getTheme } from "@okcontract/uic";
  import { ObjectFieldViewString } from "@scv/dataeditor";
  import { OrgSnippet, TokenSnippet } from "@scv/dcuic";

  import Titles from "./Titles.svelte";

  export let instance: Instance;
  export let item: AnyCell<any>;
  export let size: "smb" | "sm" | "md" = "sm";
  export let chain: AnyCell<string>;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
</script>

{#if $item instanceof Error}
  <span title={$item.message}>...</span>
{:else if Array.isArray($item)}
  {#each $item as element, i}
    <Titles {instance} item={element} size={i % 2 ? "smb" : size} {chain} />
  {/each}
{:else if isToken($item)}
  <!-- should only hover details, not open link  // bg-gray-200 dark:bg-gray-800 rounded-full -->
  <div
    class="btn btn-sm rounded-box {theme.dark(
      $compiledTheme,
      'btn-white-alpha',
      'btn-black-alpha',
      'btn-ghost'
    )}"
    style={theme.apply($compiledTheme, [ThemeText])}
  >
    <TokenSnippet {instance} q={$item} small={false} balance={false} />
  </div>
{:else if is_org($item)}
  <div
    class="btn btn-sm rounded-box {theme.dark(
      $compiledTheme,
      'btn-white-alpha',
      'btn-black-alpha',
      'btn-ghost'
    )}"
    style={theme.apply($compiledTheme, [ThemeText])}
  >
    <OrgSnippet {instance} q={$item} small={false} />
  </div>
{:else if $item !== undefined}
  <ObjectFieldViewString
    {instance}
    value={$item}
    {size}
    chain={$chain instanceof Error ? null : $chain}
    snippetType={false}
  />
{/if}
