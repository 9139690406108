import type { AnyCell, SheetProxy } from "@okcontract/cells";
import type { Address } from "@okcontract/multichain";

import { cookie } from "./cookie";
import { parseJwt, type JWTUser } from "./jwt";

export const ADMIN = "admin" as const;
export const GUEST = "guest" as const;
export const PUBLIC = "public" as const;
export const WHITELISTED = "whitelisted" as const;
export const NO_ROLE = "none" as const;

export type Role =
  | typeof ADMIN
  | typeof GUEST
  | typeof PUBLIC
  | typeof WHITELISTED
  | typeof NO_ROLE;

// react on token changes to check if for given addr if jwt is in local storage
export const getRole = (
  proxy: SheetProxy,
  token: AnyCell<string>,
  addr: AnyCell<Address>
) =>
  proxy.map(
    [addr, token],
    (_addr, _) => {
      const cki = cookie.get(_addr?.toString());
      const jwt = parseJwt(cki);
      return jwtRole(jwt);
    },
    "role"
  );

const jwtRole = (jwt: JWTUser | "loading") =>
  jwt === "loading" || jwt === null
    ? null // undefined
    : jwt?.admin
      ? ADMIN
      : jwt?.role === "editor"
        ? WHITELISTED
        : GUEST;

export const getJWTRole = (jwt: AnyCell<JWTUser | "loading">) =>
  jwt.map(jwtRole);

export const is_allowed = (role: Role, whitelists: Role[]) =>
  role === NO_ROLE ? false : whitelists?.includes(role);
