const dateRegexp = /^\d{4}[./-]\d{2}[./-]\d{2}T\d{2}:\d{2}:\d{2}.*$/;

/**
 * numberBytes transform a number to bytes (big-endian).
 * @param x number
 * @returns bytes from number
 */
export const numberBytes = (x: number) => {
  const y = Math.floor(x / 2 ** 32);
  return [y, y << 8, y << 16, y << 24, x, x << 8, x << 16, x << 24].map(
    (z) => z >>> 24
  );
};

/**
 * date8 builds an 8-byte timestamp.
 * @param now
 * @returns the current date as 8 bytes.
 */
export const date8 = (now: Date) => {
  const unix = now.getTime() / 1000;
  return new Uint8Array(numberBytes(unix));
};

/**
 * isDate tests if a given string is a date.
 * @param str date to test
 */
export const isDate = (str: string) => {
  return dateRegexp.test(str);
};
