<script lang="ts">
  import { setContext } from "svelte";

  import { cellify, type Cellified } from "@okcontract/cells";
  import { WidgetQuery, type OKWidgetStepType } from "@okcontract/coredata";
  import { merge_xp, toLambdaExpr } from "@okcontract/libwidget";
  import {
    CoreExecutionContextKey,
    Stepper,
    type AnyCore,
    type Step
  } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { Loading, Theme, ThemeBackground, ThemeText } from "@okcontract/uic";
  import { AlertPanel, CircleIcon } from "@scv/dcuic";

  import AlertError from "../../src/components/AlertError.svelte";
  import ThemeWrapper from "../../src/components/ThemeWrapper.svelte";
  import WidgetFooter from "../../src/components/WidgetFooter.svelte";
  import WidgetTX from "../../src/components/WidgetTX.svelte";

  export let core: AnyCore;
  setContext(CoreExecutionContextKey, core);

  export let instance = newInstance("widget");
  export let wid: string;
  export let th: string = undefined;
  export let xp: string = undefined;

  const proxy = instance._proxy;

  const walletID = core.WalletID;
  const currentChain = core.CurrentChain;
  const isAdmin = core.IsAdmin;
  const user = core.User;

  const widCell = proxy.new(WidgetQuery(wid), "wview.widCell");
  $: widCell.set(WidgetQuery(wid));

  const widget = instance._dataCache(widCell, {
    name: "widget",
    returnErrors: true
  });

  const steps = widget.map((_widgetCell) =>
    cellify(
      proxy,
      _widgetCell.st.map((_step, i) => ({
        ..._step,
        xm: xp && i === 0 ? merge_xp(_step.xm, toLambdaExpr(xp)) : _step.xm
      }))
    )
  ) as unknown as Cellified<Step<OKWidgetStepType>[]>;

  const stepper = new Stepper(instance, steps);
  const current = stepper.current;

  // Theme
  const thq = proxy.new(th ? JSON.parse(atob(decodeURIComponent(th))) : null);
  const theme = new Theme(thq);
  const compiledTheme = theme.compiled;

  widget.subscribe((_widget) => {
    if (!_widget || _widget instanceof Error) return;
    // loading.set(false);
    thq.set(_widget.th);
  });
</script>

{#if theme}
  <ThemeWrapper {theme}>
    <div
      class="absolute inset-0 z-50 w-full h-full overflow-hidden rounded-md {theme.dark(
        $compiledTheme,
        'bg-black text-white',
        'bg-white text-black',
        'bg-base-100 text-base-content'
      )}"
      style={theme.apply($compiledTheme, [ThemeBackground])}
    >
      {#if $widget === undefined}
        <div class="flex h-full flex-col justify-center pb-16">
          <Loading />
        </div>
      {:else if $widget instanceof Error || $widget === null}
        <div
          class="p-4 {theme.dark(
            $compiledTheme,
            'text-white',
            'text-black',
            'text-base-content'
          )}"
          style={theme.apply($compiledTheme, [ThemeText])}
        >
          <div class="mb-4 mt-12 flex w-full justify-center">
            <CircleIcon name="error-o" style="danger" />
          </div>
          <h4 class="mb-2 flex w-full justify-center">Widget not found</h4>
          <div class="mb-4 w-full text-center opacity-70">
            It looks like this widget doesn't exist
          </div>
          <div class="flex flex-col justify-center">
            <p class="flex items-center justify-center space-x-2 opacity-70">
              <span>ID:</span>
              <span>{wid}</span>
            </p>
          </div>
        </div>
      {:else if !$widget?.act && !$isAdmin && $walletID !== $widget?.own}
        <div
          class="p-4 {theme.dark(
            $compiledTheme,
            'text-white',
            'text-black',
            'text-base-content'
          )}"
          style={theme.apply($compiledTheme, [ThemeText])}
        >
          <div class="mb-4 mt-12 flex w-full justify-center">
            <CircleIcon name="info-o" style="dark" />
          </div>
          <h4 class="mb-2 w-full text-center">Inactive Interaction</h4>
          <div class="mb-4 w-full text-center opacity-70">
            This interaction is not active yet.
            <!--             Its creator <WalletAddress
              {instance}
              address={{ addr: $widget?.own, ty: "w", chain: ALL_CHAINS }}
            /> must activate it before public use. -->
          </div>
        </div>
      {:else if stepper}
        {#if !$current}
          <div class="flex h-full flex-col justify-center pb-16">
            <Loading />
          </div>
        {:else}
          <WidgetTX {instance} {stepper} />
        {/if}
      {:else}
        <div
          class="p-4 {theme.dark(
            $compiledTheme,
            'text-white',
            'text-black',
            'text-base-content'
          )}"
          style={theme.apply($compiledTheme, [ThemeText])}
        >
          <div class="mb-4 mt-12 flex w-full justify-center">
            <CircleIcon name="error-o" style="dark" />
          </div>
          <h4 class="mb-2 w-full text-center">Something went wrong...</h4>
          <div class="mb-4 w-full text-center opacity-70">
            Error: Could not load user: {$user}
            Chain: {$currentChain}
          </div>
        </div>
      {/if}
      <AlertPanel>
        <AlertError />
      </AlertPanel>
      {#if stepper}
        <WidgetFooter {instance} {stepper} wid={widCell} />
      {/if}
    </div>
  </ThemeWrapper>
{/if}
