import { createWalletClient, http } from "viem";
import type { PrivateKeyAccount } from "viem/accounts";
import { mainnet } from "viem/chains";

import { hash_passwd } from "@scv/libcrypto";
import { hash_to_base64 } from "@scv/utils";

import type { UserAuth, UserCredentials, UserWallet } from "./types";

export const publicToken = "public";

const addressSalt = "addr";

export class APIUser {
  private _endpoint: string;
  private _app: string;

  constructor(endpoint: string, app: string) {
    this._endpoint = endpoint;
    this._app = app;
  }

  /**
   * KeyPair retrieves the keypair associated with a user address.
   */
  AuthMessage = // @todo: implement cache?
    async (w: UserCredentials): Promise<UserWallet> => {
      // console.table({ addressSalt, w })
      const hash = hash_passwd(addressSalt, w.username);
      const url = `${this._endpoint}/preflight`;
      const r = await fetch(url, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify({ ID: hash })
      });
      return r.json();
    };

  /**
   * Wallet retrieves a user authentication token using a wallet signature.
   */
  Wallet = async (w: UserWallet): Promise<UserAuth> => {
    const url = `${this._endpoint}/wallet`;
    const r = await fetch(url, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: "POST",
      body: JSON.stringify(w)
    });
    if (r.ok) {
      return r.json();
    }
    throw new Error(`request failed: ${await r.text()}`);
  };

  /**
   * VerifyDiscord verify a discord user id
   */
  VerifyDiscord = async (
    token: string,
    da: { ID: string; Key: string; ESM: UserWallet } // id is the datacache id of the user / key is discordID
  ): Promise<UserAuth> => {
    const url = `${this._endpoint}/auth/discord/verify`;
    const r = await fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer: ${token}`
      },
      method: "POST",
      body: JSON.stringify(da)
    });
    if (r.ok) {
      return r.json();
    }
    throw new Error(`request failed: ${await r.text()}`);
  };

  walletAuthentication = async (
    account: PrivateKeyAccount
  ): Promise<string | null> => {
    // Create wallet client.
    const client = createWalletClient({
      account,
      chain: mainnet,
      transport: http()
    });
    const msg = <UserWallet>(
      await this.AuthMessage({ username: account.address })
    );
    // Sign message with wallet.
    msg.Sig = await client.signMessage({
      account,
      message: msg.Msg
    });
    // Retrieve auth token.
    msg.code = await hash_to_base64(`.${this._app}`);
    const auth = await this.Wallet(msg);
    // Return the token.
    return auth.token?.length ? auth.token : null;
  };
}
