export const truncated_addr = (str: string, nb: number = 10) =>
  str && str.length > 35
    ? `${str.substring(0, nb)}...${str.substring(str.length - 5, str.length)}`
    : str;

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * toTitleCase converts a camel case string to a title case
 * (ex: helloWorld => Hello World)
 * @param str
 * @returns
 */
export const toTitleCase = (str: string) =>
  capitalize(
    str
      .replace(/_/g, " ") // remove '_'
      .replace(/([a-z])([A-Z])/g, "$1 $2") // insert spaces on transitions
      .trim()
  );

const acronyms = {
  url: "URL",
};

export const replace_acronyms = (str: string) =>
  acronyms?.[str.toLowerCase()] || str;

export const remove_quotes = (str: string) =>
  typeof str === "string" && str?.startsWith('"') && str?.endsWith('"')
    ? str.slice(1, -1)
    : str;
