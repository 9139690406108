<script lang="ts">
  import { Icon, Tooltip, type TooltipPosition } from "@okcontract/uic";

  export let content: string;
  export let pos: TooltipPosition = "top";
  let notification: string;

  const copy = async () => {
    await navigator.clipboard.writeText(content);
    notification = "Copied";
    setTimeout(() => {
      notification = undefined;
    }, 1000);
  };
</script>

<Tooltip label={notification || "Copy"} {pos}>
  <button type="button" on:click|stopPropagation={copy} class="flex">
    <Icon name="copy-o" size="xs" />
  </button>
</Tooltip>
