import type { ChainType } from "@okcontract/multichain";

import {
  SearchQuery,
  TokenByChainPrefixQuery,
  type CacheQuery,
  type DataCacheType
} from "./coredata";
import { IndexPrefixQuery, prefix_from_datatype } from "./prefix";
import {
  ABIValueTypes,
  ALL_CHAINS,
  TokenCurrentChain,
  type ABIValue
} from "./types";

export type SearchType =
  | DataCacheType // one type
  | typeof ALL_CHAINS // all types (full text search)
  | typeof TokenCurrentChain; // prefix for token on current chains

/**
 * coredata_search_query returns the coredata query.
 * @param ty
 * @param input
 * @returns
 */
export const coredata_search_query = (
  ty: SearchType,
  input: string,
  ch: ChainType
) => {
  // for orgs and abix, we want to search using prefix with partial words
  switch (ty) {
    case "org":
    case "abix":
      return IndexPrefixQuery(ty, input);
    case TokenCurrentChain:
      // @todo support nft, stake, etc.
      return TokenByChainPrefixQuery(ch, `tok:${input}`);
    case "nft":
    case "stake":
    case "lend":
    case "swap":
      return SearchQuery(input, "token");
  }
  // otherwise we do a full-text search (possibly restricted by type)
  return SearchQuery(input, ty !== ALL_CHAINS ? ty : undefined);
};

export const coredata_search_type = (q: string): SearchType => {
  if (!q || typeof q !== "string") {
    return;
  }
  switch (q.substring(0, q.indexOf(":"))) {
    case "org":
      return "org";
    case "con":
      return "contract";
    case "stake":
      return "stake";
    case "tok":
      return "token";
    case "nft":
      return "nft";
    case "wid":
      return "widget";
    case "addr":
      return "address";
    case "abix":
      return "abix";
  }
};

/**
 * add_prefix_from_search_type add a search prefix to a string
 * @param type
 * @param value
 * @returns
 */
export const add_prefix_from_search_type = <T extends SearchType>(
  type: T,
  str: string
): CacheQuery => {
  const pre = prefix_from_datatype(type);
  return (pre && !str.includes(":") ? `${pre}:${str}` : str) as CacheQuery;
};

export const get_search_prefix = (a: ABIValue): SearchType =>
  // @ts-expect-error redesign type to exclude string
  ABIValueTypes.includes(a?.ty) ? a.ty : undefined;
