<script lang="ts">
  import type { ValueCell } from "@okcontract/cells";
  import {
    ALL_CHAINS,
    type Instance,
    type WalletDetail
  } from "@okcontract/sdk";
  import { WalletAddress } from "@okcontract/svelte-sdk";
  import { Button, Icon, InlineHelpText } from "@okcontract/uic";
  import { ADMIN, GUEST, WHITELISTED } from "@scv/auth";
  import { truncated_addr } from "@scv/utils";

  export let instance: Instance;
  const core = instance._core;
  const proxy = instance._proxy;

  export let account: WalletDetail;
  export let open: ValueCell<boolean>;
  export let small = false;
  export let closeOnSelect = false;
  export let roles: boolean;

  const wantedWalletAccount = core.WantedWalletAccount;
  const walletID = proxy.new(wantedWalletAccount.get());

  const addr = account.addr;
  const isVerified = account.isVerified;
  const role = account.role;
  const name = account.name;

  const switchAddr = async () => {
    // if ($addr instanceof Error) return;
    if (closeOnSelect) open.set(false);
    walletID.set($addr);
    await core.WalletChange([$addr]);
  };
</script>

<li>
  <button
    class="text-sm justify-start {$addr === $wantedWalletAccount
      ? '{roles ? "bg-base-200" : "font-semibold" }'
      : ''}"
    on:click={switchAddr}
  >
    {#if roles}
      {#if $isVerified}
        <Icon name="shield-o" size="xs" fill="success" />
        {#if $role === ADMIN}
          <Icon name="user" size="xs" />
        {:else if $role === WHITELISTED}
          <Icon name="user-o" size="xs" />
        {:else if $role === GUEST}
          <Icon name="wallet" size="xs" />
        {:else}
          <!-- should never happen -->
          <Icon name="help-o" size="xs" />
        {/if}
      {:else}
        <Icon name="shield-o" size="xs" fill="warning" />
        <Icon name="wallet" size="xs" />
        <Button label="Verify" size="xs" outline={true} action={switchAddr} />
      {/if}
    {/if}
    {#if $addr instanceof Error}
      <InlineHelpText style="warning" icon="warning"
        >Wallet address error</InlineHelpText
      >
    {:else if $name}
      {$name}
    {:else if small}
      {truncated_addr($addr.toString(), 10)}
    {:else}
      <WalletAddress
        {instance}
        address={{ addr: $addr, chain: ALL_CHAINS, ty: "w" }}
        style={false}
        my={false}
        truncate={true}
      />
    {/if}
  </button>
</li>
