<script lang="ts">
  import type { AnyCell } from "@okcontract/cells";
  import type { WidgetQueryType } from "@okcontract/coredata";
  import { type Stepper } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { Icon, getTheme } from "@okcontract/uic";
  import { FRONT_URL, config_store } from "@scv/app";

  import TxError from "./TXError.svelte";

  export let instance = newInstance("WidgetFooter");
  export let stepper: Stepper;
  export let wid: AnyCell<WidgetQueryType> = undefined;
  export let hide = false;
  export let theme = getTheme();

  const compiledTheme = theme?.compiled;

  const okTX = stepper.current;
  // @todo TxError should take $okTX as argument
</script>

<!-- @todo transaction value -->
<!-- {#if $tx instanceof BaseError && $isPreOk} -->
{#if $okTX && !($okTX instanceof Error)}
  <TxError {instance} okTX={$okTX} />
{/if}
<!-- 
{chain}
    err={$tx}
    value={$sendValue instanceof Error ? BigInt(0) : $sendValue}
   -->
<!-- {/if} -->
{#if !hide}
  <div
    class="absolute bottom-0 left-0 right-0 h-12 rounded-b-box z-20
    {theme.dark(
      $compiledTheme,
      'bg-white-alpha text-white',
      'bg-black-alpha text-black',
      'bg-black-alpha text-black'
    )}"
  >
    <div
      class="flex gap-1 justify-start items-center px-3 py-2 text-xs opacity-50"
    >
      <div class="badge badge-outline">Beta</div>
      <div class="flex flex-col">
        <span>Verify transactions in your wallet</span>
        {#if wid}
          <button
            disabled={!$wid}
            class="flex gap-1 items-center"
            on:click={() =>
              window.open(
                `${$config_store.Env[FRONT_URL]}/interaction/${$wid}/view`,
                "_blank"
              )}
          >
            <span>Powered by OKcontract</span><Icon name="shield-o" size="xs" />
          </button>
        {/if}
        <slot />
      </div>
    </div>
  </div>
{/if}
