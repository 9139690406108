import type { Address, Network } from "@okcontract/multichain";

import type { TokenQueryType } from "./coredata";

export const BasicTokenKind = "token";
export const StakeTokenKind = "stake";
export const SwapPoolTokenKind = "swap";
export const LendPoolTokenKind = "lend";
export const NFTTokenKind = "nft";

/**
 * is_token returns true if the Query is a token.
 *
 * @description This function does not resolve tokens from their address.
 * @param q query
 */
export const isToken = (q: unknown): q is TokenQueryType =>
  typeof q === "string" &&
  (q.startsWith("tok:") ||
    q.startsWith("nft:") ||
    q.startsWith("stake:") ||
    q.startsWith("swap:") ||
    q.startsWith("lend:"));
