<script lang="ts">
  import type { OrgQueryType } from "@okcontract/coredata";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { getTheme, Icon, Tooltip } from "@okcontract/uic";
  import { Logo, Upvote } from "@scv/dcuic";

  export let instance = newInstance("OrgSnippet");
  export let q: OrgQueryType;
  export let verified: boolean = false;
  export let small: boolean = false;
  export let upvote: boolean = false;
  export let theme = getTheme();
  const compiledTheme = theme?.compiled;

  const local = instance._local;
  const proxy = instance._proxy;

  const qCell = proxy.new<OrgQueryType>(q, "OrgSnippet.qCell");
  $: qCell.set(q);

  const org = local.unwrappedCell(qCell, "org");
</script>

<div class="flex flex-row grow items-center justify-between overflow-hidden">
  {#if !($org instanceof Error)}
    <div class="flex flex-row grow items-center overflow-hidden">
      <div class="flex-none">
        <Logo
          logo={$org?.logo || $org?.id}
          size={small ? "sm" : "md"}
          remote={instance._core.AppID === "widget"}
        />
        <!-- @todo no deps -->
      </div>
      <div
        class="min-w-0 grow truncate {small
          ? 'ml-1 text-sm'
          : 'ml-2 text-base font-semibold'}
        {theme.dark($compiledTheme, 'text-white', 'text-black', '')}"
      >
        {$org?.name || q.replace("org:", "")}
        <slot name="tag" />
      </div>
    </div>
    <div class="flex shrink-0 gap-2 items-center">
      {#if verified}
        {#if $org?.cons?.length > 0}
          <Tooltip label={`${$org.cons.length} verified contracts`}>
            <div class="flex space-x-1 items-center text-xs text-success">
              <span>{$org.cons.length}</span>
              <Icon name="badge-check-o" size="sm" />
            </div>
          </Tooltip>
        {/if}
      {/if}
      {#if upvote}
        <Upvote {q} style="sm" />
      {/if}
    </div>
  {/if}
</div>
