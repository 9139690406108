import type { BaseError } from "viem";

import type { ABIExtra } from "@okcontract/coredata";

/**
 * StructuredError defines structured errors.
 */
export type StructuredError = {
  /** should the error be displayed */
  dis: boolean;
  /** human-friendly message */
  msg: string;
};

export const execution_reverted = "execution reverted: ";
const reserved_admin =
  "This function is reserved for the smart contract admin.";

export const INVALID_ARG = "INVALID_ARGUMENT";
export const NETWORK_ERROR = "NETWORK_ERROR";

/**
 * predefined_errors for all contracts.
 */
const predefined_errors: [string, boolean, string][] = [
  ["caller is not the owner", true, reserved_admin],
  ["contract owner", true, reserved_admin],
  ["Governable: forbidden", true, reserved_admin]
  // ["execution reverted", false, "wrong parameters"],
];

/**
 * human_extra looks up for a known error in ABIExtra.
 * @param extra
 * @param err
 * @returns {dis:boolean, msg:string}
 * @todo use ABIError
 * @todo handle Viem Error
 */
export const human_error = (
  extra: ABIExtra,
  err: BaseError
): StructuredError => {
  if (err && extra && extra.err)
    for (let i = 0; i < extra.err.length; i++)
      if (err?.message?.includes(extra.err[i][0]))
        return { dis: extra.err[i][1], msg: extra.err[i][2] };
  if (err)
    for (let i = 0; i < predefined_errors.length; i++) {
      if (err?.details?.includes(execution_reverted))
        return { dis: true, msg: err.details.replace(execution_reverted, "") };
      if (err?.message?.includes(predefined_errors[i][0]))
        return { dis: predefined_errors[i][1], msg: predefined_errors[i][2] };
      // if (err?.code?.includes(INVALID_ARG))
      //   return {
      //     dis: true,
      //     msg: `Invalid argument ${err.argument} : ${
      //       err?.reason.split("(")?.[0]
      //     }`,
      //   };
      // if (err?.code === NETWORK_ERROR)
      //   return {
      //     dis: true,
      //     msg: `Network error : ${err?.reason.split("(")?.[0]}`,
      //   };
    }
};

export const err_no_whitelist = new Error(
  "Wallet is not authorized: If you think you should be whitelisted, please contact us."
);

export const err_wallet_connect = new Error("Please connect your wallet");

export const err_wallet_signin = new Error("Please sign in your wallet");

export const err_no_wallet = new Error("Please install a wallet");

export const err_wallet_code = (err: Error & { code: string }) =>
  new Error(`Wallet error: ${err?.code || "unknown"}`);

export const err_lighthouse = new Error("Error while uploading to lighthouse");

export const err_wrong_message = new Error("wrong message");

const NO_RESULT = "AbiDecodingZeroDataError";

export const formatError = (
  err: Error
): { type: "info" | "error"; message: string } => {
  if ("reason" in err) {
    const reason = err.reason as BaseError;
    if (reason.name === NO_RESULT)
      return { type: "info", message: "No result" };
    const message = (reason?.shortMessage || reason.message).replace(
      "execution reverted: ",
      ""
    );
    return { type: "error", message };
  }
  return { type: "error", message: err.message };
};
