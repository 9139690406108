import {
  SheetProxy,
  type AnyCell,
  type MapCell,
  type Sheet,
  type ValueCell,
  type WrappedCell
} from "@okcontract/cells";
import type { CacheQuery } from "@okcontract/coredata";

import type { GlobalSubscriber } from "./global";
import type { DataOf, OKData } from "./queryCache";

export class LocalSubscriber<Query extends CacheQuery> {
  // @todo type from outside?
  readonly _gs: GlobalSubscriber<Query>;
  readonly _qs: Set<Query>;
  readonly _proxy: SheetProxy;
  readonly _ownProxy: boolean;
  private _nullCell: ValueCell<null>;

  /**
   * create a new local subscriber, e.g. within a Svelte component
   * @param gs global subscription
   * @param qs initial list of queries (can be empty)
   */
  constructor(
    sheet: Sheet,
    gs: GlobalSubscriber<Query>,
    proxy?: SheetProxy,
    name?: string,
    ...qs: Query[]
  ) {
    this._gs = gs;
    this._qs = new Set();
    this._proxy = proxy || new SheetProxy(sheet, name);
    this._ownProxy = proxy !== undefined;
    this._nullCell = this._proxy.new(null);
    if (qs?.length) this.add(qs);
  }

  /**
   * creates a cell from the query.
   *
   * If a cell already exists, it is reused.
   * @param q
   * @returns
   */
  newData<Q extends Query>(q: Q): WrappedCell<DataOf<Q>> {
    // console.log({ at: Date.now(), new: q });
    if (!q) throw new Error("empty query");
    if (!this._qs.has(q)) {
      this._qs.add(q);
      this._gs._append([q]);
    }
    return this._gs.cell(q, this._proxy);
  }

  /**
   * unwrappedCell maps a query to a retrieved cache definition.
   * @param qCell
   * @param name
   * @param returnErrors
   * @returns
   * @todo rename get
   */
  unwrappedCell<Q extends Query, V = OKData<Q> | null>(
    qCell: AnyCell<Q>,
    name?: string,
    returnErrors = false
  ): MapCell<V | null, false> {
    const cell = this._proxy.map([qCell], (_q) =>
      _q ? this.newData(_q).cell : this._nullCell
    ) as MapCell<DataOf<Q>, false>;
    return cell.map((_v) => {
      // console.log("unwrap", qCell?.value, _v);
      if (!_v) return this._nullCell;
      if (typeof _v === "object" && "never" in _v && _v?.never)
        return this._nullCell;
      if (typeof _v === "object" && "wait" in _v && _v?.wait) return;
      return cell;
    }, name) as MapCell<V | null, false>;
  }

  staticQuery<Q extends Query, V = OKData<Q> | null>(
    q: Q,
    name?: string,
    returnErrors = false
  ): MapCell<V | null, false> {
    const cell = this.newData(q).cell;
    return this._proxy.map(
      [cell],
      (_v) => {
        // console.log("unwrap", qCell?.value, _v);
        if (!_v) return this._nullCell;
        if (typeof _v === "object" && "never" in _v && _v?.never)
          return this._nullCell;
        if (typeof _v === "object" && "wait" in _v && _v?.wait) return;
        return cell;
      },
      name
    ) as MapCell<V | null, false>;
  }

  /**
   * add new queries to the local subscription
   * @param queries
   */
  add(queries: Query[]) {
    // added are the new queries
    const adding = queries.filter((q) => !this._qs.has(q));
    for (const q of adding) this._qs.add(q);
    // append to global batch
    // console.log({ adding });
    this._gs._append(adding);
  }

  /**
   * delete all local subscriptions, typically when the component
   * is destroyed.
   */
  destroy() {
    // console.log("destroy: unsubscribing", this);
    this._gs._remove(this._qs);
    // Also destroys the proxy if it was automatically created.
    if (this._ownProxy) this._proxy.destroy();
  }
}
