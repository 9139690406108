<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import { useHistory, useLocation, useRouter } from "./contexts";
  import type { GetPropsParams } from "./types";
  import { resolve, shouldNavigate } from "./utils";

  export let to = "#";
  export let replace = false;
  export let state = {};
  export let getProps = (
    input: GetPropsParams & { existingProps: SvelteRestProps }
  ) => ({}) as GetPropsParams;
  export let preserveScroll = false;

  const location = useLocation();
  const { base } = useRouter();
  const { navigate } = useHistory();
  const dispatch = createEventDispatcher();

  $: href = to === "/" ? $base.uri : resolve(to, $base.uri);
  $: isPartiallyCurrent = $location.pathname.startsWith(href);
  $: isCurrent = href === $location.pathname;
  $: ariaCurrent = isCurrent ? ("page" as const) : undefined;
  $: props = getProps({
    location: $location,
    href,
    isPartiallyCurrent,
    isCurrent,
    existingProps: $$restProps
  });

  const onClick = (event) => {
    dispatch("click", event);
    if (shouldNavigate(event)) {
      event.preventDefault();
      // Don't push another entry to the history stack when the user
      // clicks on a Link to the page they are currently on.
      const shouldReplace = $location.pathname === href || replace;
      navigate({ to: href, ...state, replace: shouldReplace, preserveScroll });
    }
  };
</script>

<a
  {href}
  aria-current={ariaCurrent}
  on:click={onClick}
  {...props}
  {...$$restProps}
>
  <slot active={!!ariaCurrent} />
</a>
