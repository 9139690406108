// FIXME: syncable from https://github.com/chainlist/svelte-syncable/blob/master/index.js ?

import { Store } from "@scv/hstore";
import type { Draft } from "immer";

interface State {
  readonly errors: Array<string>; // list of error messages
  readonly unsupported: boolean; // unsupported browser
  readonly UI: Record<string, unknown>;
  // readonly walletID: string; // ID of wallet // FIXME: ID or address?
}

// FIXME: also integrate user login state here? and tell if token is rejected... (i.e. server restart...)
const STORE = new Store("objects", <State>{
  errors: [],
  unsupported: false,
  UI: {} // UI configuration (which tabs are opened, etc.)
  // walletID: "",
});

/**
 * dispatch a state update by applying a patch.
 * @param fn patch function
 *
 * @todo async only or two different functions?
 * @todo once typing for async/not async is fixed, should be part of Store
 *
 * @see https://github.com/immerjs/immer/issues/115 for all mutations
 */
const dispatch = async (
  fn: ((draft: Draft<State>) => Promise<void>) | ((draft: Draft<State>) => void)
) => {
  try {
    if (fn.constructor.name === "AsyncFunction") {
      await STORE.asyncApply(fn, "dispatch");
      return true;
    }
    STORE.apply(fn);
    return true;
  } catch (err) {
    console.log("ERROR", err.toString());
    errors.set(err); // recursive call to dispatch
    return false;
  }
};

const error_message_is_json = (err: Error) => {
  console.log(err.message);
  try {
    const v = JSON.parse(err.message);
    if (Array.isArray(v)) {
      return v[0];
    }
    return v;
  } catch (_) {
    return err.message;
  }
};

/**
 * errors enables to set error messages and reset them.
 */
export const errors = {
  reset: () => {
    return dispatch((state) => {
      state.errors = [];
    });
  },
  // set msg: Error or string
  set: (err: (Error & { reason?: string }) | string) => {
    console.log({ err, reason: typeof err === "object" && err?.reason });
    return dispatch((state) => {
      state.errors.push(
        typeof err === "string"
          ? err
          : "reason" in err
            ? err.reason
            : error_message_is_json(err)
      );
    });
  },
  /**
   * try to execute callback function and
   */
  try: async <T>(fn: () => T): Promise<T> => {
    try {
      return await fn();
    } catch (err) {
      errors.set(err);
      return undefined;
    }
  }
};

/**
 * UI enables to update the UI configuration.
 */
export const UI = {
  set: (key, value) => {
    return dispatch((state) => {
      state.UI[key] = value;
    });
  }
};

// /**
//  * walletID sets the wallet ID.
//  */
// export const walletID = {
//   set: (id: string) =>
//     dispatch((state) => {
//       state.walletID = id;
//     }),
// };

/** unsupported sets the browser to unsupported */
export const unsupported = () => {
  return dispatch((state) => {
    state.unsupported = true;
  });
};

// FIXME: rename
export const app_state = {
  // wallet: async () => (await STORE.get()).walletID,
  subscribe: STORE.subscribe
};
