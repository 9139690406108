import { toHex } from "viem";

import type { AnyCell, MapCell, SheetProxy } from "@okcontract/cells";
import type {
  Address,
  ChainType,
  LocalRPCSubscriber
} from "@okcontract/multichain";

import type { DefaultContracts } from "./defaultContract";
import { packetToBytes } from "./ens";

export const unstoppable_reverse_name = (
  local: LocalRPCSubscriber,
  def: DefaultContracts,
  addr: AnyCell<Address>,
  noFail = true
) =>
  local.call(
    def.polygonUNS,
    def.abiUNS,
    def.reverseNameOf,
    addr.map(
      (v) => (v && [addr]) || [],
      "unstoppable.unstoppable_reverse_name.args"
    ),
    local._proxy.new({
      name: "unstoppable.unstoppable_reverse_name.args",
      noFail
    })
  );

const universalResolverErrors = [
  {
    inputs: [],
    name: "ResolverNotFound",
    type: "error"
  },
  {
    inputs: [],
    name: "ResolverWildcardNotSupported",
    type: "error"
  }
] as const;

export const universalResolverReverseAbi = [
  ...universalResolverErrors,
  {
    name: "reverse",
    type: "function",
    stateMutability: "view",
    inputs: [{ type: "bytes", name: "reverseName" }],
    outputs: [
      { type: "string", name: "resolvedName" },
      { type: "address", name: "resolvedAddress" },
      { type: "address", name: "reverseResolver" },
      { type: "address", name: "resolver" }
    ]
  }
] as const;

export const findName = (
  local: LocalRPCSubscriber,
  proxy: SheetProxy,
  def: DefaultContracts,
  ch: AnyCell<ChainType>,
  addr: AnyCell<Address>
): MapCell<string, boolean> => {
  const ensAddr = def.getENSContract(ch);
  const hash = addr.map((_addr) => {
    if (!_addr) return null;
    const rev = `${_addr.toString().toLowerCase().substring(2)}.addr.reverse`;
    return toHex(packetToBytes(rev));
  }, "name.findName.hash");
  const args = hash.map((_hash) => {
    if (!_hash) return [];
    return [hash];
  }, "name.findName.args");
  const ens = local.call(
    ensAddr,
    proxy.new(universalResolverReverseAbi, "name.findName.abi"),
    proxy.new("reverse", "name.findName.functionName"),
    args,
    proxy.new({ name: "name.findName.ens", noFail: true })
  );
  const uns = unstoppable_reverse_name(local, def, addr);
  return proxy.map(
    [ens, uns],
    (ens, uns) => ens?.[0] || uns || null,
    "name.findName"
  );
};
