// @todo remove?
export const ABIXKey = "#abix";

import type {
  AnyCell,
  Cellified,
  SheetProxy,
  ValueCell
} from "@okcontract/cells";
import {
  ABIMethodAdm,
  ABIMethodAllowances,
  ABIMethodButton,
  ABIMethodImage,
  ABIMethodInfo,
  ABIMethodIntent,
  ABIMethodPre,
  ABIMethodRcpt,
  ABIMethodTitle,
  ABIValueTypes,
  isABINumber,
  type ABIMethod,
  type ABIMethodFields,
  type ABIValueType,
  type ABIValues
} from "@okcontract/coredata";
import {
  objectDefinition,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

export const LoadingDefinition: LabelledTypeDefinition = {
  label: "Loading",
  pl: "Loading...",
  base: "string",
  isLoader: true
};

export const coredata_type_definitions: {
  [key in
    | "org"
    | "contract"
    | "token"
    | "person"
    | "nft"
    | "abix"
    | "abi"
    | "chain"]: LabelledTypeDefinition;
} = {
  org: { label: "Organization", name: "Org" },
  contract: { label: "Contract", name: "Contract" },
  token: { label: "Token", name: "Token" },
  person: { label: "Person", name: "Person" },
  nft: { label: "NFT", name: "Token" },
  abix: { label: "ABI Extra", name: "ABIExtra" },
  abi: { label: "ABI", name: "ABI" },
  chain: { label: "Chain", name: "Chain" }
};

export const ABIMethodRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      proxy,
      {
        fields: { label: "", name: "ABIMethodFields" },
        values: (node: EditorNode) => {
          if (!node) return proxy.new({ label: "Values" });
          const valuesCell = proxy.get(node.value) as Cellified<ABIMethod>;
          return valuesCell.map((_v) => ({
            label: "Values",
            name: "ABIValues",
            hidden: !Object.keys(_v)?.length
          }));
        }
      },
      "ABIMethod",
      {
        def: {},
        hideLabel: true,
        lens: (v) => {
          const fields: Cellified<ABIMethodFields> = proxy.new({}, "lens:org");
          const values: Cellified<ABIValues> = proxy.new({}, "lens:name");

          v.subscribe((_v) => {
            const entries = Object.entries(_v);
            for (const [key, value] of entries) {
              const target = key.startsWith("@") ? "fields" : "values";
              const cell = target === "fields" ? fields : values;
              cell.update((_cell) => {
                return { ..._cell, [key]: value };
              });
            }
          });
          const obj = proxy.map(
            [fields, values],
            () => ({ fields, values }),
            "lens:obj"
          );

          obj.subscribe(async (_obj) => {
            if (_obj instanceof Error) throw new Error(`lens failed: ${_obj}`);
            const result = {};
            const fields = _obj.fields.value;
            const values = _obj.values.value;
            Object.assign(result, fields ?? {});
            Object.assign(result, values ?? {});
            const nv = Object.keys(result).length ? result : {};
            // @todo
            v.set(nv);
          });
          // console.log("ABIMethod lens", { obj });
          return obj;
        }
      }
    );

export const ABIMethodFieldsRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> => {
    const element = proxy.new({
      label: "Text or expression",
      base: "string",
      isExpr: true,
      compact: true,
      def: '""'
    } as LabelledTypeDefinition);
    return objectDefinition(
      proxy,
      {
        [ABIMethodAllowances]: {
          label: "Allowances",
          icon: "dollar-o",
          optional: true,
          dict: (k) =>
            proxy.new({
              label: "",
              base: "string",
              isExpr: true,
              compact: true
            }),
          gr: "par"
        },
        [ABIMethodPre]: {
          label: "Pre-Conditions",
          icon: "code-bracket-o",
          array: () =>
            proxy.new({
              label: "Pre-condition",
              base: "string",
              isExpr: true,
              add: true,
              compact: true
            }),
          optional: true,
          gr: "par"
        },
        [ABIMethodTitle]: {
          label: "Title elements",
          icon: "tag-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodInfo]: {
          label: "Information text",
          icon: "bars-left-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodButton]: {
          label: "Button name",
          array: () => element,
          min: 1,
          max: 1,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodImage]: {
          label: "Image",
          hint: "url, IPFS url or expression",
          icon: "image",
          base: "string",
          isExpr: true,
          isImg: true,
          optional: true,
          compact: true,
          gr: "custom"
        },
        [ABIMethodRcpt]: {
          label: "Recipients",
          icon: "users-o",
          array: () => element,
          optional: true,
          hidden: true,
          gr: "custom"
        },
        [ABIMethodAdm]: {
          label: "Admin reserved",
          icon: "user-c-o",
          base: "boolean",
          optional: true,
          gr: "custom"
        },
        [ABIMethodIntent]: objectDefinition(
          proxy,
          {
            n: {
              label: "Name",
              base: "string"
            },
            p: {
              label: "Parameters",
              dict: (k) =>
                proxy.new({
                  label: "",
                  base: "string",
                  isExpr: true
                })
            }
          },
          "Intent",
          {
            icon: "edit-alt-o",
            optional: true,
            hidden: true,
            gr: "par"
          }
        )
      },
      "ABIMethodFields"
    );
  };

const SEARCH_LIST = [
  "token-chain",
  "token",
  "nft",
  "contract",
  "swap",
  "stake"
];
export const ABIValuesRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    proxy.new({
      label: "ABIValues",
      compact: true,
      dict: (node: EditorNode) =>
        objectDefinition(
          proxy,
          {
            v: (node: EditorNode, env: Environment) => {
              const nullCell = proxy.new(null);
              const parent =
                node?.parent &&
                (proxy.get(node.parent).value as {
                  v: AnyCell<string>;
                  ty: AnyCell<string>;
                });
              const ty = parent?.ty?.value || undefined;
              if (ty instanceof Error) return nullCell;
              const search = ty
                ? parent.ty.map((_ty) =>
                    SEARCH_LIST.includes(_ty) ? _ty : null
                  )
                : nullCell;
              return search.map((_search) => ({
                label: "Expression",
                icon: "formula",
                base: "string",
                isExpr: true,
                def: "",
                optional: true,
                search: _search || undefined,
                compact: true
              }));
            },
            ty: {
              label: "Type",
              icon: "variable-o",
              // @todo labels
              enum: ABIValueTypes,
              lens: (v: ValueCell<ABIValueType>) => {
                const type: ValueCell<ABIValueType> = proxy.new(undefined);
                v.subscribe((_v) => {
                  type.set(isABINumber(_v) ? "number" : _v);
                });
                type.subscribe((_type) => {
                  v.set(_type);
                });
                return type;
              },
              optional: true,
              cssView: "badge badge-secondary badge-outline"
            },
            l: {
              label: "Label",
              icon: "tag-o",
              base: "string",
              optional: true,
              compact: true
            },
            em: {
              label: "Enum",
              icon: "hashtag-o",
              base: "string",
              isExpr: true,
              optional: true,
              compact: true
            },
            desc: {
              label: "Description",
              icon: "bars-left-o",
              base: "string",
              optional: true,
              compact: true
            },
            pl: {
              label: "Placeholder",
              icon: "text",
              base: "string",
              optional: true
            },
            a: {
              label: "Amount definition",
              icon: "number",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            def: {
              label: "Default",
              icon: "code-bracket-o",
              optional: true,
              base: "string",
              isExpr: true
            },
            min: {
              label: "Minimum value",
              icon: "minus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            max: {
              label: "Maximum value",
              icon: "plus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            x: {
              label: "Allow external definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            opt: {
              label: "Optional definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            dec: {
              label: "Optional decimals",
              icon: "number",
              optional: true,
              base: "number",
              compact: true
            }
          },
          "ABIValue",
          { cssView: "flex" }
        )
    });
