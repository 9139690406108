const DEV = false;

import type { Connector } from "@wagmi/connectors";
import type { AbiFunction } from "abitype";
import {
  encodeFunctionData,
  type Abi,
  type Log,
  type TransactionReceipt
} from "viem";

import {
  cellify,
  collector,
  flattenCellArray,
  logger,
  reduce,
  uncellify,
  type AnyCell,
  type CellArray,
  type Cellified,
  type MapCell,
  type ValueCell
} from "@okcontract/cells";
import {
  ABIMethodAllowances,
  ABIMethodButton,
  ABIMethodImage,
  ABIMethodInfo,
  ABIMethodPre,
  ABIMethodTitle,
  ChainQuery,
  OKWidgetApproveStep,
  OKWidgetCallStep,
  OKWidgetNetworkTXStep,
  OKWidgetSigStep,
  TokenMarketDataQuery,
  type ABI,
  type ABIExtra,
  type ABIMethod,
  type ABIValue,
  type CacheQuery,
  type EVMToken,
  type OKWidgetStep,
  type OKWidgetStepType,
  type TokenMarketData
} from "@okcontract/coredata";
import {
  DataEditor,
  NEW,
  dataTree,
  emptyValueOfTypeDefinitionAux,
  extractValidCells,
  isValid,
  objectDefinition,
  type EditorParameters,
  type TypeDefinitionFn,
  type TypeScheme
} from "@okcontract/fred";
import {
  Rational,
  newTypeScheme,
  typeNumber,
  typeString,
  type Environment,
  type MonoType,
  type ValueDefinition
} from "@okcontract/lambdascript";
import type { Value } from "@okcontract/lambdascript/src/eval";
import type {
  Chain,
  ChainType,
  EVMAddress,
  GetEstimateGas
} from "@okcontract/multichain";
import {
  chainToViem,
  getTransactionReceipt,
  mapArrayRec,
  type CallQueryType,
  type LocalRPCSubscriber,
  type RawRPCQuery
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";
import { toTitleCase } from "@scv/utils";

import {
  $valueDefinition,
  abiArgDefinition,
  extractArgIndex,
  findMethod,
  getAbiFunction,
  inputsPosition,
  isAnonymousArg,
  mergeExtraABIValue,
  typeFromABIParam
} from "./abi";
import type { AnalyzedLog } from "./analyze";
import { findObjectValue } from "./cellObject";
import { convertToNativeAddressesAndBigInt } from "./converter";
import type { CoreExecution } from "./coreExecution.types";
import { approve_method } from "./erc20";
import type { Instance } from "./instance";
import { EnvKeyABI, EnvKeyChain, EnvKeySelf, EnvKeyValue } from "./keys";
import { typeAddress } from "./lambdascript";
import {
  OKContractManager,
  type AnyContractQuery,
  type DataOfContractQuery,
  type OKContract
} from "./okcontract";
import { createProgram, getMissingInputs } from "./program";
import { getOrgName, type Step, type TXRequest } from "./steps";
import type { SentTransaction } from "./tx";

let txCounter = 0;

type Options = {
  abi?: Abi;
  // @todo should be an Environment directly
  extraEnv?: ValueDefinition[];
  settingsEnv?: Environment;
  forceTXGeneration?: boolean;
  recomputer?: ValueCell<number>;
  /** override title */
  title?: MapCell<unknown[], false>;
  /** override info */
  info?: MapCell<unknown[], false>;
  /** override org */
  org?: MapCell<string, false>;
  okc?: OKContractManager;
  // Override the editor: e.g. for approval steps
  editor?: AnyCell<DataEditor>;
};

export const filterMapByKeys = <T>(
  map: Map<string, T>,
  predicate: (key: string) => boolean
): Map<string, T> =>
  new Map([...map.entries()].filter(([key]) => predicate(key)));

/**
 * OKTransaction is a class containing all information relating to a
 * single blockchain transaction from its creation (inputs), computations,
 * submission and log analysis after mining.
 */
export class OKTransaction<Query extends AnyContractQuery> {
  private _instance: Instance;
  private _counter: number;
  private _core: CoreExecution<Connector>;
  private _local: LocalSubscriber<CacheQuery>;
  private _localRPC: LocalRPCSubscriber;
  private _okc: OKContractManager;
  private _options: Options;
  // @todo remove
  private _recomputer: ValueCell<number>;

  readonly okStep: Cellified<Step<OKWidgetStepType>>;
  /** stepper env */
  readonly $value: ValueCell<Rational>;
  readonly function: AnyCell<AbiFunction>;
  /** check if target method is on the right chain*/
  readonly chainOK: AnyCell<boolean>;
  readonly okContract: AnyCell<OKContract<Query>>;
  readonly contractChain: AnyCell<ChainType>;
  readonly chain: AnyCell<Chain>;
  readonly chains: AnyCell<Map<ChainType, EVMAddress<"evm">>>;
  readonly address: AnyCell<EVMAddress>;
  readonly contractData: AnyCell<DataOfContractQuery<Query>>;
  readonly abi: AnyCell<ABI>;
  readonly abix: AnyCell<ABIExtra>;
  //  basic env data
  readonly inputEnv: Environment;
  /** schema of the transaction */
  readonly schema: TypeScheme;
  /** tx data (from schema values) */
  readonly data: AnyCell<Environment>;
  readonly editor: AnyCell<DataEditor>;
  /** OKTransaction environment */
  readonly env: MapCell<Environment, false>;
  readonly missingInputs: MapCell<string[], false>;
  readonly org: MapCell<string, false>;
  readonly btnLabel: MapCell<string, false>;
  readonly title: MapCell<unknown[], false>;
  readonly info: MapCell<unknown[], false>;
  readonly infoLine: MapCell<string, false>;
  readonly button: MapCell<Value<string>[], false>;
  readonly image: MapCell<string, boolean>;
  readonly allowances: MapCell<[string, Value<unknown>][], boolean>;
  /** tx inputs */
  readonly txInputs: MapCell<AnyCell<unknown>[], false>;
  private _txInputsUncellified: AnyCell<unknown[]>;
  readonly inputsOK: MapCell<boolean, false>;
  /** if preconditions are met */
  readonly isPreOk: MapCell<boolean, false>;
  /** if tx can be sent */
  readonly canSendTX: MapCell<boolean, false>;
  /** this transaction can be skipped */
  readonly skip: AnyCell<boolean>;
  /** encoded data */
  readonly encodedData: MapCell<`0x${string}`, false>;
  /** the tx to be sent */
  readonly tx: MapCell<{ tx: TXRequest; gas_amount: bigint }, false>;
  /** the result of readonly method */
  readonly readOutput: MapCell<Record<number, AnyCell<unknown>>, false>;
  /** the sent transaction */
  readonly sentTx: ValueCell<SentTransaction[]>;
  /** tx receipt */
  readonly receipt: AnyCell<TransactionReceipt>;
  /** list of receipts */
  readonly receipts: ValueCell<TransactionReceipt[]>;
  /** raw logs from receipt */
  readonly rawLogs: AnyCell<AnyCell<Log>[]>;
  /** tx logs */
  readonly $logs: AnyCell<AnyCell<AnalyzedLog>[]>;
  /** prettified logs */
  readonly prettyLogs: AnyCell<unknown[]>;
  /** tx is processing */
  readonly isSending: ValueCell<boolean>;
  /** tx has be processed */
  readonly isDone: ValueCell<boolean>;
  readonly decimals: AnyCell<Rational>;
  readonly tokenPriceData: AnyCell<TokenMarketData>;
  readonly currency: AnyCell<EVMToken>;

  constructor(
    instance: Instance,
    step: Cellified<Step<OKWidgetStepType>>,
    opts: Options = {
      settingsEnv: instance.newEnvironment({ id: "okTransaction" }),
      forceTXGeneration: false
    }
  ) {
    this._counter = txCounter++;
    this._instance = instance;
    this._core = instance._core;
    this._local = instance._local;
    this._localRPC = instance._rpc;
    const proxy = instance._proxy;
    this._okc = opts?.okc || new OKContractManager(instance);
    this._options = opts;
    this._recomputer = opts?.recomputer || proxy.new(0, "recomputer");

    this.okStep = step;
    DEV && logger(this.okStep);

    const query: MapCell<Query, true> = this.okStep.map(
      (step: Cellified<OKWidgetStep<OKWidgetStepType>>["value"]) =>
        (step?.q as AnyCell<Query>) || null,
      "step.query"
    );
    const sty: MapCell<OKWidgetStepType, true> = this.okStep.map(
      (step: Cellified<OKWidgetStep<OKWidgetStepType>>["value"]) =>
        step?.sty || null,
      "step.sty"
    );
    const fch: CellArray<ChainType> = this.okStep.map(
      (step: Cellified<OKWidgetStep<OKWidgetStepType>>["value"]) =>
        step?.fch || null,
      "step.fch"
    );
    const flatForceChains = flattenCellArray(proxy, fch, "ffch");
    flatForceChains.subscribe(async (l) => {
      if (!(l instanceof Error)) {
        const current = await instance.wantedChain.get();
        if (l?.length && !l.includes(current)) instance.wantedChain.set(l[0]);
      }
    });

    this.okContract = this._okc.get(query, { abi: opts?.abi });
    this.address = this.okContract.map((okc) => okc.address);
    this.contractData = this.okContract.map((okc) => okc.data);
    this.abi = this.okContract.map((okc) => okc.abi);
    const parsedAbi = this.abi.map((abi) => abi?.parsed || null);
    this.abix = this.okContract.map((okc) => okc.abix);
    const okEnv = this.okContract.map((okc) => okc.env);

    const coll =
      collector<MapCell<Map<ChainType, EVMAddress<"evm">>, false>>(proxy);
    this.chains = proxy.mapNoPrevious(
      [this.okContract, flatForceChains],
      (okc, fch) =>
        fch === null
          ? okc.chains
          : coll(
              proxy.map([okc.chains], (ch) =>
                filterMapByKeys(ch, (key) => fch.includes(key))
              )
            ),
      `okTX.${this._counter}:chains`
    );
    this.contractChain = proxy.map(
      [this.okContract, flatForceChains],
      (okc, fch) =>
        fch === null || fch.length === 0
          ? okc.chain
          : // @todo collect
            proxy.map([okc.chain], (wanted) =>
              fch.includes(wanted) ? okc.chain : fch[0]
            ),
      `okTX.${this._counter}:contractChain`
    );

    this.chain = proxy.map(
      [this.contractChain, this._core.Chains],
      (ch, chains) => chains[ChainQuery(ch)] || null,
      `okTX.${this._counter}:chain`
    );

    // tx method name (as cell)
    const meth = this.okStep.map(
      (step: Cellified<Step<OKWidgetStepType>>["value"]) => step?.m || null
    );
    // tx function
    this.function = proxy.map(
      [parsedAbi, meth],
      (_abi, _method) => getAbiFunction(_abi, _method),
      `okTX.${this._counter}:function`
    );
    DEV && logger(this.function);
    const methodName = this.function.map(
      (m) => m?.name || null,
      `okTX.${this._counter}:methodName`
    );

    this.chainOK = proxy.map(
      [
        sty,
        this.contractChain, //this.core.CurrentChain,
        this.abi,
        this.function,
        flatForceChains
      ],
      (_sty, _chain, _abi, _method, _fch) => {
        if (!_method || !_abi) return false; // @todo false
        if (_method?.stateMutability === "view") return true;
        // if (_sty === OKWidgetNetworkTXStep)
        //   return find(proxy, fch, (v) => v === _chain); // _fch.includes(_chain);
        // @previously isOnCurrentChain(_sty, _fch, _chain, _abi);
        return _sty === OKWidgetNetworkTXStep // || _sty === OKWidgetApproveStep
          ? _fch === null || _fch.includes(_chain)
          : _sty === OKWidgetCallStep || _sty === OKWidgetApproveStep
            ? _abi?.addr?.chain === _chain
            : _sty === OKWidgetSigStep;
      },
      `okTX.${this._counter}:chainOK`
    );
    DEV && logger(this.chainOK);

    const xm = proxy.map(
      [this.okStep, this._recomputer],
      (step, _r) => step?.xm || null,
      `okTX.${this._counter}:xm`
    ) as unknown as Cellified<ABIMethod>;
    DEV && logger(xm);

    /**
     * merged all definitions (sorted in order of priority) for a given method from:
     * - abix values (common abix definitions)
     * - abix method (definitions for a given method)
     *  - xm (definitions from the widget)
     * @todo check that its all ABIValues
     * @todo this should be Cellified too, once Abix is Cellified
     */
    const abiMethod = proxy
      .map(
        [xm, methodName, this.abix], // @todo recomputer?
        async (_xm, _m, _abix, _recompute) =>
          ({
            ..._abix?.values,
            ..._abix?.methods?.[_m],
            // @todo: not reactive...
            ...(await uncellify(_xm, {
              getter: (cell) => cell.value
            }))
          }) as ABIMethod
      )
      // merge
      .map((_values) => {
        // add $address to ABIValues for token / contract and token-chain
        const res = Object.fromEntries(
          Object.entries(_values).map(([k, v]) =>
            ["token", "contract", "token-chain"].includes(v?.ty) && v?.v
              ? [k, { ...v, v: `$address(${v?.v})` }]
              : [k, v]
          )
        );
        return res;
      }, `okTX.${this._counter}:abiMethod`) as AnyCell<ABIMethod>;
    DEV && logger(abiMethod);

    const program = abiMethod.map(
      (abiMethod) => createProgram(abiMethod),
      `okTX.${this._counter}:program`
    );
    DEV && logger(program);

    const types = instance._coredataTypeScheme();
    const typesCell = proxy.new(types, `okTX.${this._counter}:types`);
    // build envs
    this.$value = proxy.new(new Rational(0), `okTX.${this._counter}:$value`);

    // @todo add settings here?
    const coreEnv = this._instance._proxy.map(
      [okEnv],
      (env) =>
        env.withValueTypes(
          [EnvKeyValue, this.$value, newTypeScheme(typeNumber)],
          [EnvKeySelf, this._core.WalletID, newTypeScheme(typeAddress)],
          [EnvKeyChain, this.contractChain, newTypeScheme(typeString)],
          ...(opts?.extraEnv || []),
          ...(opts?.settingsEnv?.valueTypes() || [])
        ),
      `okTX.${this._counter}:coreEnv`
    );
    const getKeys = (env: Environment) => env.keys(false);
    const getValues = (env: Environment) => env._values;
    DEV && logger(coreEnv, getValues);

    // partialEnv only serves to hold enough values to evaluate
    // the empty values, it should be discarded when reduce the
    // whole program afterwards.
    // @todo check that no computation are made in double, maybe
    // reuse some env cells.
    const partialEnv = this._instance._proxy.mapNoPrevious(
      [coreEnv, program],
      async (env, prog) => {
        const [out, rem, deps] = await prog.partialReduce(env);
        console.log(`okTX(${this._counter}):partialEnv`, { rem, deps });
        return out;
      },
      `okTX.${this._counter}:partialEnv`
    );
    DEV && logger(partialEnv, getValues);

    this.missingInputs = proxy.map(
      [program, this.function, partialEnv, abiMethod], // @todo was preEnv
      (prog, fn, env, meth) =>
        getMissingInputs(prog, fn, env, Object.keys(meth)),
      `okTX.${this._counter}:missingInputs`
    );
    DEV && logger(this.missingInputs);

    // @todo use mapArray to benefit from reuse + collection
    // @todo probably no need to react on wallet change to recompute definitions
    const missingDefs = proxy.mapNoPrevious(
      [this.missingInputs, partialEnv], // instance._core.WalletID
      (inputs, env) => {
        const res: [string, TypeDefinitionFn, AnyCell<MonoType>][] = [];
        for (const key of inputs) {
          // console.log("missingDefs", { key });
          const lowKey = key.toLowerCase();
          // @todo use constant
          if (key === "$value") {
            res.push([
              lowKey,
              $valueDefinition(
                instance._local,
                instance._core,
                instance._rpc,
                env.value(EnvKeyABI) as AnyCell<ABI>
              ),
              proxy.new(typeNumber, "typeNumber")
            ]);
            continue;
          }
          const anonKeyIndex = isAnonymousArg(key)
            ? extractArgIndex(key)
            : null;
          const abiParam = this.function.map(
            (_af) =>
              _af.inputs.find((_input, index) =>
                anonKeyIndex !== null
                  ? index === anonKeyIndex
                  : _input.name === key
              ) ||
              // null if not a direct parameter but comes from ABIMethod
              null,
            `missingDefs.abiParam:${key}`
          );
          DEV && logger(abiParam);
          const abiValue = abiMethod.map(
            // @ts-ignore strange type error
            (am) => findMethod(am, key),
            `missingDefs.abiValue:${key}`
          ) as MapCell<ABIValue, false>;
          DEV && logger(abiValue);
          const definition = abiArgDefinition(
            instance,
            partialEnv,
            this.function,
            abiValue,
            abiParam
          );
          // console.log("missingDefs", simplifier({ abiValue, definition }));
          const merged = mergeExtraABIValue(
            instance._proxy,
            definition,
            abiMethod,
            key
          );
          DEV && logger(merged);
          const def = () => merged;
          res.push([
            lowKey,
            def,
            instance._proxy.map([abiParam, abiValue], typeFromABIParam)
          ]);
        }
        return res;
      },
      `okTX.${this._counter}:missingDefs`
    );
    DEV && logger(missingDefs);

    const values = missingDefs.map(
      (arr) => Object.fromEntries(arr.map(([k, v, _]) => [k, v])),
      `okTX.${this._counter}:values`
    );
    this.schema = {
      types: typesCell,
      values: objectDefinition(proxy, values),
      gs: proxy.new([], `okTX.${this._counter}:schema.gs`)
    };

    // Environment containing input data.
    const dataEnv = instance.newEnvironment({ noInstance: true });

    // data is the interaction inputs
    // @todo reuse previous values
    // @todo rewrite
    this.data = proxy.mapNoPrevious(
      [missingDefs, partialEnv], // @todo react on missing inputs
      async (defs, env) => {
        // @todo We could recreate the dataEnv each time... investigate
        // const dataEnv = instance.newEnvironment({ noInstance: true });

        // We either create a new key / value and add it to this env
        // or if the key is already existing in preEnv we retrieve it.
        // Note: empty has already been filtered with the required inputs.
        // If the key is in dataEnv, we conserve it.

        for (const [key, typeDef, monoType] of defs)
          if (dataEnv.value(key) === undefined) {
            // We don't override previous definitions.
            // @todo we should not need to get the cell here
            const tyCell = typeDef();
            const ty = await tyCell.get();
            if (env.value(key) !== undefined) {
              console.warn(
                `okTX.${this._counter}: Key ${key} exists in partialEnv`
              );
            }
            const cell =
              // reuse value from preEnv, e.g. `$value`
              env.value(key) ||
              // create new one
              cellify(
                proxy,
                await emptyValueOfTypeDefinitionAux(proxy, types, ty, {
                  oneElementInArray: true,
                  deep: false, // @todo check
                  label: `empty:${key}`
                }),
                `data:${key}`
              );
            // The MonoType should not change as it will not be reactive.
            const _mt = await monoType.consolidatedValue;
            console.log("data", {
              key,
              _mt,
              prev: env.value(key),
              cell,
              monoType
            });
            if (!(_mt instanceof Error))
              dataEnv.addValueType(key, cell, newTypeScheme(_mt));
          }
        // @todo remove cells in dataEnv that are not needed anymore.
        return dataEnv;
      },
      `okTX.${this._counter}:data`
    );
    DEV && logger(this.data, getValues);

    this.env = proxy.mapNoPrevious(
      [program, partialEnv, this.data],
      (_program, env, data) => _program.reduce(env.mergeWith(undefined, data)),
      `okTX.${this._counter}:env`
    );
    DEV && logger(this.env, getKeys);

    // @todo use okTX recomputer?
    const editorParameters = {
      mode: NEW,
      recomputer: proxy.new(0),
      showLabels: false,
      name: "ABIFunctionEditor"
    } as EditorParameters;

    this.editor =
      opts?.editor ||
      proxy.map([this.data, this.env], (_data, _env, prev) => {
        console.log("okTX: new editor", this._counter);
        return (
          prev ||
          new DataEditor(
            proxy,
            proxy.new(_data._values, "editor.values"),
            this.schema,
            _env,
            editorParameters
          )
        );
      });

    // @todo could be use to retrieve values from previous steps
    // @todo do we need/want that?
    // proxy.map(
    //   [this.sEnv, this.env],
    //   async (_sEnv, env) => {
    //     const empty = {
    //       ...(await emptyValueOfTypeDefinition(proxy, this.schema, ltd)?.get()),
    //     };
    //     const values = Object.keys(await this.schema.values.get());
    //     // keep already defined value in env
    //     // else set default empty value
    //     const data = values.reduce(
    //       (acc, value) => ({
    //         ...acc,
    //         [value]: _sEnv?.[value] || empty?.[value],
    //       }),
    //       {}
    //     );
    //     // this.data.set(data);
    //     return null;
    //   },
    //   "okTX.setData"
    // );

    // @move to core
    // @todo probably not needed anymore
    const trueCell = proxy.new(true, "true");
    // @move to core
    // const falseCell = proxy.new("false", "falseExpr");

    const skip = proxy.map(
      [this.okStep],
      (step, _r) => step?.skip || "false",
      `okTX.${this._counter}:(skip)`
    ) as MapCell<string | null, true>;

    this.skip = proxy.map(
      [skip, this.env],
      (_skip, _env) =>
        _env.evaluateString(_skip) as unknown as MapCell<boolean, false>,
      `okTX.${this._counter}:skip`
    );

    // @todo rewrite
    this.skip.subscribe((_skip) => {
      if (_skip instanceof Error) return;
      // console.log("_approval", { _skip });
      this.isDone.set(_skip);
    });

    this.isPreOk = proxy.mapNoPrevious(
      [this.env, abiMethod],
      (_env, _abiMethod) => {
        if (!_abiMethod?.[ABIMethodPre]) return trueCell;
        const expr = _abiMethod[ABIMethodPre]
          ?.map((def) => `(${def})`)
          ?.join("&&");
        return _env.evaluateString(
          expr
        ) as unknown as Value<boolean> as AnyCell<boolean>;
      },
      `okTX.${this._counter}:isPreOk`
    );

    const makeTitle = () => {
      const title = proxy.map(
        [xm],
        (_xm, _r) => (_xm?.[ABIMethodTitle] as Cellified<string[]>) || [],
        `okTX.${this._counter}:xmTitle`
      ) as unknown as Cellified<string[]>;
      const titleDef = proxy.map(
        [title, sty, abiMethod, this.contractData],
        (_title, sty, abi_method, con) => {
          return (
            (_title?.length > 0
              ? flattenCellArray(proxy, title)
              : abi_method?.[ABIMethodTitle] || [
                  `"${
                    con?.name ||
                    con?.id ||
                    (sty === OKWidgetSigStep ? "Sign" : "")
                  }"`
                ]) || []
          );
        }
      );
      return proxy.map(
        [titleDef, this.env],
        (_titleDef, _env) => _env.evaluateStringArray(_titleDef),
        "okTX:title"
      );
    };
    // @todo title should be computed directly in the program
    this.title = this._options?.title || makeTitle();

    const makeInfo = () => {
      const info = proxy.map(
        [xm],
        (_xm) => _xm?.[ABIMethodInfo] || null,
        `okTX.${this._counter}:(info)`
      );
      // @previously default_info_txt(_info, _methodName, _abiMethod),
      const infoDef = proxy.map(
        [info, methodName, abiMethod],
        (_info, m, _abiMethod) => {
          // @todo maybe this should be done only once in methodName...
          const name = m?.includes("(") ? m.substring(0, m.indexOf("(")) : m;
          return _info?.length > 0
            ? flattenCellArray(proxy, info)
            : _abiMethod?.methods?.[name]?.[ABIMethodInfo] || [];
        },
        `okTX.${this._counter}:infoDef`
      );
      return proxy.map(
        [infoDef, this.env],
        (info: string[], _env) =>
          // console.log("info", { chain: _env.value(EnvKeyChain) });
          _env.evaluateStringArray(info),
        `okTX.${this._counter}:info`
      );
      // @todo restore fully cellified
      // this.info = this.env.map(
      //   (_env) =>
      //     mapArray(
      //       proxy,
      //       infoDef,
      //       (v) => _env.evaluateString(v),
      //       `okTX.${this._counter}:[info]`
      //     ),
      //   `okTX.${this._counter}:info`
      // );
    };

    // @todo info and infoLine should be computed directly in the program
    this.info = this._options?.info || makeInfo();
    this.infoLine = reduce(
      proxy,
      this.info as unknown as CellArray<unknown>,
      (acc, v) => `${acc} ${v?.toString()}`,
      ""
    );

    const allowanceDefs = proxy.map(
      [abiMethod],
      (_abiMethod) =>
        _abiMethod?.[ABIMethodAllowances]
          ? Object.entries(_abiMethod[ABIMethodAllowances])
          : [],
      `okTX.${this._counter}:allowanceDefs`
    );

    this.allowances = proxy.map(
      [allowanceDefs, this.env],
      async (_allowances, _env) => {
        const allowances = [];
        for (const [key, def] of _allowances)
          allowances.push([key, await _env.evaluateString(def)]);
        return allowances;
      },
      `okTX.${this._counter}:allowances`
    );

    const button = proxy.map(
      [xm],
      async (_xm, _r) => _xm?.[ABIMethodButton] || null,
      `okTX.${this._counter}:(button)`
    ) as unknown as Cellified<string[]>;
    // @previously default_btn_txt(_button, _methodName, _abiMethod),
    const buttonDef = proxy.map(
      [button, methodName, abiMethod],
      (_button, m, abi_method) => {
        const name = m?.includes("(") ? m.substring(0, m.indexOf("(")) : m;
        return (
          (_button?.length
            ? flattenCellArray(proxy, button)
            : abi_method?.[ABIMethodButton] ||
              (name !== undefined
                ? [
                    // proxy.new(
                    `"${name}"`
                    // , `default_btn:${name}`)
                  ]
                : [])) || []
        );
      },
      `okTX.${this._counter}:buttonDef`
    );
    // @todo removed method
    this.button = proxy.map(
      [buttonDef, this.env, this.okStep],
      async (_buttonDef, _env, _step) => _env.evaluateStringArray(_buttonDef),
      `okTX.${this._counter}:button`
    ) as MapCell<Value<string>[], false>;

    this.btnLabel = proxy.mapNoPrevious(
      [sty, methodName, this.button],
      // @ts-expect-error @todo
      (_sty, _methodName, _button) => {
        if (_button?.length) return _button[0];
        switch (_sty) {
          case OKWidgetCallStep:
            return toTitleCase(_methodName);
          case OKWidgetNetworkTXStep:
            return "Execute";
          case OKWidgetApproveStep:
            return approve_method;
          case OKWidgetSigStep:
            return "Sign";
        }
        return "";
      },
      `okTX.${this._counter}:btnLabel`
    );

    const imageDef = proxy.map(
      [xm],
      (_xm, _r) => _xm?.[ABIMethodImage] || null,
      `okTX.${this._counter}:imageDef`
    );
    this.image = proxy.map(
      [imageDef, this.env],
      (urlExpression: string, env) =>
        // @previously evalImageURL(_env, _imageDef),
        {
          if (!urlExpression) return null;
          // @todo factor all .startsWith("ipfs://") in utility function
          if (
            urlExpression.startsWith("https://") ||
            urlExpression.startsWith("ipfs://")
          )
            return urlExpression;
          // @todo (EvalOption.FailUndefined)
          return (
            (env.evaluateString(urlExpression) as unknown as AnyCell<string>) ||
            "loading"
          );
        },
      `okTX.${this._counter}:image`
    );

    const makeOrg = () => {
      const org = proxy.map(
        [this.okStep],
        (_step, _r) => _step?.org || null,
        `okTX.${this._counter}:(org)`
      ) as unknown as MapCell<string, true>;
      // @todo also did depend on this.function
      return proxy.mapNoPrevious(
        [sty, query, org, this.env],
        (_sty, _q, _org, _env) => getOrgName(this._core, _env, _q, _sty, _org),
        `okTX.${this._counter}:org`
      );
    };

    this.org = this._options?.org || makeOrg();

    // Transaction Data
    const nativeValue = proxy.map(
      [this.$value, this.function],
      (_$value, _method) =>
        _method?.stateMutability !== "payable" || !_$value
          ? new Rational(0)
          : _$value,
      `okTX.${this._counter}:nativeValue`
    );

    const inputsTree = dataTree(
      proxy,
      this.data.map((_data) => _data._values || null, "inputsTree.values"),
      this.schema
    );
    const validInputs = extractValidCells(proxy, inputsTree);
    this.inputsOK = isValid(proxy, validInputs);

    // Tx generation
    this.txInputs = proxy.map(
      [this.function, this.env, this.chainOK, this.inputsOK],
      (_method, _env, _okChain, _inputsOK) =>
        _inputsOK && _okChain && _method ? inputsPosition(_method, _env) : [],
      `okTX.${this._counter}:txInputs`
    );

    // @todo just mapArray with recomputer?, ideally remove need for uncellified version
    this._txInputsUncellified = mapArrayRec(proxy, this.txInputs, (v) => v).map(
      (res) =>
        uncellify(res, {
          getter: (cell) => cell.value
        }),
      `okTX.${this._counter}:txInputsUncellified`
    );

    this.canSendTX = proxy.map(
      [this.function, this.isPreOk, this.inputsOK],
      (_method, _isPreOk, _inputsOK) =>
        _method && _method.stateMutability !== "view" && _inputsOK && _isPreOk,
      `okTX.${this._counter}:canSendTX`
    );

    // @todo handle ApproveTX / NTX
    this.encodedData = proxy.map(
      [
        parsedAbi,
        methodName,
        this.chainOK,
        this.canSendTX,
        this._txInputsUncellified
      ],
      async (abi, functionName, _okChain, _canSendTX, _inputValues) => {
        if (!_canSendTX || !_okChain) return null;
        const args = convertToNativeAddressesAndBigInt(_inputValues);
        // @ts-expect-error infinite recursion in viem
        const encodedData = encodeFunctionData({
          abi,
          functionName,
          // @ts-expect-error readonly error
          args
        }) as `0x${string}`;
        return encodedData;
      },
      `okTX.${this._counter}:encodedData`
    );

    this.isSending = proxy.new(false, `okTX.${this._counter}:isSending`);
    // @ts-expect-error @todo Property 'gas_amount' is missing
    this.tx = proxy.mapNoPrevious(
      [this.canSendTX, this.skip],
      (_canSendTX, _skip) =>
        _canSendTX && (!_skip || opts.forceTXGeneration)
          ? instance._generateTX(
              this.address,
              this.encodedData,
              nativeValue,
              !opts.forceTXGeneration
            )
          : null,
      `okTX.${this._counter}:tx`
    );

    // @todo should be mapped?
    // @todo rewrite
    this.isDone = proxy.new(
      (async () => {
        const okStep = await step.get();
        if (okStep instanceof Error) throw okStep;
        // @todo check value
        return okStep?.sty?.value === OKWidgetApproveStep &&
          "done" in okStep &&
          // @ts-expect-error fix Cellified types
          okStep?.amount?.value !== BigInt(0)
          ? (okStep.done as boolean)
          : false;
      })(),
      `okTX.${this._counter}:isDone`
    );

    // method evaluation
    const canCallMethod = proxy.map(
      [this.chainOK, this.function, this.txInputs],
      (_okChain, _method, _inputs) =>
        (_okChain &&
          _method?.outputs &&
          _method?.stateMutability === "view" &&
          _inputs &&
          true) ||
        false,
      `okTX.${this._counter}:canCallMethod`
    );
    const methodAddr = proxy.map(
      [this.address, canCallMethod],
      (_addr, _canCall) => (_canCall ? _addr : null),
      `okTX.${this._counter}:methodAddr`
    );
    this.readOutput = this._localRPC
      .call(methodAddr, parsedAbi, methodName, this.txInputs)
      .map((output) => {
        if (output instanceof Error) throw output;
        if (Array.isArray(output))
          return Object.fromEntries(output.map((out, i) => [i, out]));
        return { 0: proxy.new(output) };
      });

    this.sentTx = proxy.new([], `okTX.${this._counter}:sentTx`);
    const sentTXHash = this.sentTx.map(
      (_sentTx) => _sentTx?.[_sentTx?.length - 1]?.hash || null,
      `okTX.${this._counter}:sentTXHash`
    );
    // last receipt
    this.receipt = getTransactionReceipt(
      this._localRPC,
      sentTXHash,
      this.contractChain
    );

    /**
     * receipts is a list of receipt since a given
     * OKTransaction could be repeated multiple times.
     */
    this.receipts = proxy.new([], `okTX.${this._counter}:receipts`);
    // add new receipts in receipts list and core
    this.receipt.subscribe(async (_receipt) => {
      if (_receipt instanceof Error) throw _receipt;
      if (!_receipt) return;
      // @todo why? we can have 2 tx in same block
      // @todo required?
      const exists = (l: TransactionReceipt[]) =>
        l.find((_r) => _r.blockHash === _receipt.blockHash);
      this.receipts.update((l) => (exists(l) ? [...l, _receipt] : undefined));
      this._core.Receipts.update((l) =>
        exists(l) ? [...l, _receipt] : undefined
      );
    });
    // listen to receipt to notify when a tx is completed
    this.receipt.subscribe((_receipt) => {
      if (_receipt instanceof Error) {
        this.isSending.set(false);
        return;
      }
      // @todo check if necessary for approve_method
      // if (!_receipt || this.function.value.name === approve_method) return;
      if (!_receipt) return;
      this.isSending.set(false);
      // @todo check if necessary for approve_method
      if (this.function.value.name === approve_method) return;
      this.isDone.set(true);
    });

    this.rawLogs = this.receipt.map(
      (_receipt) =>
        _receipt?.logs?.map((log) => proxy.new(log, "okTX.rawLog")) || [],
      "okTX.rawLogs"
    );

    this.$logs = instance._analyzeLogs(this.contractChain, this.rawLogs);
    this.prettyLogs = this.$logs.map(
      (_logs) =>
        _logs?.length
          ? _logs.map((_log) =>
              instance.logTitle(
                // @todo check wallet
                instance._core.WalletID,
                _log
              )
            )
          : [],
      `okTX.${this._counter}:prettyLogs`
    );

    // add logs in shared env
    // proxy.map(
    //   [this.rawLogs, this.$logs, this.prettyLogs],
    //   (_raw, _analyzed, _pretty) => {
    //     sLogs.update((_sLogs) => {
    //       _sLogs["raw"] = [..._sLogs?.raw, _raw];
    //       _sLogs["analyzed"] = [..._sLogs?.analyzed, _analyzed];
    //       _sLogs["pretty"] = [..._sLogs?.pretty, _pretty];
    //       return _sLogs;
    //     });
    //   },
    //   "okTX.sharedLogs"
    // );

    /** decimals */
    // @todo remove
    // this.decimals = instance._getDecimals(addr);
    /** token currency */
    // @todo remove
    this.currency = this.contractChain.map((_chain) => {
      const chain = findObjectValue(
        proxy,
        this._core.Chains,
        (v) => v.id === _chain
      ) as AnyCell<Chain>;
      const tokenQuery = chain.map((_chain) => _chain?.currency || null);
      return this._local.unwrappedCell(tokenQuery);
    }, `okTX.${this._counter}:currency`) as AnyCell<EVMToken>;
    /** token price data from server */
    const tmdQuery = this.currency.map((c) => {
      if (!c) return null;
      const coingecko = c.foreign.find((f) => f.includes("coingecko"));
      const id = coingecko.split(":")[1];
      return TokenMarketDataQuery(id);
    }, "tmq");
    this.tokenPriceData = this._local.unwrappedCell(tmdQuery, "tpd");
  }

  /**
   * setValue sets the $value cell containing the transaction value.
   * @param v any float or integer number, without decimals conversion, or exact `bigint`
   * @example tx.setValue(0.001)
   */
  public setValue = (v: number | bigint) => {
    const chain = this.chain.value;
    if (chain instanceof Error) throw chain;
    const dec = chain.decimals === undefined ? 18 : chain.decimals;
    this.$value.set(new Rational(typeof v === "number" ? v * 10 ** dec : v));
  };

  /**
   * sendTX submits the transaction on-chain.
   * @returns block hash
   */
  public sendTX = async () => {
    const txData = await this.tx.consolidatedValue;
    if (txData instanceof Error) return null;
    const tx = txData.tx;
    // console.log({ tx });
    const walletID = await this._core.WalletID.consolidatedValue;
    if (walletID instanceof Error) return null;
    const ch = await this._core.Chain.get();
    if (ch instanceof Error) return;
    const walletClient = await this._core.WalletClient.consolidatedValue;
    if (walletClient instanceof Error) return null;
    // @todo could be a Cell
    const input = {
      chain: chainToViem(ch),
      value: tx?.value,
      to: tx?.to,
      data: tx?.data,
      gasLimit: tx?.gasLimit,
      from: tx?.from
    };
    const hash = await walletClient
      // @ts-expect-error: check gasLimit exists, and viem types
      .sendTransaction(input);
    // console.log("SendTX= hash", { hash });
    if (!hash) return null;
    this.isSending.set(true);
    const method = await this.function.get();
    if (method instanceof Error) throw method;
    const tokenPrice = await this.tokenPriceData.get();
    const newTx = {
      ch: ch.id,
      hash,
      url: `${ch.explorer}/tx/${hash}`,
      fnName: method.name,
      gasCurPrice: tokenPrice instanceof Error ? 0 : tokenPrice?.current_price,
      sentAt: new Rational(Date.now()) // @todo should not be?
    } as SentTransaction;
    // add to okTransaction
    this.sentTx.update((sent) => [...sent, newTx]);
    // add to core
    this._core.SentTransactions.update((all) => [...all, newTx]);
    return hash;
  };

  /**
   * Sign the raw transaction, for the few EOAs that support it.
   * @todo should be a ValueCell?
   */
  public signTX = async () => {
    const txData = await this.tx.get();
    if (txData instanceof Error) return null;
    const tx = txData.tx;
    const walletID = await this._core.WalletID.get();
    if (walletID instanceof Error) return null;
    const ch = await this._core.Chain.get();
    if (ch instanceof Error) return;
    // @todo expose in Core?
    const walletClient = await this._core.WalletClient.get();
    if (walletClient instanceof Error) return null;
    try {
      const input = {
        chain: chainToViem(ch),
        value: tx?.value,
        to: tx?.to,
        data: tx?.data,
        gasLimit: tx?.gasLimit,
        from: tx?.from
      };
      // @ts-expect-error: viem types
      const req = await walletClient.prepareTransactionRequest(input);
      console.log("signTX", { input, req });
      const sig = await walletClient.signTransaction(req);
      console.log("signTX", { sig });
    } catch (err) {
      console.log("signTX", err);
    }
  };

  public switchChain = () => {
    const chain = this.chain.value;
    if (chain instanceof Error) {
      console.error("switchChain", chain);
      return;
    }
    // console.log("Switching to", chain.id);
    this._core.SwitchChain(chain.id);
  };

  // once approve tx is done:
  // - we invalidate the current allowance query and retry with
  //   the receipt blockNumber
  // - once the query invalidation is confirm with the new value updated
  //   we manually set the OKTransaction to a done state and stop the
  //   isSending cell
  _approvalRefresh = async (
    _receipt: TransactionReceipt | Error,
    _allowanceQuery: AnyCell<[ChainType, RawRPCQuery]>,
    _estimateQuery: AnyCell<[ChainType, GetEstimateGas]>
  ) => {
    if (_receipt instanceof Error) throw _receipt;
    if (!_receipt) return;

    const q = await _allowanceQuery.get();
    if (q instanceof Error) throw q;
    const estimateQuery = await _estimateQuery.get();
    if (estimateQuery instanceof Error) throw estimateQuery;

    // @todo move to rpc
    const queryWithBlockNumber = {
      ...q[1],
      params: [q[1].params[0], `0x${_receipt.blockNumber.toString(16)}`]
    } as CallQueryType;

    // invalidating rpc query
    await this._localRPC.invalidate(q, {
      cb: () => {
        this.isSending.set(false);
        this.isDone.set(true);
      },
      replaceQuery: queryWithBlockNumber
    });
    await this._localRPC.invalidate(estimateQuery, {
      cb: () => {
        this.isSending.set(false);
        this.isDone.set(true);
      },
      replaceQuery: estimateQuery[1]
    });
  };

  public wait() {
    return this._instance._proxy.working.wait();
  }
}
