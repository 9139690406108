import type { AnyCell } from "@okcontract/cells";
import type {
  CacheQuery,
  CacheQueryFromType,
  CachedData,
  WritableDataType,
  WriteResponse
} from "@okcontract/coredata";
import type { Definitions } from "@okcontract/coredata/coredata";
import type { AuthClient } from "@scv/auth";

const TagWithData = "data";

// @todo remove interface
// @todo adjust to converted
export interface APICacheInterface {
  /**
   * Refresh a set of queries, asking the server to update its own data.
   * @param ids
   * @param withData
   * @returns
   */
  Refresh: (
    ids: CacheQuery[],
    withData?: boolean
  ) => Promise<{
    time: number;
    cd: CachedData<keyof Definitions>[];
    miss?: CacheQuery[];
  }>;
  /**
   * Collect a set of queries, taking whatever values are already available in the server.
   * @param ids
   * @returns
   */
  Collect: (ids: CacheQuery[]) => Promise<{
    cd: CachedData<keyof Definitions>[];
  }>;
  /**
   * Write/update a data for a query.
   * @param json
   * @param sig
   * @param auth
   * @returns
   */
  Write: <T extends WritableDataType>(
    json: string,
    sig: string,
    auth?: string
  ) => Promise<WriteResponse<T>>;
  /**
   * Delete a query from the server.
   * @param q
   * @param sig
   * @returns
   */
  Delete: <T extends WritableDataType>(
    q: CacheQueryFromType<T>,
    sig: string
  ) => Promise<WriteResponse<T>>;
  /**
   * NewID generates a fresh, safe, server-side generated ID.
   * @returns
   */
  NewID: () => Promise<{
    id: string;
    sig: string;
  }>;

  readonly _stats: { collect: number; refresh: number };
  readonly _endpoint: string;
}

export class APICache implements APICacheInterface {
  private _client: AnyCell<AuthClient>;
  readonly _endpoint: string;
  readonly _stats: { collect: number; refresh: number };
  private _converter: (v: unknown) => unknown;

  constructor(
    client: AnyCell<AuthClient>,
    convertFromNative: (v: unknown) => unknown,
    endpoint: string
  ) {
    this._client = client;
    this._stats = { collect: 0, refresh: 0 };
    this._converter = convertFromNative;
    this._endpoint = endpoint;
  }

  /**
   * Subscribe to a set of queries (currently unused).
   * @param ids
   * @returns
   */
  Subscribe = async (ids: string[]): Promise<{ id: string }> => {
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    const r = await c.postJSON(`${this._endpoint}/subscribe`, {
      id: ids.join(",")
    });
    return r.json();
  };

  /**
   * Refresh
   * @param ids
   * @param withData
   * @returns
   * @todo type each element
   */
  Refresh = async (
    ids: CacheQuery[],
    withData = false
  ): Promise<{
    time: number;
    cd: CachedData<keyof Definitions>[];
    miss?: CacheQuery[];
  }> => {
    if (!ids?.length) return;
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    const r = await c.postJSON(`${this._endpoint}/refresh`, {
      id: ids.join(","),
      tags: withData ? [TagWithData] : undefined
    });
    this._stats.refresh++;
    return this._converter(await r.json());
  };

  /**
   * Collect
   * @param ids
   * @returns
   * @todo type each element
   */
  Collect = async (
    ids: CacheQuery[]
  ): Promise<{
    cd: CachedData<keyof Definitions>[];
  }> => {
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    const r = await c.postJSON(`${this._endpoint}/collect`, {
      id: ids.join(",")
    });
    this._stats.collect++;
    return this._converter(await r.json());
  };

  /**
   * Write writes data in datacache.
   * @param json
   * @param sig
   * @param auth
   * @returns CachedData
   */
  Write = async <T extends WritableDataType>(
    json: string,
    sig: string, // FIXME: stricter type?
    auth?: string
  ): Promise<WriteResponse<T>> => {
    // const json = JSON.stringify({ ty, data });
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    const r = await c.postBODY(
      `${this._endpoint}/write`,
      `{"json":${json},"sig":${JSON.stringify(sig)}${
        auth ? `,"auth":${JSON.stringify(auth)}` : ""
      }}`
    );
    return r.json();
  };

  /**
   * Delete a data.
   * @param q
   * @param sig
   * @returns
   */
  Delete = async <T extends WritableDataType>(
    q: CacheQueryFromType<T>,
    sig: string // FIXME: stricter type?
  ): Promise<WriteResponse<T>> => {
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    const r = await c.postJSON(`${this._endpoint}/delete`, {
      id: q,
      sig
    });
    return r.json();
  };

  /**
   * NewID generates a safe, server-side fresh unique ID.
   * @returns
   */
  NewID = async (): Promise<{ id: string; sig: string }> => {
    const c = await this._client.get();
    if (c instanceof Error) throw c;
    return c.getJSON(`${this._endpoint}/new/id`);
  };
}
