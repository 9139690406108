import type { AnyCell, SheetProxy, ValueCell } from "@okcontract/cells";
import { OrgQuery, strip_datacache_prefix } from "@okcontract/coredata";
import {
  EDIT,
  NEW,
  defaultPatterns,
  objectDefinition,
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import type { Instance } from "./instance";

export const org_groups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "Organization information" }),
  proxy.new({ id: "con", l: "Contracts, tokens and chains" })
];

export const orgID =
  (
    instance: Instance,
    is_admin = false, // @todo single options
    mode = EDIT,
    optional = false
  ) =>
  (node: EditorNode, env: Environment): AnyCell<LabelledTypeDefinition> =>
    instance._proxy.new({
      label: "Organization",
      base: "string",
      optional,
      search: "org",
      pl: "Search your organization... (ex: compound)",
      hint: "Search for your organization", // or claim it
      // help: "To propose a new organization, type its desired id and click 'claim'",
      // def: mode === EDIT && !is_admin && UNAVAILABLE_DATA,
      def: mode === EDIT && !is_admin,
      lens: (v: ValueCell<string>) => {
        const org: ValueCell<string> = instance._proxy.new(
          undefined,
          "lens:org"
        );
        v.subscribe((_v) => {
          console.log("org set", _v);
          org.set(_v);
        });
        org.subscribe((_org) => {
          console.log("org value", _org);
          v.set(strip_datacache_prefix(_org));
        });
        return org;
      },
      validator: async (v: string) => {
        if (!v || is_admin) return;
        const org = await instance._core.CacheOnce(OrgQuery(v));
        // const parent = await instance._proxy.get(node.parent).get();
        const from = await instance._core.WalletID.get();
        if (from instanceof Error) return "Unknown address";
        const addr = from.toString();
        // console.log({ org, from, parent, node });
        if (
          org?.from.toString() === addr ||
          org?.mem?.find((addr) => addr.toString() === from.toString()) !==
            undefined
        )
          return;
        return "Only members of the organization can create contracts";
      }
    });

export const orgSchema =
  (instance: Instance, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(
      instance._proxy,
      {
        from: {
          label: "Owner",
          locked: true,
          base: "string",
          gr: "id",
          isAddress: true
        },
        id: {
          label: "Unique organization ID",
          locked: mode === EDIT,
          base: "string",
          pattern: defaultPatterns.orgid,
          gr: "id",
          pl: "compound",
          search: mode === EDIT ? "org" : undefined,
          hint: mode === NEW ? "Unique organization identifier" : undefined,
          help:
            mode === NEW
              ? "Should be a unique identifier representing your org"
              : undefined,
          validator: async (v: string) => {
            // TODO: refactor with org / contract / token
            if (!v) return;
            const org = await instance._core.CacheOnce(OrgQuery(v));
            if (org?.id && mode === NEW) {
              return `Unique ID ${v} already exists`;
            }
          }
        },
        name: {
          label: "Name",
          base: "string",
          gr: "id",
          pl: "Compound Finance",
          hint: "Your organization name"
          // optional: true
        },
        logo: {
          label: "Logo",
          hint: "Organization logo URL or id",
          base: "string",
          gr: "id",
          isImg: true,
          pl: "https://... or ipfs://..."
          // optional: true
        },
        desc: {
          label: "Description",
          hint: "A description of your organization",
          pl: "A description of your organization...",
          base: "string",
          long: true,
          gr: "id",
          optional: true,
          icon: "bars-left-o"
        },
        mem: {
          label: "Members",
          hint: "Members will have write access to the organization tokens and contracts",
          array: () =>
            instance._proxy.new({
              label: "Member",
              base: "string",
              isAddress: true
            }),
          // @todo fix second schema validation on array parent which is not required
          optional: true,
          gr: "id",
          icon: "users-o"
        },
        chans: {
          label: "Social networks",
          hint: "Website, FB...",
          gr: "id",
          array: () => instance._proxy.new({ name: "Channel" }),
          optional: true,
          icon: "share-o"
        },
        foreign: {
          label: "Crypto platform identifiers",
          gr: "id",
          array: () => instance._proxy.new({ name: "ForeignOrgId" }),
          optional: true,
          icon: "chartpie-o"
        },
        ch: {
          label: "Chains",
          gr: "con",
          array: () =>
            instance._proxy.map([instance._core.Chains], (chains) => ({
              label: "Chain",
              enum: Object.keys(chains).map((ch) => ch.substring(1))
            })),
          optional: true,
          icon: "link"
        },
        toks: {
          label: "Tokens",
          array: () => instance._proxy.new({ name: "TokenID" }),
          gr: "con",
          optional: true,
          icon: "coins-o"
        },
        cons: {
          label: "Contracts",
          array: () => instance._proxy.new({ name: "ContractID" }),
          gr: "con",
          optional: true,
          icon: "document-o"
        }
      },
      "Organization"
    );
