<script lang="ts">
  import { ValueCell } from "@okcontract/cells";
  import type { DataEditor, EditorNode } from "@okcontract/fred";
  import { Rational } from "@okcontract/lambdascript";
  import { formatBig, type Instance } from "@okcontract/sdk";
  import {
    Range,
    ThemeBackgroundTransparent,
    ThemeText,
    getTheme
  } from "@okcontract/uic";

  import type { EditorOptions } from "../types";

  export let editor: DataEditor;
  export let instance: Instance;
  const proxy = instance._proxy;

  export let node: EditorNode;
  const definition = node.definition;
  const value = proxy.get(node.value);

  // @todo remove
  export let convert;
  export let options: EditorOptions = {};
  $: disabled =
    !!options?.view ||
    !(value instanceof ValueCell) ||
    ("locked" in $definition && $definition?.locked);

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  $: def =
    ($definition && !($definition instanceof Error) && $definition) ||
    undefined;
  $: label = def?.label;
  // @todo check fine with large values?
  // @todo can we use native input with bigint?
  $: vBig =
    $value instanceof Rational
      ? BigInt($value.toBigInt())
      : typeof $value === "number" || typeof $value === "bigint"
        ? BigInt($value)
        : typeof $value === "string" // @todo should not happen
          ? BigInt($value)
          : BigInt(0);
  // @ts-expect-error optional property
  $: min = def?.min?.toString();
  $: max =
    "max" in $definition && $definition.max
      ? typeof $definition.max === "number"
        ? BigInt($definition.max)
        : BigInt($definition.max.toBigInt())
      : undefined;

  // $: console.log("EditorNumber", { $value, vBig, min, label });
</script>

{#if $definition instanceof Error}
  Error at definition {$definition}
{:else if max !== undefined}
  {@const symb = ("unit" in def && def.unit) || ""}
  {@const required = !("optional" in def && def.optional)}
  <!-- @todo decimals is never Rational? -->
  {@const decimals =
    ("decimals" in def &&
      (def.decimals instanceof Rational
        ? def.decimals.toBigInt()
        : BigInt(def.decimals || 0n))) ||
    0n}
  <Range
    {label}
    {required}
    {symb}
    {decimals}
    value={vBig}
    {max}
    {disabled}
    on:input={(ev) => editor.update(node, new Rational(ev.detail))}
  />
{:else if def}
  {#if disabled}
    <div class="px-1 flex items-center gap-1">
      {formatBig(vBig, 0)}
      {#if "unit" in def && def.unit}
        <div
          class="label-text opacity-70 {theme.dark(
            $compiledTheme,
            'input-white-alpha',
            'input-black-alpha',
            ''
          )}"
        >
          {def.unit}
        </div>
      {/if}
    </div>
  {:else}
    {@const placeholder = "pl" in def ? def.pl : null}
    {@const step = "step" in def ? def.step : 1}
    <label
      class="input input-bordered flex items-center {disabled
        ? 'input-disabled'
        : ''} {theme.dark(
        $compiledTheme,
        'input-white-alpha',
        'input-black-alpha',
        ''
      )}"
      style={theme.apply(
        $compiledTheme,
        [ThemeText],
        [ThemeBackgroundTransparent]
      )}
    >
      <!-- @todo big numbers... -->
      <input
        {disabled}
        value={Number(vBig)}
        {placeholder}
        title={label}
        type="number"
        {step}
        {min}
        max={("max" in $definition && String($definition.max)) || undefined}
        on:input={(ev) => editor.update(node, convert(ev, value, $definition))}
        class="grow w-10"
      />
      {#if "unit" in def && def.unit}
        <div
          class="label-text opacity-70 {theme.dark(
            $compiledTheme,
            'input-white-alpha',
            'input-black-alpha',
            ''
          )}"
        >
          {def.unit}
        </div>
      {/if}
    </label>
  {/if}
{/if}
