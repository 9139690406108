// @todo move to app

import type { Connector } from "@wagmi/connectors";

import {
  link_of_cache_query,
  type CacheDataFromType,
  type CacheQueryFromType,
  type CachedData,
  type WritableDataType
} from "@okcontract/coredata";
import {
  sign_message,
  type AnyCore,
  type CoreExecution
} from "@okcontract/sdk";
import { write_message } from "@scv/cache";
import { navigate } from "@scv/routing";
import { stripDefaultValues } from "@scv/utils";

import { errors } from "./store";

/**
 * fail dispatches an error to the errors Store and returns it.
 * @param err
 * @returns
 */
export const fail = (err: Error) => {
  errors.set(err);
  // console.error(err);
  return err;
};

/**
 * write_data writes a coredata to datacache.
 * @param ty type of data to write (in WritableDataType, e.g. "contract")
 * @param data raw data
 * @param other function to update the local client cache after success
 */
export const write_data = async <T extends WritableDataType>(
  core: CoreExecution<Connector>,
  ty: T,
  data: CacheDataFromType<T>,
  /**
   * other updates other cache data locally and immediately
   * (e.g. to display upvote counter update as the user clicks,
   * otherwise the previous value would be cached for 30/60s)
   */
  other?: (cd: CachedData<T>) => void | Promise<void>,
  auth?: string,
  stay = false
) => {
  const clean = stripDefaultValues(data);
  const msg = write_message(ty, clean);
  const sig = await sign_message(core.WalletClient, msg);
  try {
    const cd = await core.Cache.write(ty, msg, sig, auth);
    // console.log({ write: cd.q });
    // no need to wait?
    if (other !== undefined) other(cd);
    const url = link_of_cache_query(cd.q);
    if (!stay && url) setTimeout(() => navigate({ to: url }), 250);
  } catch (error) {
    return fail(error);
  }
};

export const delete_data = async <T extends WritableDataType>(
  core: CoreExecution<Connector>,
  ty: T,
  q: CacheQueryFromType<T>,
  other?: (cd: CachedData<T>) => void | Promise<void>
) => {
  const sig = await sign_message(core.WalletClient, `delete:${q}`);
  try {
    const cd = await core.Cache.delete(ty, q, sig);
    if (other !== undefined) other(cd);
    const url = link_of_cache_query(cd.q, "delete");
    if (url) setTimeout(() => navigate({ to: url }), 250);
  } catch (error) {
    return fail(error);
  }
};

/**
 * @todo this becomes more complex
 * @todo move to coredata, this is specific to the use case
 **/
export const remove_from_part_wid_query = (core: AnyCore) => {
  const queryCache = core.Cache;
  const part_queries = queryCache.keys.filter((k) =>
    k?.startsWith("part:widget")
  );
  // @todo this create a new WrappedCell
  for (const q of part_queries)
    if (queryCache.cell(q)) {
      // @todo remove local cache
      // queryCache.delete("widget", query);
      // cache[query].q = all?.[query]?.data?.q.filter((v) => v !== wQuery);
    }
};
