const DEV = false;

import { bases } from "multiformats/basics";
import { CID, type MultibaseDecoder } from "multiformats/cid";

import type { AnyCell } from "@okcontract/cells";

const gateways = [
  "https://$$HASH$$.ipfs.dweb.link/$$PATH$$",
  "https://gateway.lighthouse.storage/ipfs/$$HASH$$/$$PATH$$",
  "https://gateway.mesh3.network/ipfs/$$HASH$$/$$PATH$$"
];

// @todo use?
// const video_gateway = "https://gateway.lighthouse.storage/ipfs/$$HASH$$";
const basesByPrefix = Object.keys(bases).reduce(
  (acc, curr) => {
    acc[bases[curr].prefix] = bases[curr];
    return acc;
  },
  {} as { [key: string]: MultibaseDecoder<string> }
);

/**
 * convert_CID converts CIDv0 to v1 (as required by some gateways).
 * @param s
 * @returns
 * @todo this function alone adds 20kb to the build output...
 */
export const convert_CID = (s: string) => {
  const prefix = s.substring(0, 2);
  const base = basesByPrefix[prefix];
  const cid = CID.parse(s, base);
  return cid.toV1().toString(bases.base32);
};

/**
 * ipfs_rewrite rewrites an IPFS URL using gateways.
 * @param url
 * @returns
 * @todo detect browsers (e.g. Brave) that support IPFS directly
 * @todo add multiple gateways (e.g https://ipfs.io/ipfs/...) or
 * @todo write an image component that tries multiple gateways
 * @todo correct CIDv0 case?
 * @todo manage
 * @todo convert to cells
 */
export const ipfs_rewrite = (url: AnyCell<string>, gw = 0) =>
  url.map((_url) => {
    try {
      if (!_url?.startsWith("ipfs://") && !_url?.startsWith("https://ipfs.io"))
        return _url;
      const path = _url
        .replace("https://ipfs.io/ipfs/", "")
        .replace("ipfs://", "")
        .split("/");
      const http = gateways[gw]
        .replace("$$HASH$$", gw === 0 ? convert_CID(path[0]) : path[0])
        .replace("$$PATH$$", path.length > 1 ? path[1] : "");
      return http;
    } catch (error) {
      DEV && console.log({ error });
      return _url;
    }
  });

export const ipfs_rewrite_old = (url: string, gw = 0) => {
  try {
    if (!url?.startsWith("ipfs://") && !url?.startsWith("https://ipfs.io"))
      return url;
    const path = url
      .replace("https://ipfs.io/ipfs/", "")
      .replace("ipfs://", "")
      .split("/");
    const http = gateways[gw]
      .replace("$$HASH$$", gw === 0 ? convert_CID(path[0]) : path[0])
      .replace("$$PATH$$", path.length > 1 ? path[1] : "");
    return http;
  } catch (error) {
    DEV && console.log("ipfs_rewrite_old", { error });
    return url;
  }
};
