import type { SheetProxy, Unsubscriber, WrappedCell } from "@okcontract/cells";
import type {
  CacheQuery,
  CachedData,
  TypeFromCacheQuery
} from "@okcontract/coredata";
import { debouncer } from "@okcontract/multichain";

import type { DataOf, QueryCache } from "./queryCache";

// @todo independent types
export class GlobalSubscriber<Query extends CacheQuery> {
  private _cache: QueryCache;
  private _sub: Map<Query, number>;
  private _batch: Set<Query>;
  private _deb: <T>(cb: (v: T) => void | Promise<void>, v: T) => void;

  constructor(cache: QueryCache, delay = 20) {
    this._cache = cache;
    this._sub = new Map();
    this._batch = new Set();
    this._deb = debouncer(delay);
  }

  cell<Q extends Query>(q: Q, proxy?: SheetProxy): WrappedCell<DataOf<Q>> {
    return this._cache.cell(q, proxy);
  }

  /**
   * once retrieves a single query value.
   * @todo this is specific to CacheQuery
   * @param q
   * @deprecated not used anymore
   * @returns
   */
  once = async <
    Q extends Query,
    CD = CachedData<TypeFromCacheQuery<Q>>["data"]
  >(
    q: Q,
    { throwable: boolean } = { throwable: false } // @todo implement?
  ): Promise<CD | null> => {
    // Find or create the cell
    const cell = this.cell(q);
    // Launch the update queue.
    this._append([q]);
    // console.log({ q, once: "start" });
    return new Promise((resolve, reject) => {
      // biome-ignore lint/style/useConst: need reference
      let uns: Unsubscriber;
      uns = cell.subscribe((v) => {
        // console.log("once", { v });
        // we wait
        if (typeof v === "object" && v !== null && "wait" in v && v.wait)
          return;
        if (v !== undefined) {
          const out: CD | null =
            typeof v === "object" && v !== null && "never" in v && v.never
              ? null
              : (v as CD);
          // if the data is expired, wait for the update
          const exp = this._cache._expiry.find(q);
          if (exp && Date.now() > exp * 1000) return;

          // Since the subscription can return immediately when
          // a data is already available (e.g. the second time we
          // ask for the same query), we need to delay the unsubscriber
          // call to make sure the function is defined.
          // if (typeof uns === "function") uns();
          queueMicrotask(() => uns());
          // setTimeout(() => uns(), 50);
          this._remove(new Set([q]) as Set<Query>);
          resolve(out);
        }
      });
    });
  };

  /**
   * add queries, must be called only by local subscriptions
   * @param queries
   * @returns
   * @version previously subscribe
   */
  private _add_list = async (queries: Query[]) => {
    if (!queries) return;
    for (const x of queries) {
      const prev = this._sub.get(x) || 0;
      if (!prev) {
        this._cache.activate(x);
      }
      this._sub.set(x, prev + 1);
    }
  };

  /**
   * remove queries, must be called only by local subscriptions
   * @param queries
   * @returns
   * @version previously unsubscribe
   *
   * @todo should be a list
   */
  _remove = async (queries: Set<Query>) => {
    if (!queries) return;
    for (const x of queries) {
      const c = this._sub.get(x) || 0;
      if (c) {
        if (c === 1) {
          this._cache.activate(x, false);
        }
        this._sub.set(x, c - 1);
      }
    }
  };

  /**
   * _add_batch should only be used with debounced `_append`.
   */
  private _add_batch = async (_) => {
    const adding = Array.from(this._batch);
    // console.log({ at: Date.now(), batch: adding });
    this._batch.clear();
    this._add_list(adding);
    // FIXME: only if we don't have them already in the local cache?
    // we only _refresh now?
    await this._cache._refresh(adding);
  };

  /**
   * append new queries from a caller.
   * each caller is responsible for calling `delete` with the same
   * values on destroy.
   * @param qs
   * @todo batch should be array, not set otherwise we may aggregate
   * 2 components that call at same time as single subscription.
   */
  _append(qs: Query[]) {
    // console.log({ at: Date.now(), append: qs });
    for (const q of qs) this._batch.add(q);
    this._deb(this._add_batch, null);
  }
}
