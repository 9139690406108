<script lang="ts">
  import type { TransactionReceipt } from "viem";

  import { type AnyCell } from "@okcontract/cells";
  import type { Chain } from "@okcontract/multichain";
  import type { SentTransaction } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { Collapsible, Icon, InlineDescription } from "@okcontract/uic";

  import Receipt from "./Receipt.svelte";

  export let instance = newInstance("TXFees");

  const proxy = instance._proxy;

  export let gas_amount: bigint;
  export let gas_price: number;
  export let currency_price: number;
  export let currency: string;
  export let service: number = 0;
  export let fee_name: "Estimated" | "Paid" = "Estimated";
  export let receipts: AnyCell<TransactionReceipt[]> = proxy.new([]);
  export let txs: SentTransaction[] = undefined;
  export let chain: AnyCell<Chain>;

  /**
   * @see https://docs.ethers.io/v5/api/utils/display-logic/
   */
  const txPrice = (v: bigint) =>
    v &&
    gas_price &&
    currency_price &&
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format((Number(v) * gas_price * currency_price) / 1e9 + service);

  const totalReceipts = receipts.map((_receipts) =>
    _receipts.reduce((acc, receipt) => receipt?.gasUsed + acc, BigInt(0))
  );
  const total = totalReceipts.map((_total) => txPrice(_total || gas_amount));
</script>

<Collapsible {proxy} style="transparent" size="sm" isOpen={proxy.new(false)}>
  <span slot="heading" class="flex gap-1 items-center text-sm">
    <Icon name="fee-o" opacity="50" />
    <span class="font-medium opacity-70">{fee_name} fees: </span>
    {#if $total}
      <span class="font-bold"
        >{$total instanceof Error ? "??" : $total} USD</span
      >
    {/if}
  </span>
  <div class="flex flex-col gap-1">
    {#if !txs}
      <InlineDescription title="Gas:" size="sm">
        <div>{gas_amount}</div>
      </InlineDescription>
      <InlineDescription title="Price:" size="sm">
        {gas_price} gwei
      </InlineDescription>
      <InlineDescription title="Currency:" size="sm">
        1 {currency} =
        {currency_price
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(currency_price)
          : "??"}
        USD
      </InlineDescription>
      <InlineDescription title="Service:" size="sm"
        >{service} USD</InlineDescription
      >
    {:else if $receipts instanceof Error}
      Receipts error {$receipts}
    {:else}
      {#each $receipts as receipt, i}
        <Receipt
          name="Transaction #{i + 1}: {txs?.[i]?.fnName}"
          {receipt}
          tx={txs?.[i]}
          {service}
          {currency}
          {chain}
        ></Receipt>
      {/each}
    {/if}
    <div class="font-bold opacity-100">
      <InlineDescription title="Total:" size="sm">
        {$total instanceof Error ? "??" : $total} USD
      </InlineDescription>
    </div>
  </div>
</Collapsible>
