import type { AbiFunction } from "abitype";

import type { ABIMethod } from "@okcontract/coredata";
import { Program, type Environment } from "@okcontract/lambdascript";

import { ABIMethodToASTMap, getArgName } from "./abi";
import { EnvKeyValue } from "./keys";
import { AddressExtension } from "./lambdascript";

/**
 * createProgram creates the program for a given method (step)
 * @param step the current step (method) we create the program on
 * @param abix ABIExtra is used to get extra definitions
 * @returns the program for the given method / step
 */
export const createProgram = async (abiMethod: ABIMethod) => {
  const definitions = await ABIMethodToASTMap(abiMethod);
  return new Program(definitions, {
    parseOptions: { ext: [AddressExtension] }
  });
};

/**
 * getMissingInputs returns the missing inputs for a given program
 * @param program the program where we check the missing inputs
 * @param abiFunc the AbiFunc we compare against
 * @param env
 * @returns
 *
 * @todo maybe no need to re-check in env for existing value
 */
export const getMissingInputs = (
  program: Program,
  abiFunc: AbiFunction,
  env: Environment,
  cases: string[] = []
) => {
  // get missing inputs from the program to execute the ABI function
  const missing = getRequiredValues(program, abiFunc, env, cases);
  // if $value isn't present in program and function is payable, add it to reqInputs
  if (
    !program.get(EnvKeyValue) &&
    !missing?.includes(EnvKeyValue) &&
    abiFunc?.stateMutability === "payable"
  )
    missing.push(EnvKeyValue);
  // console.log("okTX getMissingInputs", { missing });
  return missing;

  // filter out values that are either resolved in methodEnv or if $value is defined in preProg
  // return missing.filter((req) => {
  //   if (env.has(req)) return false;
  //   if (req === "$value" && program.get("$value")) return false;
  //   return true;
  // });
};

/**
 * getRequiredValues returns a program missing required values
 * @param prog
 * @param fn
 * @param req
 * @returns
 *
 * @todo remove optional req ?
 * @todo do we need the filter on $ ?
 */
export const getRequiredValues = (
  prog: Program,
  fn: AbiFunction,
  env: Environment,
  cases: string[]
) => {
  const inputs = fn?.inputs.map((pt, i) => getArgName(pt, i)) || [];
  const originalCase = Object.fromEntries(
    // inputs have priority
    [...cases, ...inputs].map((s) => [s.toLowerCase(), s])
  );
  const missing = (key: string) => {
    console.log("getRequiredValues:missing", { key, env, prog });
    return key;
  };
  return (
    prog
      .dependencies(
        inputs.map((s) => s.toLowerCase()),
        env
      )
      // @todo filter "@"?
      .filter((r) => !r?.startsWith("$"))
      .map(
        (s) =>
          originalCase[s] ||
          // The value may come from the Environment
          env.case(s) ||
          missing(s)
      )
  );
};
