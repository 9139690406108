type AsyncReducer<T, U> = (
  accumulator: U,
  value: T,
  index: number,
  array: T[]
) => Promise<U>;

export const asyncReduce = async <T, U>(
  array: T[],
  reducer: AsyncReducer<T, U>,
  initialValue: U
): Promise<U> => {
  let accumulator = initialValue;
  for (let i = 0; i < array.length; i++)
    accumulator = await reducer(accumulator, array[i], i, array);
  return accumulator;
};
