<script lang="ts">
  import type { TokenQueryType } from "@okcontract/coredata";

  import { newInstance } from "./core";

  export let address: TokenQueryType | `0x${string}`;

  const instance = newInstance();
  const proxy = instance._proxy;

  const balance = instance._formatBalance(
    // @ts-expect-error walletIDType
    instance._core.WalletID,
    instance._core.CurrentChain,
    proxy.new(address)
  );
</script>

{#if !($balance instanceof Error)}
  {$balance}
{/if}
