import type { Abi, AbiFunction } from "abitype";
import { formatAbiItem } from "viem/utils";

import {
  ABIMethodAdm,
  type ABIExtra,
  type ABIMethod
} from "@okcontract/coredata";
import type { Environment, ValueDefinition } from "@okcontract/lambdascript";
import { ADMIN, type Role } from "@scv/auth";

/**
 * @todo wrong type on purpose to enforce abstract datatypes...
 */
export type UniqueMethodName = string;

/**
 * extractFunctionName shortens the method name when unambiguous.
 * @param name
 * @returns
 */
export const extractFunctionName = (name: string): UniqueMethodName =>
  name?.includes("(")
    ? (name.substring(0, name.indexOf("(")) as UniqueMethodName)
    : (name as UniqueMethodName);

export type ContractFunctionValues = [
  AbiFunction[], // values
  AbiFunction[], // reads
  AbiFunction[] // writes
];

/**
 * isAdminReserved check if an ABIMethod function is admin reserved
 * @param v
 * @returns
 */
export const isAdminReserved = (m: ABIMethod): boolean => !!m?.[ABIMethodAdm];

/**
 * isWhitelisted return whether a method is whitelisted
 * for a given ABIExtra ref
 */
export const isWhitelisted = (abix: ABIExtra, m: string) =>
  !!abix?.methods?.[m];

export const filterABIMethods = (
  role: Role,
  VRW: ContractFunctionValues,
  pivotEnv: ValueDefinition[],
  abix: ABIExtra
) => {
  const RW = [...(VRW?.[1] || []), ...(VRW?.[2] || [])];
  const is_adm = role === ADMIN;
  const methods = RW.filter((method) =>
    is_adm ? true : !isAdminReserved(abix?.methods?.[method.name])
  ).filter(
    (method) => pivotEnv.find(([k, _v, _t]) => k === method.name) === undefined
  );
  return methods
    .map((method) => {
      const name = formatAbiItem(method);
      const short = extractFunctionName(name);
      const isDuplicate =
        methods.filter((fn) => {
          return extractFunctionName(formatAbiItem(fn)) === short;
        })?.length > 1;
      return [isDuplicate ? name : short, method] as [string, AbiFunction];
    })
    .sort();
};

/**
 * splitABI splits the ABI between:
 * - values that are constant and no input (V)
 * - read (R)
 * - write (W)
 * @param abi
 * @returns
 */
export const splitABI = (abi: Abi): ContractFunctionValues => {
  return abi.reduce(
    ([V, R, W], fn) => {
      if (fn.type !== "function") return [V, R, W];

      const isViewOrPure =
        fn.stateMutability === "view" || fn.stateMutability === "pure";

      return fn.inputs.length === 0 && isViewOrPure
        ? [[...V, fn], R, W]
        : isViewOrPure
          ? [V, [...R, fn], W]
          : [V, R, [...W, fn]];
    },
    [
      [] as AbiFunction[],
      [] as AbiFunction[],
      [] as AbiFunction[]
    ] as ContractFunctionValues
  );
};
