import { MinHeap } from "./minHeap";

export type ExpiryOptions = {
  now: () => Promise<number> | number;
};

const defaultExpiryOptions = {
  now: (): number | Promise<number> => Date.now()
};

export class ExpiryTracker<K> {
  private _map = new Map<K, number>();
  private _queue = new MinHeap<{ key: K; expiry: number }>(
    (a, b) => a.expiry - b.expiry,
    ({ key }) => key
  );
  readonly _options: ExpiryOptions;

  constructor(options = defaultExpiryOptions) {
    this._options = options;
  }

  setExpiry(key: K, expiry: number) {
    this._map.set(key, expiry);
    this._queue.enqueue({ key, expiry });
  }

  find(key: K) {
    return this._map.get(key);
  }

  delete(key: K) {
    // @todo find expiry and use delete?
    this._queue.deleteByKey(key);
  }

  async hasExpiredKeys() {
    const now = await this._options.now();
    const top = this._queue.peek();
    if (!top) return false;
    return top.expiry < now;
  }

  async takeExpiredKeys(): Promise<K[]> {
    const now = await this._options.now();
    const expiredKeys: K[] = [];
    while (!this._queue.isEmpty() && this._queue.peek().expiry < now) {
      const item = this._queue.dequeue();
      expiredKeys.push(item.key);
      this._map.delete(item.key);
    }
    return expiredKeys;
  }
}
