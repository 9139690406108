<!-- @todo rename to EditorGroups -->
<script lang="ts">
  import {
    DataEditor,
    type EditorNode,
    type NodeObject
  } from "@okcontract/fred";
  import type { Instance } from "@okcontract/sdk";

  import type { EditorOptions } from "../types";
  import EditorGroup from "./EditorGroup.svelte";

  export let instance: Instance;
  export let editor: DataEditor;
  export let node: EditorNode & NodeObject; // AnyCell<EditorNode>;
  export let options: EditorOptions = {};

  const groups = editor.groups(node);

  // const def = node.definition;
  // $: console.log("StoreEdit", { node });
  // $: console.log("StoreEdit", { $groups });
</script>

{#if !($groups instanceof Error)}
  {#each $groups || [] as group}
    <!-- @todo keyed? (group[0].id) -->
    <!-- {@const _ = console.log({ group })} -->
    <EditorGroup
      {instance}
      {editor}
      {node}
      {group}
      {options}
      groups={$groups.length}
    />
  {/each}
{/if}
