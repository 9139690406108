<script lang="ts">
  import { Icon, Tooltip, type TooltipPosition } from "@okcontract/uic";

  export let hintText: string;
  export let pos: TooltipPosition = "right";
</script>

<Tooltip label={hintText} {pos}>
  <Icon name="info-o" size="xs" />
</Tooltip>
