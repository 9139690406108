<script lang="ts">
  import {
    BasicTokenKind,
    type NFTQueryType,
    type TokenQueryType
  } from "@okcontract/coredata";
  import { Balance, newInstance } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { Logo, Upvote } from "@scv/dcuic";

  export let instance = newInstance("TokenSnippet");
  export let q: TokenQueryType | NFTQueryType;
  export let small: boolean = false;
  export let upvote: boolean = false;
  export let balance: boolean = false;

  const local = instance._local;
  const proxy = instance._proxy;
  const core = instance._core;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const currentChain = core.CurrentChain;

  const qCell = proxy.new(q, `TokenSnippet.qCell:${q}`);
  $: qCell.set(q);

  const token = local.unwrappedCell(qCell, "token");
  const addrLists = proxy.map([token, currentChain], (_token, _chain) =>
    _token.addr.filter((_evm) => _evm.chain === _chain)
  );
  const label = token.map((_token) =>
    _token.ty === BasicTokenKind ? _token.symbol : _token?.name
  );
  const title = token.map((_token) => _token.name);

  const getAddr = () => addrLists[0].addr as `0x${string}`;
</script>

<div class="flex grow items-center justify-between">
  <div class="flex gap-1 items-center">
    {#if !($token instanceof Error)}
      <div class="shrink-0">
        <Logo
          logo={$token && ($token.logo || $token.id)}
          size={small ? "sm" : "md"}
          remote={instance._core.AppID === "widget"}
        />
      </div>
    {/if}
    {#if $label && !($title instanceof Error) && !($label instanceof Error)}
      <div
        class="flex-1 {theme.dark(
          $compiledTheme,
          'text-white',
          'text-black',
          'text-base-content'
        )} {small ? 'text-sm' : 'text-sm font-semibold'}"
      >
        <span title={$title} class={$label.includes(" ") ? "" : "uppercase"}>
          {$label}
        </span>
        <slot name="tag" />
      </div>
    {/if}
    {#if !($addrLists instanceof Error) && $addrLists?.length > 0 && balance}
      <span class="font-medium"><Balance address={getAddr()} /></span>
    {/if}
  </div>
  {#if upvote}
    <Upvote {q} style="sm" />
  {/if}
</div>
