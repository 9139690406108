import type { WalletClient } from "viem";

import type { AnyCell, SheetProxy, ValueCell } from "@okcontract/cells";
import type { Address } from "@okcontract/multichain";

import { sign_message } from "./wallet";

export const LOADING = "wait" as const;
export const SUCCESS = "success" as const;
export const WARNING = "warning" as const;
export type UploadStatus =
  | typeof LOADING
  | typeof SUCCESS
  | typeof WARNING
  | undefined;

export interface LighthouseUploadMeta {
  Name: string;
  Hash: string;
  Size: string;
}

export type LighthouseUpload = {
  data: ValueCell<ArrayBuffer | string | null>;
  meta: ValueCell<LighthouseUploadMeta>;
  status: ValueCell<UploadStatus>;
  url: ValueCell<string>;
};

export type LighthouseEnv = Map<string, LighthouseUpload>;

const lighthouseAPI = "https://api.lighthouse.storage";
const lighthouseNode = "https://node.lighthouse.storage";

export class Lighthouse {
  private proxy: SheetProxy;
  /** api token */
  private token: ValueCell<string>;
  /** the wallet client used to sign message */
  private walletClient: AnyCell<WalletClient>;
  /** if we its lighthouse authenticated */
  readonly isAuth: ValueCell<boolean>;

  /** the lighthouse environment (key is path - value is lighthouse upload data)*/
  private _env: LighthouseEnv;

  constructor(proxy: SheetProxy, walletClient: AnyCell<WalletClient>) {
    // console.log("new Lighthouse");
    this.proxy = proxy;

    this.walletClient = walletClient;

    this.token = proxy.new(undefined, "Lighthouse.key");
    this.isAuth = proxy.new(false, "Lighthouse.isAuth");
    this.token.subscribe((_key) => this.isAuth.set(!!_key));

    this._env = new Map();
  }

  private set(path: string, lh: LighthouseUpload) {
    this._env.set(path, lh);
  }

  private unset(path: string) {
    if (this._env.hasOwnProperty(path)) this._env.delete(path);
  }

  get(path: string) {
    return this._env?.get(path) || null;
  }

  /**
   * Authenticates a user using their wallet ID.
   * On successful verification, it stores the access token.
   *
   * @param {Address} walletID - The user's wallet ID for authentication.
   * @returns {Promise<boolean>} - True if authenticated, false otherwise.
   */
  async authenticate(walletID: Address): Promise<boolean> {
    const msg = await fetch(
      `${lighthouseAPI}/api/auth/get_message?publicKey=${walletID.toString()}`
    );
    const text = JSON.parse(await msg.text());
    const payload = {
      publicKey: walletID.toString(),
      signedMessage: await sign_message(this.walletClient, text)
    };
    const response = await fetch(`${lighthouseAPI}/api/auth/verify_signer`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      method: "POST",
      body: JSON.stringify(payload)
    });
    const json = await response.json();

    if (!json?.accessToken) return false;
    this.token.set(json.accessToken);
    return true;
  }

  /**
   * Uploads and pin a document via Lighthouse.storage to IPFS.
   * Also update lighthouse data cells (data and meta)
   * @param data
   * @returns {Promise<LighthouseUploadMeta>} - True if upload succeed else false
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
   * @todo add @types/mime-types for mime
   */
  async upload(
    path: string,
    data: string | ArrayBuffer,
    mime?: string
  ): Promise<{ success: boolean; meta?: LighthouseUploadMeta; error?: Error }> {
    const lighthouseData = this.get(path);
    const token = await this.token.get();
    const formData = new FormData();
    formData.append(
      "file",
      typeof data === "string"
        ? data
        : new Blob([data], mime ? { type: mime } : undefined)
    );

    try {
      const meta = await (
        await fetch(`${lighthouseNode}/api/v0/add`, {
          headers: {
            "Mime-Type": mime ? mime : "text/plain",
            Authorization: `Bearer ${token}`,
            Encryption: "false"
          },
          method: "POST",
          body: formData
        })
      ).json();
      if (!meta) {
        lighthouseData.status.set(WARNING);
        return { success: false, error: new Error("failed to upload") };
      }

      lighthouseData.data.set(data);
      lighthouseData.meta.set(meta);
      lighthouseData.status.set(SUCCESS);

      return { success: true, meta };
    } catch (error) {
      lighthouseData.status.set(WARNING);
      return { success: false, error };
    }
  }

  /**
   * Add a new Lighthouse reference in environment
   * @param path - The path at which the data will be added
   * @param url - The new url
   */
  addNew(path: string, url: string) {
    const lh = this.get(path);
    if (!lh) {
      const newLh: LighthouseUpload = {
        url: this.proxy.new(url || null),
        data: this.proxy.new(null),
        status: this.proxy.new(null),
        meta: this.proxy.new(null, "lighthouse.addNew.meta")
      };
      this.set(path, newLh);
      return;
    }
    lh.url.set(url);
  }

  /**
   * Unset all Lighthouse cell and remove lighthouse data
   * from the env for a given path
   * @param path
   */
  remove(path: string) {
    const lh = this.get(path);
    if (!lh) return;

    lh.data.set(null);
    lh.meta.set(null);
    lh.url.set(null);
    lh.status.set(null);

    return this.unset(path);
  }
}
// lighthouse_upload
