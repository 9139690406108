<script lang="ts">
  import type { ValueCell } from "@okcontract/cells";
  import { LOADING_WALLET } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import {
    Button,
    Dropdown,
    Icon,
    InlineHelpText,
    getTheme
  } from "@okcontract/uic";
  import { config_store } from "@scv/app";
  import { ADMIN, GUEST, WHITELISTED } from "@scv/auth";
  import { truncated_addr } from "@scv/utils";

  import UserMenuWalletRow from "./UserMenuWalletRow.svelte";

  export let instance = newInstance("WalletAccounts");
  export let open: ValueCell<boolean>;
  export let small = false;
  export let showConnect = true;
  export let closeOnSelect = false;
  export let size: "xs" | "sm" | "md" = "sm";
  export let roles = false;

  const core = instance._core;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const role = core.Role;
  const isWidgetConfig = config_store.map(
    (config) => config.AppID === "widget"
  );
  const name = core.WalletName;
  const connectedWallets = core.ConnectedWallets;
  const wantedWalletAccount = core.WantedWalletAccount;
  const connectorAccount = core.ConnectorAccount;
  const isVerified = core.IsVerified;
  const loading = core.IsSwitchingAccount;
  const noWallet = core.IsNoWallet;

  let buttonElement: HTMLElement;
</script>

{#if $wantedWalletAccount}
  {#if !($connectedWallets instanceof Error)}
    <Dropdown
      style="bottom-end"
      size={roles ? "md" : "sm"}
      {open}
      {buttonElement}
      on:close={() => open.set(false)}
    >
      <div slot="action">
        <button
          tabindex="0"
          bind:this={buttonElement}
          class="btn btn-{size} rounded-box {theme.dark(
            $compiledTheme,
            'btn-white-alpha',
            'btn-black-alpha',
            'btn-ghost'
          )}"
          on:click={() => open.update((_open) => !_open)}
        >
          {#if ($loading === undefined || $loading === true) && $noWallet !== true}
            <span class="loading loading-spinner loading-xs"></span>
          {:else if $isVerified}
            {#if $role === ADMIN}
              <Icon name="user" size="xs" />
            {:else if $role === WHITELISTED}
              <Icon name="user-o" size="xs" />
            {:else if $role === GUEST}
              <Icon name="wallet" size="xs" />
            {:else}
              <Icon name="wallet-o" size="xs" />
            {/if}
          {/if}
          {#if $name}
            <span class="hidden font-medium lg:block opacity-80">{$name}</span>
          {:else}
            <span class="hidden font-medium lg:block opacity-80"
              >{truncated_addr($wantedWalletAccount.toString(), 10)}</span
            >
            <span class="font-medium lg:hidden opacity-80"
              >{truncated_addr($wantedWalletAccount.toString(), 4)}</span
            >
          {/if}
          <Icon name="chevron-d" size="xs" opacity="70" />
        </button>
      </div>
      <div slot="main" class="w-full">
        <div class="menu {roles ? '' : 'overflow-auto'}">
          {#each Object.entries($connectedWallets || {}) || [] as [_, w] (w.addr.value)}
            <UserMenuWalletRow
              {instance}
              account={w}
              {open}
              {closeOnSelect}
              {roles}
            />
          {/each}
        </div>
        {#if !small}
          {@const len = Object.keys($connectedWallets || {})?.length || 0}
          {#if $wantedWalletAccount === LOADING_WALLET || $connectorAccount === LOADING_WALLET}
            Connecting...
          {:else if $wantedWalletAccount?.toString() !== $connectorAccount?.toString() && !$isWidgetConfig}
            <div class="m-1">
              <InlineHelpText style="info"
                >To be verified you need to switch wallet and sign a message</InlineHelpText
              >
            </div>
            <Button
              disabled={true}
              label="Switch your web3 wallet"
              block={true}
              style="neutral"
              outline={true}
              size="md"
            />
          {/if}
          {#if len}
            <Button
              label={`Disconnect${len > 1 ? " all" : ""}`}
              block={true}
              iconPrepend={true}
              icon="close"
              style="neutral"
              outline={true}
              size="md"
              asyncAction={core.Drop}
            />
          {/if}
        {/if}
      </div>
    </Dropdown>
  {/if}
{:else if !$isWidgetConfig && showConnect}
  <Button
    style="primary"
    size="md"
    label="Connect Wallet"
    asyncAction={async () => {
      await core.Connect();
    }}
  />
{/if}
