import type { AnyCell, SheetProxy } from "@okcontract/cells";
import { type CacheQuery, isContract, isToken } from "@okcontract/coredata";
import type { Address, ChainType, Network } from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";
import { filterAsync } from "@scv/utils";

import { retrieveAddress } from "./address";
import type { AnalyzedLog } from "./analyze";
import { formatBig } from "./erc20";
import type { AnyAddress } from "./types";

export const PRETTY = "pretty";
export const ANALYZED = "analyzed";
export const RAW = "raw";

/**
 * logAbi is a pre-built ABI for log messages
 * @todo add more static definitions
 * @todo get other definitions from datacache
 */
export const logAbi = [
  "event Transfer(address indexed from, address indexed to, uint256 amount)",
  "event Approval(address owner, address spender, uint256 value)",
  "event Swap(address indexed sender, uint256 amount0In, uint256 amount1In, uint256 amount0Out, uint256 amount1Out, address indexed to)"
];

/**
 * logTitle transforms a log open format to a Title.
 */
export const logTitle = (
  proxy: SheetProxy,
  wallet: AnyCell<Address>,
  aLog: AnyCell<AnalyzedLog>
) =>
  proxy.map(
    [aLog, wallet],
    (_aLog, _walletID) => {
      const { address, event, eventArgs } = _aLog;
      if (_aLog && "nftcd" in _aLog) {
        if (event === "ApprovalForAll" && "nftcd" in _aLog)
          return [
            `${event} your NFTs`,
            "to",
            address.toString() // to
          ];
        return [
          `${event} NFT #`,
          BigInt(_aLog?.eventArgs?.tokenId || 0).toString(), // id
          "to",
          address.toString() // to
        ];
      }
      if ((event === "Transfer" || event === "Swap") && "tcd" in _aLog) {
        // @todo default to 0 or 18?
        const dec = _aLog?.tcd?.decimals;
        const symb = _aLog?.tcd?.symbol;
        return proxy.map([dec, symb], (_dec, _symb) => {
          if (eventArgs[0] === _walletID.toString())
            return ["Send", `-${formatBig(eventArgs[2], _dec)} ${_symb}`];
          if (eventArgs[1] === _walletID.toString())
            return ["Receive", `+${formatBig(eventArgs[2], _dec)} ${_symb}`];
          return ["Transfer", `${formatBig(eventArgs[2], _dec)} ${_symb}`];
        });
      }
      if (event === "NFTPriceUpdated")
        return [
          "NFT #",
          String(eventArgs?.tokenId),
          "price set to",
          `${formatBig(eventArgs.price, 18)} ETH`
        ];

      // @todo generic Log
      const decode = filter_numeric_keys(eventArgs);
      const params = Object.entries(decode).flatMap(([k, v]) => [k, String(v)]);
      return [event, ...params];
    },
    "logTitle"
  );

const filter_numeric_keys = (decode: Record<string | number, unknown>) => {
  const newObject = {};
  for (const key in decode) {
    if (Number.isNaN(Number(key)) && key !== "$fragment_sig")
      newObject[key] = decode[key];
  }
  return newObject;
};

/**
 * logSearch search a specific log
 * @param proxy
 * @param local
 * @param chain
 * @param logs
 * @param step
 * @param event
 * @param prop
 * @param emitter
 * @param filter
 * @returns
 */
export const logSearch = <
  Prop extends string | null,
  Emitter extends AnyAddress | null,
  Filter extends {
    [k: string]: string | Address<Network> | number | bigint;
  } | null
>(
  proxy: SheetProxy,
  local: LocalSubscriber<CacheQuery>,
  chain: AnyCell<ChainType>,
  logs: AnyCell<AnalyzedLog[][]>,
  step: AnyCell<number>,
  event: AnyCell<string>,
  prop: AnyCell<Prop>,
  emitter: AnyCell<Emitter>,
  filter: AnyCell<Filter>
) => {
  const envLogs = proxy.map(
    [logs, step],
    (_logs, _step) => (_logs?.[_step] as AnalyzedLog[]) || null
  );
  return proxy.map(
    [chain, envLogs, emitter, filter, prop],
    (_ch, _envLogs, _emitter, _filter, _prop) => {
      let logs = envLogs;
      // filter on emitter addr
      if (emitter) {
        const resolved = retrieveAddress(proxy, local, chain, emitter);
        logs = resolved.map((_resolved) => {
          return _envLogs.filter((log) => {
            // @todo Starknet
            return log.address.equals(_resolved);
          });
        }, "logSearch.res.resolved");
      }
      // filter on array based on key-value pair
      if (_filter) {
        const predicate = async (log: AnalyzedLog) => {
          for (const key in filter) {
            const filterKey = _filter[key];
            const filterValue =
              // check if key is token or contract
              isContract(filterKey) || isToken(filterKey)
                ? await retrieveAddress(
                    proxy,
                    local,
                    chain,
                    proxy.new(filterKey)
                  ).get()
                : filterKey;
            return !(!(key in log.eventArgs || {}) ||
            typeof log?.eventArgs[key] === "number"
              ? BigInt(log?.eventArgs[key]) !==
                BigInt(filterValue as unknown as number)
              : log?.eventArgs[key] !== filterValue);
          }
          return false;
        };
        logs = logs.map((_logs) => {
          return filterAsync(_logs, predicate);
        }, "logSearch.res.filterAsync");
      }

      // filter on event
      logs = proxy.map(
        [logs, event],
        (_logs, _event) =>
          _logs?.filter((log) => {
            return log?.event?.toLowerCase()?.startsWith(_event.toLowerCase());
          }),
        "logSearch.res.filter"
      );

      // @todo we should probably return the array ?
      return (
        logs
          .map(
            (_logs) =>
              _prop ? _logs?.[0]?.eventArgs?.[_prop] : _logs?.[0]?.eventArgs,
            "logSearch.res.logs"
          )
          // @todo remove .get
          .get()
      );
    },
    "logSearch.res"
  );
};
