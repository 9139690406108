<script lang="ts">
  import type { TransactionReceipt } from "viem";

  import type { AnyCell } from "@okcontract/cells";
  import { type Chain } from "@okcontract/multichain";
  import { ThemeText, getTheme } from "@okcontract/uic";

  import type { TokenMarketData } from "@okcontract/coredata";
  import type { Rational } from "@okcontract/lambdascript";
  import type { SentTransaction } from "@okcontract/sdk";

  import ChainExplorerLink from "./ChainExplorerLink.svelte";
  import TxFees from "./TXFees.svelte";
  import TxLog from "./TXLog.svelte";

  export let receipt: AnyCell<TransactionReceipt>;
  export let receipts: AnyCell<TransactionReceipt[][]>;

  export let tokenPrice: AnyCell<TokenMarketData>;
  export let chain: AnyCell<Chain>;

  export let txs: AnyCell<SentTransaction[][]>;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const logs = receipt.map((_receipt) => {
    const logs = [];
    _receipt?.logs.forEach((log) => logs.push(log));
    return logs;
  }, "WidgetTXSent.logs");

  const getCurrencyPrice = (input: unknown) =>
    Number((input as Rational).toBigInt());
</script>

<!-- @todo split the logic in components -->

{#if $receipt && !($receipt instanceof Error) && !($tokenPrice instanceof Error) && !($chain instanceof Error)}
  <ChainExplorerLink
    hash={$receipt.transactionHash}
    ch={chain}
    showHash={false}
  />

  <div class="block w-full break-words text-sm leading-5">
    {#if $logs && !($logs instanceof Error) && $logs?.length}
      <div
        class="mb-2 rounded-md px-2 py-1 text-sm {theme.dark(
          $compiledTheme instanceof Error ? null : $compiledTheme,
          'bg-white-alpha text-white dark:text-white',
          'bg-black-alpha text-gray-500 dark:text-gray-500',
          'd-descr'
        )}"
        style={theme.apply(
          $compiledTheme instanceof Error ? null : $compiledTheme,
          [ThemeText]
        )}
      >
        {#each $logs as log}
          <TxLog {log} />
        {/each}
      </div>
    {/if}
    <div class="mb-4">
      <TxFees
        {chain}
        currency={"ETH"}
        gas_amount={$receipt.gasUsed}
        gas_price={Number(
          $receipt.effectiveGasPrice
            ? $receipt.effectiveGasPrice
            : $chain?.defaultGas || 0
        ) / 1e9}
        currency_price={getCurrencyPrice($tokenPrice?.current_price)}
        fee_name="Paid"
        txs={$txs instanceof Error ? [] : $txs.flat()}
        receipts={receipts.map((_r) => _r.flat())}
      />
    </div>
  </div>
{/if}
