import type { AnyCell, SheetProxy } from "@okcontract/cells";
import { TokenQuery } from "@okcontract/coredata";
import {
  EDIT,
  NEW,
  defaultPatterns,
  objectDefinition,
  type EditorMode,
  type EditorNode,
  type GroupDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { orgID } from "./data-org";
import type { Instance } from "./instance";

export const token_groups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "Token Information" }),
  proxy.new({ id: "addr", l: "List of Token Addresses" }),
  proxy.new({ id: "add", l: "Additional Information" })
];

export const nft_groups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "NFT information" }),
  proxy.new({ id: "addr", l: "NFT Address" }),
  proxy.new({ id: "add", l: "Additional information" })
];

export const tokenSchema =
  (instance: Instance, mode?: EditorMode, is_admin = false) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance._proxy, {
      org: orgID(instance, is_admin, mode),
      id: {
        label: "Unique Token ID",
        search: mode === EDIT ? "token" : undefined,
        base: "string",
        validator: async (v: string) => {
          const tok = await instance._core.CacheOnce(TokenQuery(v));
          if (tok?.id && mode === NEW) return `This ID ${v} is taken`;
        },
        gr: "id",
        pl: "cUSDCv3"
      },
      symbol: {
        label: "Symbol",
        base: "string",
        gr: "id",
        pl: "ex. cUSDCv3",
        css: "flex"
      },
      ty: {
        label: "Category",
        name: "PoolTokenType",
        gr: "id"
      },
      act: {
        label: "Token activation",
        base: "boolean",
        hidden: true,
        gr: "id"
      },
      name: {
        label: "Display Name",
        base: "string",
        hint: "Useful for NFT collection names",
        pl: "ex. Compound III USDC token",
        gr: "id"
      },
      desc: {
        label: "Description",
        hint: "A description of your token",
        pl: "Add description...",
        base: "string",
        long: true,
        gr: "id",
        optional: true,
        icon: "bars-left-o"
      },
      logo: {
        label: "Logo",
        base: "string",
        isImg: true,
        gr: "id",
        optional: false,
        icon: "img",
        pl: "https://... or ipfs://..."
      },
      addr: {
        label: "",
        array: () => instance._proxy.new({ name: "EVMAddress", min: 1 }),
        gr: "addr"
      },
      toks: {
        label: "Linked Tokens",
        array: () => instance._proxy.new({ name: "TokenID" }),
        gr: "add",
        optional: true,
        icon: "coins-o"
      },
      foreign: {
        label: "Crypto Platform Identifiers",
        array: () => instance._proxy.new({ name: "ForeignOrgId" }),
        gr: "add",
        optional: true,
        icon: "chartpie-o"
      },
      url: {
        label: "Docs URL",
        base: "string",
        pattern: defaultPatterns.url,
        gr: "add",
        optional: true,
        icon: "book-open-o"
      }
    });
// card: true
