import * as Config from "../../config.json";
import { cookie } from "./cookie";
const DEBUG_SDK = Config?.debug?.http;

// FIXME: Class, with predefined DEFAULT URL

/**
 * makeRequest performs an authenticated request to the API.
 * @param path
 * @param method GET by default
 * @returns
 */
export const makeRequest = async (
  url: string,
  method: "GET" | "POST" | "DELETE" = "GET"
) => {
  const token = cookie.get("token");
  DEBUG_SDK && console.log(method, url, token);

  const r = await fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`
    },
    method
  });
  if (!r.ok) {
    throw new Error(r.statusText);
  }
  return r;
};

/**
 * getJSON gets data from authenticated route, returns a JSON response.
 * @param path path to query (without domain)
 */
export const getJSON = async (url: string) => {
  const r = await makeRequest(url);
  return r?.json();
};

/**
 * deleteJSON performs a DELETE query on given path and returns a JSON response.
 * @param path
 */
export const deleteJSON = async (url: string) => {
  const r = await makeRequest(url, "DELETE");
  return r?.json();
};

/**
 * postJSON posts JSON data to authenticated route, returns a JSON response.
 * @param path path to query (without domain)
 * @param data data sent in body
 */
export const postJSON = async (url: string, data: unknown) => {
  DEBUG_SDK && console.log("POST path:", url, "- data:", data);
  const token = cookie.get("token");

  const r = await fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    body: JSON.stringify(data)
  });
  if (!r.ok) {
    throw new Error(r.statusText);
  }
  // return await r.json();
  return r;
};

/**
 * postBODY posts body data to authenticated route
 * @param path path to query (without domain)
 * @param data data sent in body
 */
export const postBODY = async (url: string, data: string) => {
  DEBUG_SDK && console.log("POST path:", url, "- data:", data);
  const token = cookie.get("token");
  const r = await fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    body: data
  });
  if (!r.ok) {
    throw new Error(r.statusText);
  }
  // return await r.json();
  return r;
};
