import type { UserKeyPair } from "@scv/auth";

import type { Address } from "@okcontract/multichain";

import type { ChannelType } from "./types";

export const RoleGuest = "guest";
export const RoleUser = "user";
export const RoleAdmin = "admin";

export type RoleType = typeof RoleGuest | typeof RoleUser | typeof RoleAdmin;

export interface Role {
  role: RoleType;

  // @todo Maybe only store in History
  // By       string        `json:"from"` // User attributing the role

  // @todo Sig useless with chain
  // Sig      string        `json:"sig"`  // Signature of attribution

  // @todo Optional value, e.g. for "owner" of resource key `org:xxx`
  // Value    string `json:"v"`
}

export const AuthTypeEOA = "EOA";
export const AuthType4337 = "4337";
export const AuthTypeService = "service";

export type AuthType =
  | typeof AuthTypeEOA
  | typeof AuthType4337
  | typeof AuthTypeService;

export interface ForeignAccount {
  /** account type */
  ty: ChannelType;
  /** username */
  u: string;
  /** signature */
  sig?: string;
}

export interface User {
  type: AuthType;
  id: string;
  roles: Role[];
  kp: UserKeyPair;
  from?: Address;
  u?: string;
  f?: ForeignAccount[];
  sto: { [key: string]: string };
}

export type UserQueryType = `user:${string}`;
export const UserQuery = (id: string): UserQueryType =>
  id ? `user:${id}` : null;
