import { jsonStringify } from "@okcontract/cells";
import type { CacheDataFromType, DataCacheType } from "@okcontract/coredata";

/**
 * write_message generates a stable message for signing custom objects.
 * @param ty
 * @param data
 * @returns
 */
export const write_message = <T extends DataCacheType>(
  ty: T,
  data: CacheDataFromType<T>
) => jsonStringify({ ty, data });
