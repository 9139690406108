<script lang="ts">
  import {
    OKTransaction,
    type AnyContractQuery,
    type Instance
  } from "@okcontract/sdk";
  import { getTheme } from "@okcontract/uic";

  import SwitchChains from "./SwitchChains.svelte";
  import WalletAccounts from "./WalletAccounts.svelte";
  import WidgetSettings from "./WidgetSettings.svelte";

  export let instance: Instance;
  const proxy = instance._proxy;

  export let okTX: OKTransaction<AnyContractQuery>;
  const org = okTX.org;
  const chains = proxy.map(
    [okTX.chains],
    (all) => Array.from(all.keys()),
    "chains"
  );
  // $: okTX && console.log({ $chains, title: okTX.title.value, okTX });

  export let theme = getTheme();

  const open = proxy.new(false, "WidgetHeader.open");
</script>

<div class="absolute top-0 right-0 left-0 h-12 z-10">
  <div class="flex gap-1 items-center justify-end px-3 pt-3 pb-1">
    <div class="flex gap-1 items-center">
      <SwitchChains {instance} {chains} displayCurrent={true} size="xs" />
      <WalletAccounts
        {instance}
        {open}
        small={true}
        showConnect={false}
        closeOnSelect={true}
        size="xs"
      />
      <WidgetSettings {theme} {instance} />
    </div>
  </div>
</div>
