/**
 * Adapted from https://github.com/reach/router/blob/b60e6dd781d5d3a4bdaaf4de665649c0f6a7e78d/src/lib/history.js
 * https://github.com/reach/router/blob/master/LICENSE
 */
import type { LinkProps } from "./types";

const getLocation = (source: Window) => ({
  ...source.location,
  state: source.history.state,
  key: source.history.state?.key || "initial"
});

export type LocationEvent = {
  location: Location;
  action: string;
  preserveScroll?: boolean;
};
export type Listener = (location: LocationEvent) => void;

export type NavigateOptions = LinkProps & {
  replace?: boolean;
  preserveScroll?: boolean;
};

export const createHistory = (source: Window) => {
  const listeners: Array<Listener> = [];
  let location = getLocation(source);

  return {
    get location() {
      return location;
    },

    listen(listener: Listener) {
      listeners.push(listener);

      const popstateListener = () => {
        location = getLocation(source);
        listener({ location, action: "POP" });
      };

      source.addEventListener("popstate", popstateListener);

      return () => {
        source.removeEventListener("popstate", popstateListener);
        const index = listeners.indexOf(listener);
        if (index > -1) listeners.splice(index, 1);
      };
    },

    navigate(options: NavigateOptions) {
      const {
        to,
        replace = false,
        preserveScroll = false,
        state = {}
      } = options;
      const fullState = { ...state, key: `${Date.now()}` };

      try {
        if (replace) source.history.replaceState(fullState, "", to);
        else source.history.pushState(fullState, "", to);
      } catch (e) {
        source.location[replace ? "replace" : "assign"](to);
      }

      location = getLocation(source);
      for (const listener of listeners)
        listener({ location, action: "PUSH", preserveScroll });

      (document.activeElement as HTMLElement)?.blur();
    }
  };
};

// Global history uses window.history as the source if available,
// otherwise a memory history
export const globalHistory = createHistory(window);

export const navigate = globalHistory.navigate;
