const DEV = false;

import { cookie } from "./cookie";

const SyncTimeFrame = 1000 * 60 * 60 * 3; // 3 Hours
const LastSyncKey = "LastSyncWithTimeServer";
const TimeDiffKey = "Local-Server-TimeDiff";
const PublicIP = "PublicIPAddress";
const RetryMax = 1;
const AcceptedDelay = 1000;

const _set = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (_) {
    cookie.set(key, value);
  }
};

const _get = (key: string): string => {
  try {
    return localStorage.getItem(key) || cookie.get(key);
  } catch (_) {
    return cookie.get(key);
  }
};

export class TimeSync {
  private _endpoint: string;

  constructor(endpoint: string) {
    this._endpoint = endpoint;
  }

  private _cacheQuery = async (): Promise<{ id: string; time: number }> => {
    const r = await fetch(`${this._endpoint}/time`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    return r.json();
  };

  /**
   * Sync synchronizes the time with a reference server and
   * returns the difference in seconds with the local time.
   */
  Sync = async () => {
    let RetryCount = 0;
    const aux = async (): Promise<number> => {
      const startMS = new Date().valueOf();
      const { id, time } = await this._cacheQuery();
      _set(PublicIP, id);
      const serverMS = Math.floor(time * 1000);
      const nowMS = new Date().valueOf();
      const diffMS = serverMS - (nowMS + startMS) / 2;
      if (++RetryCount < RetryMax && nowMS - startMS > AcceptedDelay) {
        return aux();
      }
      _set(LastSyncKey, `${nowMS}`);
      _set(TimeDiffKey, `${diffMS}`);
      return diffMS;
    };
    return aux();
  };

  /**
   * Get real time and IP address.
   * Get automatically syncs the time, so there is no need to call Sync manually.
   */
  Get = async () => {
    // sync if required
    const LastSync = Number.parseInt(_get(LastSyncKey) || "0");
    const now = new Date().valueOf();
    if (Math.abs(now - LastSync) > SyncTimeFrame) {
      DEV && console.log("timesync", LastSync, now);
      await this.Sync();
    }
    // return info
    const v = _get(TimeDiffKey) || "0";
    const diff = Number.parseInt(v);
    const real = new Date(Date.now().valueOf() + diff);
    const ip = _get(PublicIP);
    return { diff, real, ip };
  };

  /**
   * Wait until a given timesync arrives.
   * @param ts expected timesync
   */
  Wait = async (ts: number) => {
    const { real } = await this.Get();
    const left = ts - real.valueOf();
    if (left > 0) await new Promise((r) => setTimeout(r, left));
  };
}
