<script lang="ts">
  import { setContext } from "svelte";

  import { Theme, ThemeContextKey } from "@okcontract/uic";

  export let theme: Theme = undefined;

  setContext(ThemeContextKey, theme);
</script>

<slot />
