<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import type { AnyCell } from "@okcontract/cells";
  import {
    add_prefix_from_search_type,
    isContract,
    isToken,
    link_of_cache_query,
    strip_datacache_prefix
  } from "@okcontract/coredata";
  import {
    DataEditor,
    defaultPatterns,
    type EditorNode
  } from "@okcontract/fred";
  import { parseExpression, prettyPrint } from "@okcontract/lambdascript";
  import { EnvKeyChain, Instance, coredataSearch } from "@okcontract/sdk";
  import { Icon, ThemeText, getTheme } from "@okcontract/uic";
  import { CodeMirror } from "@scv/codemirror";
  import { CopyToClipboard, DataCacheSnippet, ResultSnippet } from "@scv/dcuic";
  import Image from "@scv/dcuic/Image.svelte";
  import { SearchSelector } from "@scv/selector";

  import AlertCellError from "@scv/dcuic/AlertCellError.svelte";
  import { type EditorOptions, type ModeType } from "../types";
  import {
    canBeImage,
    imageURL,
    initialMode,
    stringValue
  } from "./AdvancedInputEditor.utils";
  import LighthouseUpload from "./LighthouseUpload.svelte";

  export let instance: Instance;
  const proxy = instance._proxy;
  const chain = instance.wantedChain;

  export let editor: DataEditor;
  $: env = editor.env;
  $: contractChain = env && env.value(EnvKeyChain);
  export let options: EditorOptions = {};

  export let node: EditorNode;
  export let disabled: boolean;
  const definition = node.definition;
  const value = instance._proxy.get(node.value) as AnyCell<string>;

  // export let stringify = false;
  $: expr = $definition && "isExpr" in $definition && $definition.isExpr;
  const transform = (x: string) => (expr ? JSON.stringify(x) : x);

  const theme = getTheme();
  const compiledTheme = theme.compiled;

  const mode = proxy.new<ModeType>(initialMode(proxy, definition, value));
  let inputRef: HTMLElement;

  // Lighthouse
  // @todo we should not always create this
  const dPath = node.path.join(".");

  // create new LightHouse data when URL is found
  // or update current lighthouse url
  value.subscribe((_value) => {
    if (_value instanceof Error) return;
    const url = imageURL(_value);
    instance._core.Lighthouse.addNew(dPath, url);
  });

  const unquoted = proxy.map(
    [value],
    (_v) => stringValue(_v),
    "unquoted",
    true
  );

  // string search selector
  const getSelected = async () => strip_datacache_prefix(await unquoted.get());
  const selected = proxy.new(getSelected(), "selected");
</script>

{#if $value instanceof Error}
  <AlertCellError cell={value} />
{:else if options?.view}
  {#if $mode === "coredata" || isToken($unquoted) || isContract($unquoted)}
    <ResultSnippet
      link={link_of_cache_query($unquoted)}
      size="xs"
      style="default"
    >
      <DataCacheSnippet
        dq={$unquoted}
        small={true}
        displayType={false}
        displayUpvote={false}
      />
    </ResultSnippet>
  {:else if $mode === "expr"}
    <div
      class="font-mono flex flex-wrap break-words overflow-x-auto bg-info/30 rounded-btn text-sm p-1"
    >
      {#await parseExpression($value)}
        {$value}
      {:then expr}
        <!-- {@html prettyPrintHTML(expr)} -->
        {prettyPrint(expr)}
      {:catch err}
        <span class="bg-yellow/50">
          {$value}
        </span>
      {/await}
    </div>
    <!-- @todo restore -->
    <!--   <CodeMirror
      value={$value}
      readonly={true}
      editable={false}
    />-->
  {:else if $mode === "img"}
    <Image src={$unquoted} style="sm" />
    <span class="font-medium break-all">{$unquoted}</span>
  {:else if $mode === "string"}
    <span class="font-medium">{$unquoted}</span>
  {/if}
{:else}
  {@const search =
    $definition && "search" in $definition ? $definition.search : null}
  {@const expr = "isExpr" in $definition && $definition.isExpr}
  {@const string = !$value || defaultPatterns.string.test($value)}
  {@const image =
    "isImg" in $definition && $definition.isImg && canBeImage($value)}
  <div role="tablist" class="tabs tabs-lifted justify-self-start">
    {#if $mode === "string" || $mode === "img" || $mode === "expr" || $mode === "coredata"}
      {#if expr}
        <button
          type="button"
          class="tab {$mode === 'expr' ? 'tab-active' : ''}"
          on:click={() => ($mode = "expr")}
        >
          <Icon name="formula" />
        </button>
      {/if}
      {#if (string && !search) || image}
        <button
          type="button"
          class="tab {$mode === 'string' ? 'tab-active' : ''}"
          on:click={() => ($mode = "string")}
        >
          <Icon name="string" />
        </button>
      {/if}
      {#if image}
        <button
          type="button"
          class="tab {$mode === 'img' ? 'tab-active' : ''}"
          on:click={() => ($mode = "img")}
        >
          <Icon name="image" />
        </button>
      {/if}
      {#if search}
        <button
          type="button"
          class="tab {$mode === 'coredata' ? 'tab-active' : ''}"
          on:click={() => ($mode = "coredata")}
        >
          <Icon name="search" />
        </button>
      {/if}
      <div class="tab" />
    {/if}
  </div>
  <div
    class="block bg-base-100 border-b border-l border-r rounded-b-box relative p-2"
  >
    {#if $mode === "string"}
      {@const placeholder = "pl" in $definition ? $definition.pl : undefined}
      <label
        class="input input-bordered flex items-center gap-2 w-full {theme.dark(
          $compiledTheme,
          'input-white-alpha',
          'input-black-alpha',
          ''
        )}"
        style={theme.apply($compiledTheme, [ThemeText])}
      >
        <input
          class="grow"
          bind:this={inputRef}
          {disabled}
          value={$unquoted}
          {placeholder}
          on:input={(event) =>
            dispatch("update", transform(event.currentTarget.value))}
        />
      </label>
    {:else if $mode === "expr"}
      {#key inputRef}
        <div class="grid">
          <div class="w-full col-start-1 row-start-1">
            <!-- @todo migrate to Cell -->
            <!-- @todo env -->
            <CodeMirror
              on:change={(event) => dispatch("update", event.detail)}
              value={$value}
              readonly={disabled}
              editable={!disabled}
              {env}
            />
          </div>
          {#if expr}
            <div
              class="col-start-1 row-start-1 flex items-start justify-end p-2 rtl:justify-start"
            >
              <!-- @todo migrate to Cell -->
              <CopyToClipboard content={$value} pos="left" />
            </div>
          {/if}
        </div>
      {/key}
    {:else if $mode === "img"}
      <LighthouseUpload
        {instance}
        key={dPath}
        {disabled}
        on:update={(ev) => dispatch("update", transform(ev.detail))}
      />
    {:else if $mode === "coredata" && search}
      {@const placeholder = `Search ${search === "token-chain" ? `token on ${$contractChain}` : search}...`}
      {@const searchFn = coredataSearch(instance._core, search, contractChain)}
      <SearchSelector
        {instance}
        {selected}
        {searchFn}
        {placeholder}
        inputStyle="bordered"
        {disabled}
        on:select={(ev) => {
          const selected = ev.detail.selected;
          const component = ev.detail.component;
          component.close();
          component.selected.set(selected);

          const prefixedValue = value
            ? add_prefix_from_search_type(search, selected)
            : "";
          dispatch("update", transform(prefixedValue));
        }}
        let:value
      >
        <DataCacheSnippet
          dq={add_prefix_from_search_type(search, value)}
          small={true}
        /></SearchSelector
      >
    {/if}
  </div>
{/if}
