// Author: Henri Binsztok
// Copyright: Software Constitution Ventures Pte. Ltd.
// Licensed to: OKcontract Pte. Ltd.

import Config from "../../config.json";
const DEV = Config.dev;

import { produce } from "immer";
// window.onload = () => enablePatches();

import { objectEquals } from "@scv/utils";

import { debouncer } from "./debouncer";
import { DotGraph } from "./dot";

export type Unsubscriber = () => void;
export type Listener<T> = (value: T, firstTime?: boolean) => any; // FIXME: or void?

export const storeGraph = new DotGraph();

/**
 * subscriber_queue is a queue used for subscriptions.
 */
const subscriber_queue = [];

/**
 * Store is an alternative Svelte store introducing a different semantics.
 *
 * @param id id of the store (for debugging, etc.)
 * @param value optional initial value
 *
 * @todo do we need the HStore interface?
 */
export class Store<T> {
  // implements HStore<T> {
  public readonly id: string | false;
  public readonly parents: Store<any>[];

  ready: boolean; // FIXME: maybe we don't need ready
  count: number = 0;
  private subscribers: Listener<any>[] = [];
  private waiters = [];
  private state: T;
  // FIXME: subscriptions to other stores
  // let subscriptions = [];
  unmap: () => void;

  /**
   * Store constructor.
   * @param id user-friendly name of the store; for debugging purposes only
   * @param value
   * @param parents
   */
  constructor(
    id: string | false,
    value: T = undefined,
    parents: Store<any>[] = []
  ) {
    DEV && typeof id === "string" && storeGraph.nodes.push(id);
    this.id = id;
    this.state = value;
    this.ready = value !== undefined;
    this.parents = parents;
  }

  get isReady(): boolean {
    return this.ready;
  }

  /**
   * set a `newValue` to the store.
   * @param newValue defined value replacing the store.
   * @param who is calling (for debugging purposes)
   * @param force optional parameter to disable value equality check (e.g. Object order)
   *
   * Setting undefined values does nothing, use `reset` in that case.
   * Setting the same value as held in the store does nothing.
   *
   * @todo benchmark performance impact of objectEquals with KeyValueStore. Disable check with `force` argument.
   */
  set = (newValue: T, who?: string, force?: boolean) => {
    if (
      newValue !== undefined &&
      (force || !objectEquals(this.state, newValue))
    ) {
      const firstTimeDefined = !this.ready && newValue !== undefined;
      DEV &&
        console.log(
          `#${this.count++} Store(${this.id}).set${who ? " by " + who : ""}`,
          newValue,
          firstTimeDefined ? "(first time)" : ""
        );
      // execute and empty all waiters
      if (firstTimeDefined) {
        this.ready = true;
        if (this.waiters.length > 0) {
          DEV && console.log("Store", this.id, "wait over", newValue);
          this.waiters.forEach((fn) => fn(newValue));
          this.waiters = [];
        }
      }
      // change state and push calls to subscriber queue
      this.state = newValue;
      if (this.ready && this.subscribers.length > 0) {
        this.subscribers.forEach((fn) =>
          subscriber_queue.push([fn, this.state, firstTimeDefined])
        );
      }
      // empty subscriber queue
      while (subscriber_queue.length > 0) {
        const [fn, state, first] = subscriber_queue.shift();
        fn(state, first);
      }
    } else {
      DEV && console.log("Store", this.id, "skipping update", newValue);
    }
  };

  /**
   * setAsync
   * @param prom
   * @param who
   *
   * @todo useless?
   */
  setAsync = async (prom: Promise<T>, who?: string) => {
    return this.set(await prom, who);
  };

  /**
   * subscribe to changes to the store value.
   * @param fn listener function
   * @todo debouncer built-in
   */
  subscribe = (fn: Listener<T>): Unsubscriber => {
    // console.log(`${this.id} - new subscription`, fn);
    this.subscribers.push(fn);
    // Get first subscription if available
    if (this.state !== undefined) {
      // console.log("initial subscription call");
      fn(this.state);
    }
    return () => {
      // unsubscriber
      const index = this.subscribers.indexOf(fn);
      if (index !== -1) {
        this.subscribers.splice(index, 1);
      }
    };
  };

  /**
   * get the value of store. This returns a promise, since `get` will wait
   * until the value is defined if the store value is undefined.
   */
  get = () => {
    return new Promise<T>((resolve) => this.once(resolve));
  };

  /**
   * reset resets the store (value undefined and not ready).
   * This is the only way to reset the store.
   *
   * Exceptionally dispatches undefined to subscribers.
   *
   * @description Subscribers are left untouched.
   */
  reset = () => {
    this.state = undefined;
    this.ready = false;
    this.subscribers.forEach((fn) => fn(undefined, false));
  };

  /**
   * once registers a callback which will be called _once_ either immediately, or
   * when the store is ready (i.e. it receives a defined value).
   *
   * @param cb callback
   * @returns true if the store is defined and function was immediately executed
   * @returns false if callback waits
   *
   * If the store already contains a defined value, wait is also called _once_.
   *
   * @todo FIXME async type for callback => Promise / or not async => boolean
   */
  once = async (cb: (v: T) => void | Promise<void>): Promise<boolean> => {
    if (this.ready) {
      // FIXME: remove await
      await cb(this.state);
      return true;
    }
    DEV && console.log("Store", this.id, "wait registered");
    this.waiters.push(cb);
    return false;
  };

  /**
   * apply a `patch` to the store using _immer_.
   * @param fn function to be applied, should modify the value in place.
   * @param who is calling (for debugging purposes)
   *
   * @returns the new value of the store
   *
   * @description If the result of `fn` is *undefined*, there is no update.
   *
   * @todo computing and returning patch should be optional (for performance)
   * @todo use produceWithPatches?
   */
  apply = (patch: (v: T) => void, who?: string): T => {
    // FIXME: if ready?
    // if (patch.constructor.name === "AsyncFunction") {
    const next = produce(this.state, patch); // FIXME [next, diff] = ...
    // }
    // FIXME: do we need to check the diff length?
    // if (diff && diff.length > 0)
    // if (next !== undefined)
    this.set(next, `apply(${who})`);
    // return diff;
    return next;
  };

  /**
   * update applies a functional update to the store.
   * @todo what if undefined?
   */
  update = (fn: (v: T) => T, who?: string, force?: boolean) => {
    this.set(fn(this.state), `update(${who})`, force);
  };

  /**
   * asyncApply applies a `patch` to the store using _immer_.
   *
   * @returns a promise to diff with previous store value.
   *
   * @todo return the diff instead: Promise<Patch[]>
   */
  asyncApply = async (patch: Listener<T>, who?: string): Promise<T> => {
    // let diff: Patch[];
    const next: T = await produce(
      this.state,
      patch
      // (patches) => (diff = patches)
    );
    // FIXME: do we need to check the diff length?
    // if (diff.length > 0)
    this.set(next, `apply(${who})`);
    return next; // diff;
  };

  /**
   * debounce is a debounced subscriber.
   * @param delay delay for debouncing
   * @param skipFirstTime do nothing on initial defined value
   *
   * @todo ability to perform debounce fn only if changed since last call?
   */
  debounce = (to: string, fn: Listener<T>, delay = 1000): Unsubscriber => {
    DEV &&
      storeGraph.edges.push({
        from: this.id,
        to,
        label: `debounce(${delay})`,
      });
    const deb = debouncer(delay);
    // return subscribe((v, firstTime) => !firstTime && deb(fn, v));
    return this.subscribe((v) => deb(fn, v));
  };

  /**
   * init initializes this store from a source store using a supplied, potentially
   * async function. Init will only be called once.
   * @param src source store
   * @param fn (async) function to apply to source store
   * @returns true if initialization is done, or false if it waits for `src` to be ready
   */
  init = async <A>(
    src: Pick<Store<A>, "once" | "id">,
    fn: (v: A) => T | Promise<T>
  ): Promise<boolean> => {
    DEV && storeGraph.edges.push({ from: src.id, to: this.id, label: "init" });
    return src.once(async (v) => {
      this.set(await fn(v), `init:${this.id}`);
    });
  };

  /**
   * map creates a new store, mapped from this source store.
   *
   * Futures updates to this store will update the new store value until `unmap` is called.
   * @param newID id of the new store
   * @param fn function to be applied to value
   */
  map = <A>(newID: string | false, fn: (v: T) => A | Promise<A>): Store<A> => {
    DEV && storeGraph.edges.push({ from: this.id, to: newID, label: "map" });
    const ns: Store<A> = new Store(newID, undefined, [this]);
    ns.unmap = this.subscribe(async (v) =>
      ns.set(await fn(v), `map/${this.id}`)
    );
    return ns;
  };

  /**
   * map2 creates a new store, mapped from this source store and another.
   *
   * Futures updates to this store will update the new store value until `unmap` is called.
   * @param newID id of the new store
   * @param other other store
   * @param fn function to be applied to value
   */
  map2 = <A, X>(
    newID: string,
    other: Store<X>,
    fn: (v1: T, v2: X) => A | Promise<A>
  ): Store<A> => {
    return newMapped2Store(newID, this, other, fn);
  };
}

/**
 * initStore initializes a store from another store.
 * @param dst store to initialize
 * @param src existing origin store
 * @param fn function to apply to origin store
 *
 * This function does not create the store directly as we can't create
 * stores at toplevel in Svelte using promises.
 */
export const initStore = async <T, A>(
  dst: Store<T>,
  src: Store<A>,
  fn: (v: A) => Promise<T>
) => {
  DEV && storeGraph.edges.push({ from: src.id, to: dst.id, label: "init" });
  src.once(async (v) => {
    dst.set(await fn(v), `init:${dst.id}`);
  });
};

/**
 * newMappedStore creates a mapped store.
 * @param id
 * @param store
 * @param fn
 * @todo multiple sources?
 */
export const newMappedStore = <T, A>(
  id: string,
  store: Store<A>,
  fn: (v: A) => T
): Store<T> => {
  DEV && storeGraph.edges.push({ from: store.id, to: id, label: "map" });
  const ns: Store<T> = new Store(id, undefined, [store]);
  ns.unmap = store.subscribe((v) => ns.set(fn(v), `map/${id}`));
  return ns;
};

/**
 * newMappedApplyStore creates a derived store that _applies_ a function of the store value
 * to the current value.
 * @param id
 * @param store
 * @param fn
 * @todo multiple sources?
 */
export const newMappedApplyStore = <T, A>(
  id: string,
  store: Store<A>,
  fn: (v: A) => (prev: T) => void,
  v0: T
): Store<T> => {
  DEV && storeGraph.edges.push({ from: store.id, to: id, label: "map.apply" });
  const ns: Store<T> = new Store(id, v0, [store]);
  ns.unmap = store.subscribe((v) => ns.asyncApply(fn(v), `map.apply/${id}`));
  return ns;
};

/**
 * ancestors returns a set of ancestors for a given store.
 * @param a
 */
const ancestors = (a: Store<any>) => {
  const set: Set<Store<any>> = new Set();
  const add = (s: Store<any>) =>
    s.parents.forEach((x) => {
      set.add(x);
      add(x);
    });
  add(a);
  return set;
};

const commonAncestors = (a: Store<any>, b: Store<any>) => {
  const aa = ancestors(a);
  const bb = ancestors(b);
  return new Set([...aa].filter((x) => bb.has(x)));
};

/**
 * newMapped2Store creates a mapped store from 2 source stores.
 * @param id new store id
 * @param storeA first source store
 * @param storeB second source store
 * @param fn
 *
 * @todo propagate unique id for changes...
 * @todo ability to pause subscriptions?
 */
export const newMapped2Store = <T, A, B>(
  id: string,
  storeA: Store<A>,
  storeB: Store<B>,
  fn: (va: A, vb: B) => T | Promise<T>
  // options: { debounce: number } = { debounce: 0 }
): Store<T> => {
  DEV && storeGraph.edges.push({ from: storeA.id, to: id, label: "map2" });
  DEV && storeGraph.edges.push({ from: storeB.id, to: id, label: "map2" });
  const ns: Store<T> = new Store(id, undefined, [storeA, storeB]);
  id = `map2/${id}`;

  // B is a parent of A, so we should only react to A
  // FIXME: we should determine which is the latest...
  const com = commonAncestors(storeA, storeB);

  const unsA = storeA.subscribe((va) => {
    storeB.once(async (vb) => {
      if (va !== undefined && vb !== undefined)
        ns.set(await fn(va, vb), `${id}:${storeA.id}->`);
    });
  });

  const unsB =
    com.size == 0 // no common ancestors
      ? storeB.subscribe((vb) => {
          storeA.once(async (va) => {
            if (va !== undefined && vb !== undefined)
              ns.set(await fn(va, vb), `${id}:${storeB.id}->`);
          });
        })
      : DEV &&
        console.log(
          `Store ${storeA.id} and ${storeB.id} have common ancestors...`
        );
  // FIXME: automatically unsubscribe the other store when one origin store is deleted?
  ns.unmap = () => {
    unsA();
    unsB && unsB();
  };
  return ns;
};

/**
 * KeyValueStoreChange describes changes in a KeyValueStore.
 */
export type KeyValueStoreChange<K> = { set: K } | { delete: K };

/**
 * KeyValueStore is a derived key-value store based on Store.
 */
export class KeyValueStore<K extends string, V> {
  public readonly id: string;
  public readonly store: Store<{ [key in K]: V }>;
  public readonly changes: Store<KeyValueStoreChange<K>>; // FIXME: should be readonly
  constructor(id: string) {
    this.id = id;
    this.store = new Store(id, {} as { [key in K]: V });
    this.changes = new Store(id + ".changes");
  }
  /**
   * set a key in map.
   * @param k key
   * @param v value
   */
  set = (k: K, v: V) => {
    this.store.apply((kv) => {
      kv[k] = v;
    });
    this.changes.set({ set: k });
  };
  /**
   * get a key from map.
   * @param k key
   */
  get = async (k: K) => {
    return (await this.store.get())[k];
  };
  /**
   * delete a key from map.
   * @param k key
   */
  delete = (k: K) => {
    this.store.apply((kv) => {
      delete kv[k];
    });
    this.changes.set({ delete: k });
  };
}
