<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import type { UploadStatus } from "@okcontract/sdk";
  import { Icon, ThemeAccent, ThemeError, getTheme } from "@okcontract/uic";

  export let status: UploadStatus;

  /**
   * image or resource url
   */
  export let url: string;
  /**
   * interval (in seconds, 15 by default)
   */
  export let interval: number = 7;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  let timer: number;
  let timer_url: string;

  const new_timer = () => {
    if (status !== "wait") return;
    if (url === timer_url) return;
    if (timer) clearInterval(timer);
    timer_url = url;
    timer = window.setInterval(() => {
      fetch(url, { method: "HEAD" })
        .then((res) => {
          if (res.ok) {
            dispatch("update", "success");
            clearInterval(timer);
          } else {
            dispatch("update", "warning");
          }
        })
        .catch((err) => {
          console.log("ImageReady:", err);
          clearInterval(timer);
        });
    }, interval * 1000);
  };

  $: url && new_timer();
</script>

<span
  class="h-5 {status === 'success'
    ? 'text-success'
    : status === 'warning'
      ? 'text-error'
      : 'text-base-content'}"
  style={theme.apply(
    $compiledTheme,
    [ThemeAccent],
    status === "warning" ? [ThemeError] : []
  )}
>
  {#if status === "wait"}
    <span class="loading loading-spinner loading-xs"></span>
  {:else}
    <Icon name={status} />
  {/if}
</span>
